<template>
<div class="_container">
	<div class="_insideContainer">
		<div class="leftContainer">
			<div class="titleContainer">
				<Title type="dark" tag="OH NO" :title="$t('error.title')" :framed='false' />
			</div>
			<div class="infoContainer">
				{{$t('error.info')}}
			</div>
			<div class="buttonContainer">
				<Button :text="$t('error.buttonText')" :type="color ='light'" link="" />
			</div>
		</div>
		<div class="rightContainer">
			<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="559px" height="632px" viewBox="0 0 559 632" style="enable-background:new 0 0 559 632; fill:#E2E2E2;" xml:space="preserve">
				<g transform="translate(0)">
					<path d="M277,337.9c-3-1.3-5.9-2.5-8.9-3.5c-0.7,5-1.2,9.9-1.6,14.5c2.5,0.9,5,1.9,7.5,2.9

C274.8,347.4,275.8,342.8,277,337.9z"></path>
					<path d="M328,372.3c-2.2-2.2-4.5-4.3-6.9-6.4c-3.6,3.3-7,6.4-10.3,9.4c2,1.7,3.9,3.5,5.7,5.4

C320.2,378.1,324,375.3,328,372.3z"></path>
					<path d="M292.2,327.1c-3.5-1.8-7-3.4-10.4-4.9c-1.4,5-2.8,10.1-4,15.2c3,1.3,6,2.7,9,4.2

C288.5,336.7,290.4,331.6,292.2,327.1z"></path>
					<path d="M286.4,342.4c-3-1.5-5.9-2.9-8.9-4.2c-1.2,4.8-2.2,9.5-3.2,13.9c2.5,1.1,5,2.3,7.4,3.5

C283.2,351.4,284.8,347,286.4,342.4z"></path>
					<path d="M304.5,353c-2.7-1.8-5.4-3.6-8.3-5.3c-2.2,4.3-4.3,8.4-6.2,12.3c2.4,1.4,4.7,2.9,6.9,4.4

C299.3,360.9,301.8,357.1,304.5,353z"></path>
					<path d="M295.7,347.5c-3-1.7-5.8-3.3-8.7-4.8c-1.7,4.6-3.3,9-4.7,13.2c2.4,1.2,4.8,2.6,7.3,4

C291.5,355.9,293.5,351.8,295.7,347.5z"></path>
					<path d="M312.8,359.1c-2.5-2-5.1-3.9-7.8-5.7c-2.7,4-5.3,7.8-7.7,11.4c2.2,1.5,4.4,3.1,6.5,4.8

C306.7,366.3,309.7,362.8,312.8,359.1z"></path>
					<path d="M303.1,333c-3.5-2-7-4-10.2-5.6c-1.9,4.8-3.8,9.6-5.6,14.4c3,1.5,5.9,3.1,8.8,4.9

C299.3,340.3,301.4,336.2,303.1,333z"></path>
					<path d="M309.8,375.5c-2-1.7-4-3.4-6.1-5c-2.8,3.2-5.5,6.3-8,9.1c1.7,1.3,3.3,2.7,4.9,4

C303.5,381.1,306.6,378.4,309.8,375.5z"></path>
					<path d="M320.7,365.5c-2.4-2.1-4.8-4.1-7.4-6.1c-3.1,3.7-6.1,7.1-9,10.4c2.1,1.6,4.2,3.3,6.2,5.1

C313.7,372,317.1,368.9,320.7,365.5z"></path>
					<path d="M310.2,375.8c-3.3,2.9-6.4,5.6-9.3,8c1.6,1.4,3.1,2.8,4.6,4.3c3.3-2.1,6.8-4.4,10.4-7

C314.1,379.3,312.2,377.5,310.2,375.8z"></path>
					<path d="M250.3,344.3c-0.7-4.9-1.2-10.1-1.6-15.4c-2.9-0.6-5.8-1.1-8.7-1.6c0.9,5.4,1.9,10.7,3,15.7

C245.4,343.4,247.9,343.8,250.3,344.3z"></path>
					<path d="M226.3,308.2c-3.3-0.2-6.6-0.3-9.9-0.3c1.5,5.7,3.2,11.5,5.1,17.2c2.8,0,5.6,0.1,8.5,0.3

C228.7,319.7,227.5,314,226.3,308.2z"></path>
					<path d="M227.5,341.9c-2.1-5.1-4.1-10.4-6-15.9c-2.8,0-5.5,0.1-8.2,0.3c2.3,5.5,4.8,10.8,7.4,15.8

C222.9,342,225.1,341.9,227.5,341.9z"></path>
					<path d="M230.3,326.3c-2.8-0.2-5.6-0.3-8.4-0.3c1.9,5.5,3.8,10.8,6,15.9c2.3,0,4.7,0.1,7,0.2

C233.3,337.1,231.7,331.8,230.3,326.3z"></path>
					<path d="M193.6,348.8c-0.9-1-1.8-2-2.7-2.9c0.8,1,1.7,2,2.5,3C193.5,348.9,193.6,348.9,193.6,348.8z"></path>
					<path d="M221.1,325c-1.8-5.3-3.5-11-5.2-17.2c-3.3,0-6.5,0.1-9.6,0.4c2,5.8,4.2,11.5,6.6,17.1

C215.5,325.1,218.3,325,221.1,325z"></path>
					<path d="M206.3,344.5c-3.5-4.9-6.9-10-10.2-15.4c-2.5,0.6-4.9,1.3-7.3,2.1c3.7,5.2,7.5,10.2,11.4,15

C202.2,345.6,204.2,345,206.3,344.5z"></path>
					<path d="M212.3,325.4c-2.4-5.7-4.6-11.4-6.7-17.1c-3.2,0.3-6.3,0.6-9.3,1.1c2.5,5.7,5.1,11.4,8,16.9

C206.9,325.9,209.6,325.6,212.3,325.4z"></path>
					<path d="M204.3,327.4c-2.6,0.4-5.2,0.9-7.6,1.6c3.2,5.3,6.6,10.5,10.1,15.4c2.1-0.5,4.2-0.9,6.4-1.3

C210,338.1,207.1,332.8,204.3,327.4z"></path>
					<path d="M220.2,342.2c-2.6-5.1-5.1-10.3-7.5-15.8c-2.7,0.2-5.3,0.5-7.9,0.9c2.8,5.4,5.7,10.7,8.8,15.7

C215.7,342.6,217.9,342.4,220.2,342.2z"></path>
					<path d="M195.5,328.1c-3.3-5.4-6.4-10.9-9.4-16.7c-2.9,0.7-5.8,1.6-8.6,2.5c3.3,5.5,6.9,11,10.6,16.3

C190.6,329.4,193,328.7,195.5,328.1z"></path>
					<path d="M266,348.8c0.4-4.6,0.9-9.5,1.5-14.5c-3-1.1-5.9-2-8.9-2.9c-0.2,5.2-0.2,10.2-0.1,15

C261.1,347.1,263.5,347.9,266,348.8z"></path>
					<path d="M258.1,331.2c-3-0.8-5.9-1.6-8.8-2.2c0.4,5.3,0.9,10.5,1.5,15.4c2.4,0.5,4.9,1.1,7.4,1.8

C258,341.4,258,336.4,258.1,331.2z"></path>
					<path d="M270,317.5c-3.5-1.2-7-2.4-10.4-3.4c-0.4,5.3-0.7,10.8-0.9,16.3c3,0.9,6,1.8,9,2.9

C268.4,328,269.2,322.7,270,317.5z"></path>
					<path d="M281.1,321.8c-3.5-1.5-7-2.9-10.5-4.1c-1,5.6-1.8,10.9-2.5,15.8c3,1.1,6,2.3,9,3.6

C278.5,331.9,279.8,326.8,281.1,321.8z"></path>
					<path d="M242.5,342.9c-1.2-5-2.2-10.2-3.1-15.7c-2.9-0.4-5.7-0.7-8.6-0.9c1.4,5.5,2.9,10.8,4.5,15.8

C237.7,342.3,240.1,342.6,242.5,342.9z"></path>
					<path d="M239.3,326.2c-0.9-5.7-1.7-11.3-2.3-17c-3.4-0.5-6.7-0.8-10.1-1.1c1.2,6.2,2.4,11.9,3.7,17.1

C233.5,325.5,236.4,325.8,239.3,326.2z"></path>
					<path d="M199.8,346.4c-3.9-4.8-7.7-9.8-11.4-15c-2.3,0.8-4.5,1.7-6.7,2.6c3.8,5.1,8,10,12.4,14.6

C195.9,347.8,197.8,347.1,199.8,346.4z"></path>
					<path d="M195.7,309.5c-3.1,0.5-6,1.1-9,1.8c2.9,5.6,6,11.2,9.3,16.7c2.5-0.6,5.1-1.1,7.7-1.6

C200.9,320.9,198.2,315.3,195.7,309.5z"></path>
					<path d="M265.3,362.8c0.1-4.1,0.3-8.5,0.6-13.1c-2.5-0.9-4.9-1.7-7.3-2.4c0.1,4.8,0.4,9.3,0.8,13.6

C261.4,361.4,263.3,362.1,265.3,362.8z"></path>
					<path d="M234.7,357.3c-2.4-4.6-4.7-9.4-6.8-14.5c-2.3,0-4.5,0.1-6.8,0.3c2.6,5,5.3,9.8,8.1,14.4

C231,357.4,232.8,357.3,234.7,357.3z"></path>
					<path d="M289.2,360.6c-2.5-1.5-5-2.8-7.2-4c-1.4,4.1-2.7,8.1-3.8,11.8c2,1,3.9,2.1,5.8,3.2

C285.6,368.1,287.3,364.5,289.2,360.6z"></path>
					<path d="M228.9,357.5c-2.8-4.6-5.6-9.4-8.2-14.4c-2.2,0.2-4.4,0.4-6.6,0.8c3.1,5,6.2,9.7,9.5,14.3

C225.3,357.9,227.1,357.7,228.9,357.5z"></path>
					<path d="M235.2,343.1c-2.3-0.2-4.7-0.2-6.9-0.2c2.1,5.1,4.4,9.9,6.7,14.5c1.8,0,3.7,0.1,5.6,0.2

C238.7,352.9,236.9,348.1,235.2,343.1z"></path>
					<path d="M296.5,365.2c-2.2-1.5-4.5-3-6.8-4.3c-1.9,3.9-3.7,7.5-5.3,11c1.9,1.1,3.7,2.3,5.5,3.5

C291.9,372.1,294.1,368.8,296.5,365.2z"></path>
					<path d="M212.7,360.9c-4.1-4.3-8.2-8.8-12.1-13.6c-2,0.7-3.9,1.4-5.7,2.3c4.3,4.6,8.7,9,13.3,13.2

C209.6,362,211.1,361.4,212.7,360.9z"></path>
					<path d="M217.9,359.3c-3.7-4.4-7.4-9.1-10.9-14c-2.1,0.5-4.1,1.1-6,1.8c3.9,4.8,8,9.3,12.1,13.6

C214.6,360.2,216.2,359.8,217.9,359.3z"></path>
					<path d="M213.7,344c-2.1,0.3-4.3,0.8-6.3,1.3c3.5,4.9,7.1,9.6,10.8,14c1.7-0.4,3.4-0.7,5.1-1

C220,353.7,216.8,349,213.7,344z"></path>
					<path d="M246.6,358.1c-1.4-4.5-2.7-9.3-3.9-14.3c-2.4-0.3-4.7-0.6-7.1-0.8c1.6,5,3.4,9.8,5.3,14.4

C242.8,357.7,244.7,357.9,246.6,358.1z"></path>
					<path d="M207.8,362.8c-4.5-4.1-9-8.5-13.3-13.1c-0.1,0-0.1,0.1-0.2,0.1c3.9,4.6,8,9.2,12.2,13.7

C206.9,363.3,207.3,363,207.8,362.8z"></path>
					<path d="M303.4,370.2c-2.1-1.6-4.2-3.2-6.5-4.7c-2.4,3.6-4.6,6.9-6.7,10c1.8,1.2,3.5,2.5,5.2,3.8

C297.9,376.5,300.6,373.4,303.4,370.2z"></path>
					<path d="M273.8,352.7c-2.5-1.1-4.9-2-7.4-2.9c-0.4,4.6-0.6,9-0.7,13.1c2,0.7,3.9,1.5,5.9,2.3

C272.2,361.3,272.9,357.1,273.8,352.7z"></path>
					<path d="M281.6,356.4c-2.4-1.2-4.9-2.4-7.3-3.5c-0.9,4.4-1.6,8.6-2.3,12.5c2,0.9,3.9,1.8,5.9,2.8

C279,364.5,280.2,360.5,281.6,356.4z"></path>
					<path d="M252.8,359.2c-0.9-4.4-1.7-9.1-2.4-14c-2.4-0.5-4.8-0.9-7.2-1.3c1.1,5,2.4,9.7,3.8,14.3

C248.9,358.5,250.9,358.8,252.8,359.2z"></path>
					<path d="M258.2,347.1c-2.4-0.7-4.9-1.3-7.3-1.8c0.6,4.9,1.4,9.6,2.3,14c1.9,0.4,3.9,0.9,5.9,1.5

C258.6,356.5,258.3,351.9,258.2,347.1z"></path>
					<path d="M396.6,28.3c7.8,2.8,15.7,5.9,23.6,9.3c1.8-1.7,3.8-3.3,5.9-4.7c-7.7-3.3-15.3-6.3-23-9.1

C400.8,25.2,398.6,26.7,396.6,28.3z"></path>
					<path d="M223.2,288.8c-3.8-0.3-7.6-0.4-11.3-0.4c1.3,6.3,2.7,12.3,4.3,18.3c3.3,0,6.6,0.1,10,0.3

C225,301.1,224,295.1,223.2,288.8z"></path>
					<path d="M289.1,13.4c7.6,0.6,15.3,1.4,23.1,2.5c2.6-2.3,5.3-4.3,8.2-6.2c-7.6-1.1-15.2-1.9-22.7-2.4

C294.6,9.1,291.7,11.1,289.1,13.4z"></path>
					<path d="M271.6,32.7c7.8,0.6,15.6,1.4,23.5,2.5c2.6-3.7,5.3-7,8-10.1c-7.9-1.1-15.6-1.9-23.4-2.5

C276.8,25.8,274.1,29.2,271.6,32.7z"></path>
					<path d="M381.5,44.1c8.1,2.9,16.2,6.1,24.3,9.6c2.4-3.1,4.8-5.9,7.1-8.5c-8-3.4-16-6.6-24-9.5

C386.4,38.2,384,41,381.5,44.1z"></path>
					<path d="M263.4,45.3c7.7,0.6,15.5,1.4,23.4,2.5c2.5-4.2,5.1-8.2,7.8-11.9c-7.9-1.1-15.7-1.9-23.5-2.5

C268.4,37.1,265.9,41.1,263.4,45.3z"></path>
					<path d="M221,268.5c-4.3-0.3-8.6-0.4-12.8-0.4c1,6.5,2.1,12.9,3.4,19.2c3.8,0,7.6,0.1,11.4,0.4

C222.3,281.4,221.6,275,221,268.5z"></path>
					<path d="M280.2,22c7.7,0.6,15.5,1.4,23.4,2.5c2.5-2.9,5.2-5.6,8.1-8.2c-7.8-1.1-15.5-1.9-23.1-2.5

C285.6,16.4,282.8,19.1,280.2,22z"></path>
					<path d="M389.3,35.2c8,2.9,16,6,24,9.5c2.3-2.5,4.4-4.7,6.5-6.7c-7.9-3.4-15.7-6.5-23.6-9.3

C393.7,30.7,391.4,32.9,389.3,35.2z"></path>
					<path d="M236.3,290.2c0.3,6.2,0.7,12.2,1.3,18.1c3.4,0.5,6.9,1.1,10.3,1.8c-0.1-5.8-0.1-11.7,0.1-17.8

C244.1,291.5,240.2,290.8,236.3,290.2z"></path>
					<path d="M205.7,246.9c0.7,6.8,1.5,13.4,2.4,20c4.2,0,8.5,0.1,12.9,0.4c-0.5-6.5-0.9-13.2-1.1-19.9

C215.1,247,210.3,246.9,205.7,246.9z"></path>
					<path d="M204.1,225c0.3,6.9,0.8,13.8,1.4,20.6c4.7,0,9.5,0.2,14.3,0.5c-0.2-6.8-0.3-13.6-0.2-20.5

C214.4,225.2,209.2,225,204.1,225z"></path>
					<path d="M209.5,133.5c6.7,0,13.4,0.2,20.3,0.7c1.4-6.8,3-13.3,4.7-19.7c-7.1-0.5-14.1-0.7-21.1-0.7

C211.9,120.2,210.6,126.8,209.5,133.5z"></path>
					<path d="M213.6,112.6c7,0,14,0.2,21.1,0.7c1.7-6.3,3.5-12.4,5.4-18.3c-7.3-0.5-14.6-0.8-21.8-0.8

C216.7,100.1,215.1,106.3,213.6,112.6z"></path>
					<path d="M403.4,23.6c7.7,2.8,15.3,5.8,23,9.1c1.6-1.1,3.3-2,5.2-2.7c-7.4-3.2-14.8-6.1-22.3-8.8

C407.2,21.7,405.2,22.5,403.4,23.6z"></path>
					<path d="M558.3,111.5c-0.2-0.3-0.3-0.5-0.5-0.8c-0.3-0.3-0.6-0.5-0.9-0.6

C557.3,110.6,557.8,111,558.3,111.5z"></path>
					<path d="M357.7,275.7c-3.8,5.6-7.5,11-11.2,16.4c4.5,3.1,9,6.3,13.3,9.6c4.1-5.1,8.2-10.2,12.4-15.5

C367.5,282.5,362.7,279,357.7,275.7z"></path>
					<path d="M204,223.7c5.1,0,10.4,0.2,15.6,0.5c0.1-6.9,0.4-13.9,0.8-21c-5.7-0.4-11.3-0.6-16.8-0.6

C203.6,209.7,203.7,216.7,204,223.7z"></path>
					<path d="M525.9,129.9c6.4,5.7,12.7,11.5,18.7,17.5c2.1-3.3,4-6.5,5.7-9.4c-6-5.9-12.1-11.7-18.5-17.3

C530,123.5,528.1,126.6,525.9,129.9z"></path>
					<path d="M206.3,155.8c6.3,0,12.8,0.2,19.3,0.7c1.1-7.1,2.4-14.1,3.9-21c-6.8-0.5-13.5-0.7-20.2-0.7

C208.1,141.9,207.1,148.9,206.3,155.8z"></path>
					<path d="M204.4,178.6c6,0,12,0.2,18.1,0.6c0.8-7.1,1.7-14.2,2.9-21.4c-6.5-0.4-12.9-0.7-19.2-0.7

C205.4,164.4,204.8,171.5,204.4,178.6z"></path>
					<path d="M203.6,201.3c5.6,0,11.2,0.2,16.9,0.6c0.4-7,1-14.1,1.8-21.2c-6.1-0.4-12.1-0.6-18.1-0.6

C203.9,187.1,203.7,194.2,203.6,201.3z"></path>
					<path d="M218.7,93.1c7.2,0,14.5,0.3,21.8,0.8c1.9-5.9,4-11.5,6.1-16.9c-7.5-0.5-14.9-0.8-22.3-0.8

C222.3,81.6,220.5,87.2,218.7,93.1z"></path>
					<path d="M331,5.3c7.4,1.1,14.9,2.4,22.5,4c2-0.8,4.1-1.4,6.3-1.8c-8.9-1.4-17.7-2.4-26.4-2.9

C332.6,4.8,331.8,5,331,5.3z"></path>
					<path d="M405.3,54.3c-8.1-3.5-16.2-6.7-24.3-9.6c-2.5,3.1-5.1,6.5-7.7,10.2c8.1,2.9,16.2,6.1,24.4,9.6

C400.3,60.8,402.8,57.4,405.3,54.3z"></path>
					<path d="M236.2,249.1c-0.3,6.7-0.4,13.3-0.4,19.7c4.4,0.6,8.9,1.4,13.3,2.4c0.5-6.4,1.1-12.9,1.8-19.5

C245.9,250.7,241,249.8,236.2,249.1z"></path>
					<path d="M322.3,9.6c7.6,1.1,15.3,2.5,23,4.1c2.4-1.6,5-3,7.6-4.2c-7.5-1.6-15-2.9-22.5-4

C327.7,6.6,324.9,8,322.3,9.6z"></path>
					<path d="M236.9,308.2c-0.6-5.9-1.1-11.9-1.4-18.1c-3.9-0.5-7.7-1-11.6-1.2c0.8,6.2,1.7,12.3,2.8,18.2

C230.1,307.4,233.5,307.7,236.9,308.2z"></path>
					<path d="M221.9,268.6c0.5,6.5,1.1,12.9,1.9,19.1c3.8,0.3,7.7,0.7,11.7,1.2c-0.3-6.2-0.5-12.5-0.6-19

C230.5,269.4,226.2,268.9,221.9,268.6z"></path>
					<path d="M127.9,216.6c1.3,6.8,2.9,13.6,4.8,20.4c2.9-1.2,5.9-2.4,8.9-3.4c-2-6.7-3.7-13.4-5.3-20.2

C133.5,214.4,130.6,215.5,127.9,216.6z"></path>
					<path d="M221.8,267.3c4.3,0.3,8.7,0.8,13.1,1.4c0-6.5,0.1-13.1,0.3-19.8c-4.9-0.7-9.7-1.2-14.5-1.5

C221,254.2,221.3,260.8,221.8,267.3z"></path>
					<path d="M397.2,65.2c-8.1-3.5-16.2-6.7-24.4-9.6c-2.6,3.7-5.3,7.7-7.9,11.9c8.1,2.9,16.2,6.1,24.3,9.6

C391.9,72.8,394.6,68.9,397.2,65.2z"></path>
					<path d="M313.7,16.1c7.8,1.1,15.6,2.5,23.5,4.2c2.4-2.3,5-4.4,7.8-6.2c-7.7-1.6-15.4-3-23.1-4.1

C318.9,11.8,316.2,13.8,313.7,16.1z"></path>
					<path d="M305,24.7c7.9,1.1,15.8,2.6,23.7,4.2c2.5-2.9,5.1-5.6,7.9-8.2c-7.9-1.7-15.7-3.1-23.5-4.2

C310.3,19.1,307.6,21.8,305,24.7z"></path>
					<path d="M265.6,96.6c7.5,1.1,15,2.4,22.6,4c2.4-5.8,4.8-11.4,7.2-16.7c-7.8-1.6-15.5-3-23.1-4.1

C270,85.1,267.8,90.7,265.6,96.6z"></path>
					<path d="M236.1,113.4c7.1,0.5,14.3,1.3,21.5,2.3c2-6.3,4-12.4,6.1-18.2c-7.5-1-14.9-1.8-22.2-2.4

C239.6,101,237.8,107.1,236.1,113.4z"></path>
					<path d="M231,134.3c6.8,0.5,13.7,1.2,20.7,2.2c1.7-6.7,3.6-13.3,5.5-19.6c-7.2-1-14.4-1.8-21.5-2.3

C234.1,121,232.5,127.6,231,134.3z"></path>
					<path d="M241.9,94c7.4,0.5,14.8,1.3,22.3,2.4c2.1-5.8,4.4-11.4,6.6-16.8c-7.7-1.1-15.2-1.9-22.8-2.4

C245.9,82.5,243.9,88.1,241.9,94z"></path>
					<path d="M433.2,30.5c7.4,3.2,14.8,6.7,22.2,10.5c1.1-0.6,2.2-1,3.5-1c-7.4-3.8-14.2-6.9-21.6-10

C435.8,29.8,434.5,30,433.2,30.5z"></path>
					<path d="M249,272.5c-4.4-0.9-8.9-1.7-13.2-2.4c0,6.5,0.2,12.8,0.5,19c3.9,0.6,7.9,1.3,11.8,2.1

C248.2,285.1,248.6,278.8,249,272.5z"></path>
					<path d="M248.5,76.1c7.5,0.6,15.1,1.4,22.8,2.4c2.3-5.3,4.7-10.4,7.1-15.3c-7.8-1.1-15.5-1.9-23.2-2.5

C252.9,65.6,250.6,70.7,248.5,76.1z"></path>
					<path d="M246.2,160.1c-6.6-0.9-13.1-1.6-19.6-2.1c-1.1,7.1-2.1,14.2-2.9,21.4c6.1,0.4,12.3,1.1,18.5,2

C243.4,174.2,244.7,167.2,246.2,160.1z"></path>
					<path d="M242,182.7c-6.2-0.9-12.3-1.5-18.4-2c-0.8,7.1-1.4,14.2-1.9,21.2c5.7,0.4,11.5,1,17.3,1.9

C239.8,196.8,240.8,189.8,242,182.7z"></path>
					<path d="M255.7,59.8c7.7,0.6,15.4,1.4,23.2,2.5c2.4-4.8,4.9-9.4,7.5-13.6c-7.9-1.1-15.7-1.9-23.4-2.5

C260.4,50.4,258,55,255.7,59.8z"></path>
					<path d="M238.8,205.2c-5.8-0.8-11.5-1.4-17.2-1.8c-0.5,7-0.8,14-0.9,21c5.3,0.4,10.6,1,15.9,1.7

C237.2,219.1,237.9,212.2,238.8,205.2z"></path>
					<path d="M220.7,225.6c-0.1,6.9-0.1,13.8,0.1,20.5c4.8,0.4,9.7,0.9,14.5,1.6c0.3-6.7,0.7-13.5,1.2-20.4

C231.2,226.6,225.9,226,220.7,225.6z"></path>
					<path d="M251.4,137.9c-6.9-1-13.8-1.7-20.6-2.2c-1.4,6.8-2.7,13.8-3.9,20.9c6.5,0.5,13.1,1.2,19.7,2.1

C248,151.7,249.6,144.7,251.4,137.9z"></path>
					<path d="M318.9,154.6c-7.1-2.5-14.3-4.8-21.4-6.9c-2.4,6.7-4.8,13.5-7.1,20.4

c6.8,1.9,13.6,4.1,20.4,6.6C313.5,168.1,316.1,161.5,318.9,154.6z"></path>
					<path d="M524.2,129.4c-6.5-5.7-13.2-11.2-20-16.5c-2.2,3.3-4.6,6.7-7.2,10.4

c6.9,5.4,13.6,10.9,20.1,16.6C519.7,136.2,522,132.7,524.2,129.4z"></path>
					<path d="M272.8,78.7c7.7,1.1,15.4,2.5,23.2,4.1c2.5-5.3,4.9-10.4,7.5-15.2c-7.9-1.7-15.7-3.1-23.5-4.2

C277.4,68.3,275.1,73.4,272.8,78.7z"></path>
					<path d="M495.2,123c-6.9-5.4-14.1-10.5-21.4-15.5c-2.6,3.7-5.3,7.6-8.2,11.7c7.3,5,14.4,10.2,21.3,15.5

C489.8,130.6,492.6,126.7,495.2,123z"></path>
					<path d="M486.3,135.4c-6.9-5.3-14-10.5-21.3-15.5c-2.9,4.1-5.9,8.5-9.1,13c7.2,4.9,14.3,10.1,21.1,15.4

C480.3,143.8,483.4,139.6,486.3,135.4z"></path>
					<path d="M321.9,40c8,1.7,16.1,3.7,24.2,6c2.6-3.7,5.2-7,7.8-10.1c-8.1-2.3-16.1-4.3-24.1-6

C327.1,33,324.5,36.3,321.9,40z"></path>
					<path d="M502.9,111.8c-6.9-5.3-14-10.5-21.3-15.5c-2.3,3.2-4.7,6.7-7.3,10.4c7.3,5,14.5,10.2,21.4,15.5

C498.2,118.6,500.6,115.1,502.9,111.8z"></path>
					<path d="M302.3,197.7c-6.4-2.3-12.8-4.3-19.1-6.2c-2,6.9-3.9,13.7-5.7,20.6c5.9,1.7,11.9,3.6,17.9,5.8

C297.6,211.2,299.9,204.5,302.3,197.7z"></path>
					<path d="M509.4,102.1c-6.8-5.3-13.9-10.4-21.1-15.3c-1.9,2.7-4,5.7-6.3,8.9c7.3,5,14.4,10.1,21.3,15.5

C505.5,108,507.5,104.9,509.4,102.1z"></path>
					<path d="M518.5,87.5c-6.5-5.1-13.3-10-20.2-14.7c-1.2,1.6-2.6,3.5-4.1,5.7c7.1,4.8,14,9.9,20.7,15

C516.3,91.3,517.5,89.3,518.5,87.5z"></path>
					<path d="M514.6,94c-6.7-5.2-13.6-10.2-20.7-15.1c-1.6,2.2-3.3,4.7-5.2,7.4c7.2,4.9,14.2,10,21.1,15.3

C511.6,98.8,513.2,96.3,514.6,94z"></path>
					<path d="M310.4,176c-6.8-2.4-13.5-4.6-20.3-6.5c-2.3,6.9-4.4,13.8-6.4,20.7c6.4,1.8,12.8,3.9,19.2,6.2

C305.2,189.6,307.7,182.8,310.4,176z"></path>
					<path d="M232,22c7.3-0.6,14.8-0.9,22.3-0.9c2.7-2.9,5.6-5.6,8.6-8.1c-16.1,0.1-16.3,0.5-25.3,3.7

C235.7,18.4,233.8,20.1,232,22z"></path>
					<path d="M413.8,232.8c-5.8-4.5-11.7-8.8-17.9-13c-4.1,6-8.2,12-12.3,17.9c5.8,4,11.4,8,16.9,12.3

C404.9,244.3,409.4,238.6,413.8,232.8z"></path>
					<path d="M521.1,82.8c-6.3-4.9-12.8-9.6-19.5-14.2c-0.8,1-1.8,2.4-2.9,3.9c6.9,4.7,13.6,9.6,20.2,14.7

C519.7,85.4,520.5,84,521.1,82.8z"></path>
					<path d="M476.5,149.2c-6.8-5.3-13.9-10.4-21.1-15.4c-3.2,4.5-6.5,9.3-9.9,14.2

c7.1,4.9,14,9.9,20.8,15.1C469.8,158.3,473.2,153.7,476.5,149.2z"></path>
					<path d="M399.7,251.1c-5.4-4.2-11-8.3-16.8-12.2c-4.1,5.9-8.1,11.8-12,17.6c5.4,3.7,10.6,7.5,15.7,11.4

C390.9,262.4,395.3,256.8,399.7,251.1z"></path>
					<path d="M296.7,6.8c1.6-0.9,3.3-1.8,5-2.5c-6.8,0.4-13.5,1-20.1,1.9C286.7,6.3,291.7,6.5,296.7,6.8z"></path>
					<path d="M385.7,268.9c-5.1-3.9-10.3-7.7-15.7-11.4c-3.9,5.8-7.8,11.4-11.6,17c5,3.4,9.8,6.9,14.5,10.6

C377.2,279.8,381.4,274.4,385.7,268.9z"></path>
					<path d="M465.6,164c-6.7-5.2-13.7-10.2-20.8-15.1c-3.4,4.9-6.9,10-10.6,15.2

c6.9,4.8,13.7,9.7,20.3,14.8C458.4,173.8,462,168.9,465.6,164z"></path>
					<path d="M453.8,179.9c-6.6-5.1-13.3-10-20.3-14.7c-3.6,5.3-7.4,10.7-11.2,16.2

c6.7,4.6,13.3,9.4,19.7,14.3C446.1,190.3,450,185.1,453.8,179.9z"></path>
					<path d="M327.7,134.5c-7.4-2.7-14.9-5-22.3-7.2c-2.5,6.2-5,12.6-7.4,19.2c7.1,2.1,14.3,4.4,21.4,6.9

C322.2,146.9,324.9,140.6,327.7,134.5z"></path>
					<path d="M427.9,214.4c-6.1-4.7-12.4-9.3-18.8-13.7c-4.8,6.9-10.1,14.7-12.3,17.9

c6.1,4.2,12.1,8.5,17.9,13C419.1,225.9,423.5,220.2,427.9,214.4z"></path>
					<path d="M441.2,196.8c-6.4-4.9-12.9-9.7-19.6-14.3c-3.8,5.6-7.8,11.3-11.8,17.1

c6.5,4.4,12.7,9,18.9,13.7C433,207.7,437.2,202.2,441.2,196.8z"></path>
					<path d="M273.4,299.5c-4-1.4-8-2.7-12-3.9c-0.7,6-1.3,11.8-1.7,17.4c3.5,1,7,2.1,10.5,3.4

C271.1,311,272.2,305.3,273.4,299.5z"></path>
					<path d="M247.1,31.8c7.6,0,15.2,0.3,23,0.8c2.5-3.5,5.2-6.9,8.1-10.1c-7.7-0.5-15.3-0.8-22.9-0.8

C252.3,24.9,249.6,28.3,247.1,31.8z"></path>
					<path d="M264.1,276.2c-1,6.2-1.8,12.3-2.5,18.3c4,1.2,8,2.5,12.1,3.9c1.2-5.8,2.5-11.8,4-17.9

C273.1,279,268.6,277.5,264.1,276.2z"></path>
					<path d="M239,44.4c7.6,0,15.2,0.3,22.9,0.8c2.5-4.3,5.1-8.2,7.7-11.9c-7.7-0.5-15.4-0.8-23-0.8

C244,36.2,241.4,40.2,239,44.4z"></path>
					<path d="M530.5,119.5c-6.4-5.6-13-11.1-19.8-16.4c-1.8,2.8-3.9,5.8-6.1,9.1c6.9,5.4,13.6,10.9,20,16.5

C526.8,125.5,528.8,122.4,530.5,119.5z"></path>
					<path d="M280.3,62.5c7.8,1.1,15.7,2.5,23.5,4.2c2.5-4.8,5.1-9.3,7.7-13.6c-8-1.7-15.9-3.1-23.8-4.2

C285.3,53.2,282.8,57.7,280.3,62.5z"></path>
					<path d="M267.6,255.9c-1.2,6.5-2.4,12.8-3.3,19.1c4.5,1.3,9.1,2.8,13.6,4.4c1.5-6.1,3-12.3,4.7-18.7

C277.6,259,272.6,257.4,267.6,255.9z"></path>
					<path d="M409.6,21.1c7.4,2.7,14.8,5.6,22.2,8.8c1.3-0.5,2.6-0.7,4-0.6c-7.5-3.1-15.1-6-22.6-8.5

C412,20.7,410.8,20.8,409.6,21.1z"></path>
					<path d="M224.7,75.2c7.4,0,14.8,0.3,22.3,0.8c2.1-5.4,4.4-10.5,6.7-15.3c-7.6-0.5-15.2-0.8-22.7-0.8

C228.8,64.7,226.7,69.8,224.7,75.2z"></path>
					<path d="M255.8,21.1c7.5,0,15.2,0.3,22.9,0.8c2.6-2.9,5.4-5.6,8.4-8.2c-7.6-0.5-15.2-0.8-22.6-0.8

C261.3,15.5,258.5,18.2,255.8,21.1z"></path>
					<path d="M354.9,9.6c7.6,1.6,15.1,3.5,22.8,5.7c2.1-1,4.3-1.8,6.6-2.2c-7.5-2.3-15.2-4-22.9-5.3

C359.2,8.2,357,8.8,354.9,9.6z"></path>
					<path d="M231.5,58.9c7.5,0,15.1,0.3,22.7,0.8c2.3-4.8,4.7-9.4,7.2-13.7c-7.7-0.5-15.4-0.8-22.9-0.8

C236.1,49.5,233.7,54.1,231.5,58.9z"></path>
					<path d="M346.8,14c7.8,1.7,15.5,3.6,23.4,5.8c2.2-1.6,4.6-3.1,7-4.3c-7.6-2.2-15.2-4.1-22.8-5.7

C351.8,11,349.2,12.4,346.8,14z"></path>
					<path d="M518.4,141c6.5,5.7,12.7,11.6,18.8,17.6c2.6-3.7,4.9-7.2,7.1-10.6c-6-6-12.3-11.8-18.7-17.5

C523.3,133.8,520.9,137.3,518.4,141z"></path>
					<path d="M521.2,82.5c0.4-0.8,0.7-1.7,0.9-2.6c-6.1-4.7-12.3-9.2-18.7-13.6c-0.4,0.4-0.9,1.1-1.7,2.1

C508.3,72.9,514.8,77.7,521.2,82.5z"></path>
					<path d="M286.2,304.5c-4-1.7-8-3.3-12-4.8c-1.2,5.8-2.3,11.4-3.3,16.9c3.5,1.3,7,2.7,10.6,4.2

C282.9,315.6,284.5,310.1,286.2,304.5z"></path>
					<path d="M295,219.1c-5.9-2.1-11.9-4-17.8-5.7c-1.8,6.8-3.4,13.5-4.9,20.2c5.5,1.6,11,3.4,16.5,5.3

C290.8,232.4,292.8,225.8,295,219.1z"></path>
					<path d="M523.3,80.7c6,4.7,11.9,9.5,17.6,14.5l-0.1-0.2c-5.7-5-11.5-9.9-17.5-14.6L523.3,80.7z"></path>
					<path d="M522,79.5c-6-4.6-12.1-9.2-18.5-13.5c-0.1,0.1-0.1,0.1-0.2,0.2c6.4,4.4,12.6,8.9,18.7,13.6

L522,79.5z"></path>
					<path d="M330.2,29.3c8,1.7,16,3.7,24.1,6c2.6-3.1,5.1-5.8,7.6-8.3c-8-2.3-15.9-4.2-23.8-5.9

C335.3,23.7,332.7,26.4,330.2,29.3z"></path>
					<path d="M539,103.8c0.7-1.6,1.3-3.2,1.7-4.9c-5.9-5.2-12.1-10.3-18.4-15.2c-0.5,1.2-1.3,2.7-2.3,4.4

C526.5,93.2,532.8,98.4,539,103.8z"></path>
					<path d="M538.8,104.2c-6.1-5.4-12.5-10.6-19-15.7c-1,1.8-2.2,3.8-3.6,6.1c6.7,5.2,13.2,10.6,19.4,16.1

C536.9,108.2,538,106.1,538.8,104.2z"></path>
					<path d="M540.9,95.4c-5.7-5-11.6-9.8-17.6-14.6c-0.2,0.9-0.5,1.8-0.9,2.7c6.3,4.9,12.4,9.9,18.3,15.1

C541,97.6,541,96.4,540.9,95.4z"></path>
					<path d="M338.6,20.6c7.9,1.7,15.8,3.7,23.8,5.9c2.3-2.3,4.8-4.4,7.3-6.4c-7.8-2.2-15.6-4.2-23.4-5.8

C343.6,16.2,341,18.3,338.6,20.6z"></path>
					<path d="M272,234.9c-1.5,6.7-2.9,13.2-4.1,19.8c5,1.4,10.1,3.1,15.1,4.9c1.7-6.3,3.5-12.8,5.5-19.3

C282.9,238.3,277.5,236.5,272,234.9z"></path>
					<path d="M535.4,111.1c-6.3-5.5-12.8-10.9-19.5-16.1c-1.4,2.3-3.1,4.8-4.9,7.6

c6.8,5.3,13.4,10.7,19.8,16.4C532.6,116.1,534.1,113.5,535.4,111.1z"></path>
					<path d="M414.4,89.9c-8.1-4.1-16.1-7.9-24.2-11.4c-2.7,4.2-5.5,8.7-8.4,13.4c8,3.5,16,7.3,24,11.3

C408.7,98.6,411.6,94.1,414.4,89.9z"></path>
					<path d="M463.2,70.8c-7.7-4.5-15.5-8.8-23.5-13c-2.2,2.6-4.4,5.5-6.8,8.6c7.7,4,15.7,8.3,23.7,13.1

C459,76.4,461.2,73.5,463.2,70.8z"></path>
					<path d="M375.1,154.9c-7.4-3.8-14.8-7.3-22.3-10.5c-3.1,6-6.1,12.2-9.2,18.6

c7.2,3.1,14.3,6.5,21.4,10.1C368.4,166.8,371.8,160.8,375.1,154.9z"></path>
					<path d="M497,71.9c-6.9-4.7-14.1-9.2-21.3-13.6c-1.4,1.5-3,3.3-4.7,5.3c7.5,4.4,14.8,9.1,21.9,13.9

C494.4,75.4,495.8,73.5,497,71.9z"></path>
					<path d="M385.5,136.7c-7.7-3.9-15.3-7.5-23-10.9c-3,5.6-6,11.4-9.1,17.4c7.5,3.2,14.9,6.8,22.3,10.5

C379,147.9,382.3,142.2,385.5,136.7z"></path>
					<path d="M341.5,265.3c-3.4,5.9-6.6,11.6-9.8,17.3c4.8,2.9,9.5,5.8,14,8.9c3.6-5.4,7.3-10.9,11.1-16.5

C351.8,271.6,346.7,268.4,341.5,265.3z"></path>
					<path d="M289.5,240.6c-2,6.5-3.8,12.9-5.6,19.3c5,1.8,10.1,3.8,15.1,6c2.2-6.1,4.5-12.4,6.9-18.7

C300.4,244.7,294.9,242.6,289.5,240.6z"></path>
					<path d="M541.8,99.6c5.9,5.2,11.6,10.5,17.1,16c0.1-1.3-0.1-2.7-0.5-3.9c-5.3-5.3-10.8-10.4-16.5-15.4

C542.2,97.4,542.1,98.5,541.8,99.6z"></path>
					<path d="M331,283.6c-3.2,5.6-6.3,11.1-9.3,16.5c4.3,2.6,8.5,5.3,12.6,8c3.4-5.1,6.9-10.3,10.6-15.7

C340.4,289.4,335.8,286.5,331,283.6z"></path>
					<path d="M407.8,199.9c-6.5-4.4-13.1-8.6-19.9-12.6c-3.8,6.1-7.5,12.3-11.3,18.5

c6.5,3.9,12.8,7.9,18.9,12.1C399.2,212.5,403.5,206.2,407.8,199.9z"></path>
					<path d="M469.3,63.2c-7.5-4.5-15.2-8.7-23.1-12.7c-1.9,2-3.9,4.3-6.1,6.9c7.9,4.1,15.8,8.4,23.5,12.9

C465.6,67.7,467.5,65.3,469.3,63.2z"></path>
					<path d="M394.8,219c-6.1-4.2-12.4-8.2-18.9-12c-3.8,6.3-7.5,12.5-11.2,18.7c6.1,3.6,12,7.4,17.8,11.3

C386.6,231,390.7,225,394.8,219z"></path>
					<path d="M369.1,256.8c-5.4-3.7-10.9-7.2-16.6-10.5c-3.5,6-7,12-10.4,17.8c5.2,3.1,10.4,6.4,15.4,9.8

C361.3,268.3,365.1,262.6,369.1,256.8z"></path>
					<path d="M381.8,238.1c-5.8-3.9-11.7-7.7-17.8-11.3c-3.7,6.2-7.3,12.3-10.8,18.3

c5.7,3.4,11.2,6.9,16.6,10.6C373.7,249.9,377.7,244.1,381.8,238.1z"></path>
					<path d="M474.3,57.5c-7.6-4.5-15.2-8.7-22.5-12.4c-1.6,1.4-3.4,3.1-5.2,5c7.6,3.9,15.3,8.2,23,12.7

C471.3,60.8,472.9,59,474.3,57.5z"></path>
					<path d="M448.3,91.2c-7.7-4.5-15.6-8.9-23.8-13.1c-2.6,3.7-5.3,7.6-8.1,11.8

c8.1,4.2,15.9,8.5,23.8,13.1C443,98.8,445.7,94.9,448.3,91.2z"></path>
					<path d="M456.2,80.2c-7.9-4.6-15.9-9.1-23.7-13.1c-2.4,3.2-4.9,6.6-7.5,10.3c8.1,4.1,16,8.5,23.8,13.1

C451.4,86.8,453.9,83.4,456.2,80.2z"></path>
					<path d="M395.6,119.7c-7.8-4-15.7-7.7-23.6-11.1c-2.9,5.2-5.9,10.6-8.9,16.2c7.7,3.4,15.4,7,23.1,10.9

C389.3,130.1,392.5,124.8,395.6,119.7z"></path>
					<path d="M482.3,52.8c-0.9,0.4-1.8,0.9-2.5,1.6c7,4.2,13.9,8.6,20.6,13.1c0.8-1,1.3-1.7,1.7-2.1

C495.7,61.1,489,56.9,482.3,52.8z"></path>
					<path d="M370.5,107.9c-7.9-3.4-15.8-6.5-23.6-9.4c-2.8,5.2-5.6,10.7-8.4,16.4

c7.7,2.8,15.4,5.8,23.1,9.1C364.6,118.5,367.6,113.1,370.5,107.9z"></path>
					<path d="M532.1,120c6.4,5.6,12.5,11.4,18.5,17.3c1.6-2.9,3-5.7,4.2-8.2c-5.9-5.8-11.9-11.5-18.2-17

C535.3,114.6,533.8,117.2,532.1,120z"></path>
					<path d="M323.2,324.9c-3.6-2.5-7.3-4.8-11.2-7.1c-2.7,5.1-5.4,10-7.9,14.7c3.3,2,6.6,4.1,9.8,6.2

C316.9,334.3,320,329.7,323.2,324.9z"></path>
					<path d="M321.2,301.2c-3,5.4-5.8,10.6-8.6,15.7c3.8,2.3,7.6,4.7,11.2,7.1c3.2-4.8,6.5-9.7,9.9-14.8

C329.7,306.4,325.5,303.7,321.2,301.2z"></path>
					<path d="M267.4,163.8c-6.7-1.4-13.3-2.6-19.9-3.6c-1.5,7.1-2.9,14.2-4.1,21.2c6.2,0.9,12.5,2,18.8,3.4

C263.8,177.8,265.5,170.8,267.4,163.8z"></path>
					<path d="M361,125.2c-7.7-3.3-15.4-6.4-23.1-9.1c-2.8,5.7-5.6,11.6-8.3,17.7c7.5,2.7,14.9,5.6,22.4,8.9

C355,136.6,358,130.8,361,125.2z"></path>
					<path d="M500.2,67.7c-6.7-4.6-13.6-8.9-20.7-13.1c-1.3,1.1-2.5,2.3-3.6,3.5c7.3,4.3,14.4,8.9,21.3,13.6

C498.4,70,499.4,68.7,500.2,67.7z"></path>
					<path d="M351.4,143.8c-7.4-3.2-14.9-6.2-22.3-8.8c-2.8,6.1-5.5,12.4-8.3,18.9

c7.2,2.6,14.3,5.4,21.5,8.5C345.3,156,348.4,149.8,351.4,143.8z"></path>
					<path d="M322.7,205.7c-6.4-2.8-12.8-5.3-19.1-7.6c-2.4,6.8-4.8,13.5-7,20.1c6,2.2,12,4.5,17.9,7.1

C317.1,218.8,319.8,212.3,322.7,205.7z"></path>
					<path d="M453.9,132.9c-7.3-4.9-14.7-9.7-22.3-14.2c-3.1,4.6-6.2,9.4-9.5,14.4c7.5,4.5,14.8,9.1,22,14

C447.5,142.2,450.8,137.4,453.9,132.9z"></path>
					<path d="M480.2,95.4c-7.3-5-14.8-9.7-22.5-14.3c-2.3,3.2-4.8,6.6-7.4,10.3c7.7,4.6,15.3,9.4,22.6,14.4

C475.4,102.1,477.9,98.6,480.2,95.4z"></path>
					<path d="M343,215.2c-6.4-3.2-12.7-6.2-19.1-9c-2.8,6.6-5.6,13.1-8.3,19.6c6,2.6,11.9,5.4,17.9,8.4

C336.6,228,339.7,221.6,343,215.2z"></path>
					<path d="M332.9,235.5c-5.9-3-11.9-5.8-17.8-8.4c-2.6,6.5-5.2,12.9-7.7,19.2c5.5,2.4,11,5,16.5,7.8

C326.8,248,329.8,241.8,332.9,235.5z"></path>
					<path d="M332,184.5c-6.8-2.9-13.6-5.6-20.3-8.1c-2.6,6.8-5.2,13.6-7.6,20.3c6.4,2.3,12.8,4.8,19.2,7.6

C326.1,197.8,329,191.2,332,184.5z"></path>
					<path d="M292.8,286.6c-2,5.9-3.8,11.6-5.6,17.2c4,1.8,8.1,3.7,12.1,5.7c2.2-5.4,4.6-10.9,7-16.5

C301.8,290.7,297.3,288.6,292.8,286.6z"></path>
					<path d="M261.9,186.2c-6.3-1.3-12.5-2.4-18.7-3.3C242,189.9,241,197,240,204c5.8,0.8,11.7,1.9,17.5,3.1

C258.9,200.2,260.3,193.2,261.9,186.2z"></path>
					<path d="M463.6,119c-7.3-5-14.8-9.7-22.5-14.3c-2.8,4.2-5.8,8.6-8.9,13.1c7.6,4.5,15.1,9.3,22.3,14.2

C457.7,127.5,460.7,123.1,463.6,119z"></path>
					<path d="M299.5,267.4c-2.2,6.1-4.3,12.1-6.3,18c4.5,2,9.1,4.1,13.6,6.4c2.5-5.7,5-11.5,7.7-17.4

C309.5,272,304.5,269.6,299.5,267.4z"></path>
					<path d="M492.6,78c-7.1-4.8-14.4-9.5-21.9-13.9c-1.7,2.1-3.6,4.5-5.6,7.1c7.6,4.5,15,9.3,22.3,14.2

C489.2,82.6,491,80.2,492.6,78z"></path>
					<path d="M472.4,106.4c-7.3-5-14.9-9.8-22.6-14.4c-2.6,3.7-5.3,7.6-8.1,11.8c7.7,4.6,15.2,9.4,22.5,14.3

C467,114.1,469.8,110.2,472.4,106.4z"></path>
					<path d="M452.1,44.8c7.6,3.9,15,8,22.5,12.4c1.2-1.2,2.4-2.4,3.7-3.5c-7.2-4.3-14.5-8.3-21.8-12

C454.9,42.6,453.5,43.7,452.1,44.8z"></path>
					<path d="M341.7,163.6c-7.1-3.1-14.3-5.9-21.4-8.5c-2.8,6.6-5.5,13.4-8.1,20c6.8,2.5,13.6,5.1,20.4,8.1

C335.6,176.6,338.6,170.1,341.7,163.6z"></path>
					<path d="M456.7,41.6c7.2,3.7,14.5,7.7,21.7,12c0.8-0.7,1.6-1.2,2.6-1.5c-6.9-4.1-13.9-7.9-20.9-11.5

C458.9,40.6,457.8,41,456.7,41.6z"></path>
					<path d="M420.3,181.6c-6.7-4.6-13.7-9-20.7-13.2c-3.6,5.7-7.2,11.6-11,17.6c6.8,4.1,13.5,8.3,19.9,12.7

C412.6,192.9,416.5,187.2,420.3,181.6z"></path>
					<path d="M364.4,174.3c-7.1-3.6-14.2-7-21.4-10.1c-3.2,6.6-6.2,13.1-9.2,19.6c6.8,3,13.6,6.2,20.4,9.6

C357.5,187,360.9,180.8,364.4,174.3z"></path>
					<path d="M314,226.6c-5.9-2.6-11.9-4.9-17.8-7.1c-2.2,6.7-4.3,13.3-6.3,19.8c5.5,2,11,4.2,16.6,6.6

C308.8,239.5,311.3,233.1,314,226.6z"></path>
					<path d="M460.3,40.6c6.9,3.5,13.8,7.3,20.8,11.5c0.1,0,0.2,0,0.3,0C468.2,44.3,463.5,42.2,460.3,40.6

L460.3,40.6z"></path>
					<path d="M298.9,310.5c-4-2-8-3.9-12-5.7c-1.7,5.6-3.4,11-4.8,16.3c3.5,1.5,7,3.2,10.5,5

C294.6,321.1,296.7,315.9,298.9,310.5z"></path>
					<path d="M353.5,194.6c-6.8-3.4-13.5-6.6-20.3-9.6c-3,6.6-6,13.3-8.8,19.9c6.4,2.8,12.8,5.8,19.2,9.1

C346.8,207.6,350.2,201.1,353.5,194.6z"></path>
					<path d="M443.5,148c-7.1-4.8-14.5-9.5-21.9-14c-3.3,5-6.6,10.2-10,15.6c7.3,4.4,14.5,8.9,21.5,13.6

C436.6,158,440.1,152.9,443.5,148z"></path>
					<path d="M432.2,164.3c-7-4.7-14.1-9.3-21.4-13.6c-3.4,5.4-6.9,10.9-10.5,16.7c7.1,4.2,14,8.6,20.8,13.2

C424.9,175,428.6,169.6,432.2,164.3z"></path>
					<path d="M486.9,85.9c-7.2-4.9-14.7-9.6-22.3-14.2c-2,2.7-4.2,5.6-6.5,8.8c7.7,4.6,15.2,9.4,22.5,14.3

C482.9,91.6,485,88.6,486.9,85.9z"></path>
					<path d="M374.7,206.3c-6.6-3.9-13.3-7.6-19.9-11c-3.4,6.5-6.8,12.9-10,19.3c6.3,3.3,12.6,6.7,18.8,10.4

C367.2,218.8,370.9,212.5,374.7,206.3z"></path>
					<path d="M296.2,147.4c-7.1-2-14.2-3.8-21.2-5.3c-2.1,6.8-4.1,13.7-6,20.6c6.7,1.5,13.5,3.1,20.2,5.1

C291.5,161,293.8,154.2,296.2,147.4z"></path>
					<path d="M388.6,77.8c-8.1-3.5-16.2-6.7-24.3-9.6c-2.7,4.2-5.4,8.7-8.1,13.5c8,2.9,16,6.1,24.1,9.5

C383.1,86.5,385.9,82.1,388.6,77.8z"></path>
					<path d="M324.4,255.8c-2.9,6.1-5.7,12.1-8.4,18c4.9,2.5,9.8,5.2,14.8,8.2c3.2-5.7,6.4-11.4,9.8-17.3

C335.2,261.6,329.9,258.6,324.4,255.8z"></path>
					<path d="M288.3,48c7.9,1.1,15.8,2.6,23.8,4.2c2.6-4.2,5.2-8.2,7.8-11.9c-8-1.7-15.9-3.1-23.8-4.3

C293.4,39.8,290.8,43.8,288.3,48z"></path>
					<path d="M497.6,166.6c-6.4-5.6-13-11.1-19.9-16.4c-3.2,4.5-6.6,9.1-10.2,13.9

c6.7,5.2,13.2,10.6,19.6,16.1C490.8,175.6,494.3,171,497.6,166.6z"></path>
					<path d="M315.4,275c-2.7,5.9-5.3,11.7-7.8,17.3c4.5,2.3,8.9,4.8,13.3,7.4c3-5.4,6-10.9,9.2-16.6

C325.3,280.2,320.3,277.5,315.4,275z"></path>
					<path d="M407.2,54.3c8.1,3.5,16.2,7.3,24.2,11.4c2.4-3.1,4.6-6,6.8-8.6c-8-4.1-16-7.8-24-11.3

C412,48.4,409.7,51.2,407.2,54.3z"></path>
					<path d="M351.4,245.7c-5.7-3.4-11.5-6.6-17.4-9.6c-3.1,6.3-6.1,12.5-9,18.6c5.3,2.7,10.7,5.7,16.2,8.9

C344.5,257.7,347.9,251.7,351.4,245.7z"></path>
					<path d="M362.9,226.1c-6-3.6-12.3-7-18.7-10.3c-3.3,6.4-6.4,12.7-9.6,19c5.9,3,11.8,6.3,17.5,9.7

C355.6,238.4,359.2,232.3,362.9,226.1z"></path>
					<path d="M431,66.3c-8-4.1-16.1-7.9-24.2-11.4c-2.4,3.1-5,6.5-7.6,10.2c8.1,3.5,16.2,7.4,24.3,11.5

C426.1,72.9,428.6,69.5,431,66.3z"></path>
					<path d="M486.4,181.2c-6.3-5.5-12.8-10.9-19.5-16.1c-3.6,4.8-7.2,9.8-11.1,14.9

c6.6,5.1,12.9,10.4,19.1,15.8C478.9,190.7,482.7,185.9,486.4,181.2z"></path>
					<path d="M540.1,104.8c6.1,5.4,12,10.9,17.7,16.6c0.6-1.8,0.9-3.6,1.1-5.4c-5.5-5.5-11.2-10.8-17.2-16

C541.4,101.6,540.8,103.2,540.1,104.8z"></path>
					<path d="M379.7,92.1c-8-3.5-16-6.6-24.1-9.5c-2.7,4.8-5.5,9.7-8.3,15c7.9,2.8,15.8,6,23.7,9.4

C374,101.8,376.9,96.8,379.7,92.1z"></path>
					<path d="M283.5,261.1c-1.7,6.3-3.3,12.5-4.8,18.6c4.5,1.6,9.1,3.4,13.6,5.4c2-5.9,4-11.9,6.2-18.1

C293.6,264.9,288.6,262.9,283.5,261.1z"></path>
					<path d="M421.6,38.3c7.8,3.4,15.7,7.1,23.5,11.1c1.7-1.8,3.4-3.4,5.3-5c-7.6-3.9-15.3-7.5-23-10.8

C425.4,35,423.4,36.6,421.6,38.3z"></path>
					<path d="M516.6,140.6c-6.5-5.7-13.2-11.2-20.1-16.6c-2.6,3.7-5.4,7.6-8.3,11.7

c6.9,5.4,13.6,10.9,20.1,16.6C511.2,148.2,514,144.3,516.6,140.6z"></path>
					<path d="M259.1,115.9c7.2,1.1,14.5,2.4,21.9,3.9c2.2-6.3,4.5-12.3,6.8-18.1c-7.6-1.6-15.1-2.9-22.6-4

C263.1,103.5,261,109.6,259.1,115.9z"></path>
					<path d="M311.4,317.4c-3.9-2.3-7.7-4.4-11.7-6.5c-2.2,5.3-4.4,10.5-6.4,15.6c3.4,1.8,6.9,3.7,10.3,5.7

C306,327.4,308.6,322.5,311.4,317.4z"></path>
					<path d="M278.4,280.9c-1.5,6.1-2.8,12-4.1,17.8c4,1.5,8.1,3,12.1,4.8c1.7-5.6,3.6-11.4,5.5-17.3

C287.5,284.3,282.9,282.5,278.4,280.9z"></path>
					<path d="M237.5,227.5c-0.6,6.9-1,13.7-1.3,20.4c4.9,0.7,9.8,1.6,14.8,2.6c0.7-6.6,1.6-13.3,2.6-20.1

C248.2,229.2,242.9,228.2,237.5,227.5z"></path>
					<path d="M264.9,12.5c7.5,0,15,0.3,22.6,0.8c2.7-2.3,5.6-4.3,8.6-6.1c-17.7-1.2-18.2-0.5-24.4,0.6

C269.4,9.2,267.1,10.8,264.9,12.5z"></path>
					<path d="M303.7,333.4c-3.1,5.9-4.9,9.4-7.1,13.6c2.9,1.7,5.6,3.5,8.3,5.3c2.9-4.3,5.6-8.4,8.4-12.7

C310.3,337.4,307,335.4,303.7,333.4z"></path>
					<path d="M307.2,293.4c-2.5,5.6-4.9,11.1-7.1,16.5c4,2,7.9,4.2,11.8,6.5c2.7-5.1,5.6-10.3,8.5-15.7

C316.2,298.2,311.8,295.8,307.2,293.4z"></path>
					<path d="M296.5,35.4c7.9,1.1,15.8,2.6,23.8,4.3c2.6-3.7,5.2-7,7.9-10.1c-8-1.7-15.9-3.1-23.7-4.2

C301.8,28.4,299.2,31.8,296.5,35.4z"></path>
					<path d="M444.8,49.8c-7.8-4-15.7-7.7-23.5-11.1c-2.1,1.9-4.2,4.1-6.5,6.7c8,3.5,16,7.2,23.9,11.3

C440.8,54.1,442.9,51.8,444.8,49.8z"></path>
					<path d="M507.7,153c-6.5-5.7-13.2-11.2-20.1-16.6c-2.9,4.1-6,8.4-9.3,12.8c6.8,5.3,13.4,10.8,19.9,16.4

C501.6,161.4,504.7,157.1,507.7,153z"></path>
					<path d="M257.3,208.4c-5.9-1.2-11.7-2.3-17.5-3.1c-0.9,7-1.6,13.9-2.2,20.8c5.4,0.8,10.8,1.7,16.2,2.9

C254.9,222.2,256,215.4,257.3,208.4z"></path>
					<path d="M273.7,141.8c-7-1.5-14-2.7-21-3.7c-1.8,7.1-3.5,14-4.9,20.8c6.6,1,13.3,2.2,20,3.6

C269.6,155.8,271.6,148.8,273.7,141.8z"></path>
					<path d="M536.9,111.7c6.3,5.5,12.3,11.2,18.2,17c1-2.2,1.9-4.5,2.7-6.9c-5.7-5.7-11.6-11.2-17.7-16.6

C539.2,107.1,538.1,109.3,536.9,111.7z"></path>
					<path d="M359.9,303.3c-4.1,5-8,9.9-11.9,14.7c3.9,3,7.6,6.1,11.3,9.3c4.3-4.4,8.7-9,13.1-13.7

C368.3,310,364.1,306.6,359.9,303.3z"></path>
					<path d="M306.9,247.5c-2.4,6.3-4.8,12.6-7,18.7c5,2.2,10.1,4.6,15.1,7.1c2.7-5.9,5.5-11.9,8.4-18.1

C317.9,252.5,312.4,249.9,306.9,247.5z"></path>
					<path d="M386.7,186.5c-6.7-4-13.7-7.9-20.9-11.6c-3.6,6.5-7.2,13.3-10.3,19.1c6.5,3.3,13.2,7,20,11

C378.9,199.2,382.8,192.8,386.7,186.5z"></path>
					<path d="M405.2,104.1c-8-4.1-16-7.8-24-11.3c-2.8,4.7-5.7,9.7-8.7,14.8c7.9,3.4,15.8,7.2,23.6,11.2

C399.2,113.6,402.3,108.8,405.2,104.1z"></path>
					<path d="M439.6,103.8c-8.1-4.8-16.2-9.2-23.7-13.1c-2.8,4.2-5.7,8.6-8.6,13.3c7.7,4,15.6,8.3,23.5,13

C433.8,112.3,436.8,108,439.6,103.8z"></path>
					<path d="M345,341.7c-3.2-2.8-6.5-5.5-9.9-8.2c-3.7,4.4-7.2,8.7-10.7,12.8c3,2.3,5.9,4.7,8.7,7.2

C337,349.8,341,345.8,345,341.7z"></path>
					<path d="M430.2,117.8c-7.7-4.6-15.6-8.9-23.5-13c-3,4.7-6,9.5-9.1,14.6c7.8,4,15.6,8.3,23.2,12.8

C424,127.3,427.2,122.4,430.2,117.8z"></path>
					<path d="M347.2,318.9c-3.9,4.7-7.7,9.3-11.4,13.8c3.4,2.7,6.8,5.4,10,8.2c4.1-4.1,8.3-8.4,12.5-12.8

C354.7,325,351,321.9,347.2,318.9z"></path>
					<path d="M373.1,286.9c-4.2,5.3-8.4,10.4-12.4,15.5c4.3,3.3,8.4,6.8,12.5,10.3c4.4-4.7,9-9.6,13.6-14.6

C382.3,294.2,377.7,290.5,373.1,286.9z"></path>
					<path d="M423,77.3c-8.1-4.1-16.2-7.9-24.3-11.5c-2.6,3.7-5.3,7.6-8,11.8c8.1,3.5,16.2,7.3,24.2,11.4

C417.7,84.9,420.4,81,423,77.3z"></path>
					<path d="M280.5,121.1c-7.3-1.6-14.6-2.9-21.8-3.9c-2,6.3-3.8,12.9-5.6,19.6c7,1,14,2.3,21,3.8

C276.2,133.8,278.3,127.3,280.5,121.1z"></path>
					<path d="M398.3,167.7c-7.1-4.2-14.4-8.2-21.8-12c-3.3,5.9-6.7,11.9-10.1,18.1c7,3.6,14.1,7.5,21,11.6

C391.1,179.3,394.7,173.4,398.3,167.7z"></path>
					<path d="M460.9,212.9c-6-5.2-12.1-10.3-18.4-15.2c-4.1,5.4-8.3,10.9-12.6,16.5

c6.1,4.7,12,9.6,17.7,14.6C452.2,223.5,456.6,218.2,460.9,212.9z"></path>
					<path d="M474.2,196.6c-6.2-5.4-12.5-10.6-19-15.7c-3.8,5.1-7.8,10.4-11.9,15.8

c6.3,4.9,12.5,10,18.5,15.3C466,206.7,470.1,201.6,474.2,196.6z"></path>
					<path d="M420.1,133.2c-7.6-4.5-15.3-8.7-23.1-12.8c-3.1,5.1-6.3,10.4-9.5,15.9c7.5,3.8,15,8,22.6,12.5

C413.5,143.4,416.9,138.2,420.1,133.2z"></path>
					<path d="M263.5,12.5c2-1.6,4.1-3.1,6.3-4.4c-7,1.3-13.8,2.9-20.5,4.9C254,12.7,258.7,12.5,263.5,12.5z"></path>
					<path d="M446.7,229.9c-5.7-5-11.6-9.9-17.7-14.6c-4.6,6-8.8,11.5-13.2,17.2c5.8,4.5,11.4,9.1,16.9,13.9

C437,241.4,441.9,235.6,446.7,229.9z"></path>
					<path d="M401.5,281.9c-4.8-4.2-9.7-8.2-14.7-12.2c-4.3,5.5-8.6,10.9-12.8,16.2

c4.7,3.7,9.3,7.4,13.7,11.3C392.1,292.1,396.8,287.1,401.5,281.9z"></path>
					<path d="M416.5,265c-5.1-4.5-10.4-8.8-15.8-13.1c-4.4,5.7-8.8,11.2-13.2,16.7c5.1,4,10,8,14.8,12.2

C407.1,275.6,411.8,270.3,416.5,265z"></path>
					<path d="M431.7,247.5c-5.4-4.8-11-9.4-16.8-13.9c-4.5,5.8-8.9,11.5-13.4,17.2

c5.4,4.2,10.7,8.6,15.9,13.1C422.2,258.5,427,253.1,431.7,247.5z"></path>
					<path d="M409.5,149.9c-7.4-4.4-14.9-8.5-22.6-12.4c-3.2,5.5-6.5,11.2-9.8,17.1

c7.4,3.8,14.7,7.8,21.9,12.1C402.6,160.8,406,155.3,409.5,149.9z"></path>
					<path d="M427.8,33.3c7.7,3.3,15.3,6.9,22.9,10.8c1.4-1.2,2.8-2.2,4.4-3.1c-7.4-3.8-14.8-7.3-22.2-10.5

C431.1,31.3,429.4,32.2,427.8,33.3z"></path>
					<path d="M379.1,15.7c7.6,2.2,15.3,4.7,22.9,7.4c1.9-1.1,3.8-1.9,5.9-2.4c-7.4-2.6-14.8-5-22.2-7.2

C383.4,14,381.2,14.7,379.1,15.7z"></path>
					<path d="M403.3,281.7c4.8,4.2,9.4,8.5,13.8,12.9c5-4.9,10.1-9.9,15.2-15c-4.8-4.7-9.7-9.3-14.8-13.8

C412.7,271.2,408,276.5,403.3,281.7z"></path>
					<path d="M313.5,52.6c8,1.7,16,3.7,24.1,6c2.6-4.2,5.3-8.2,7.9-11.9c-8.1-2.3-16.1-4.3-24.2-6

C318.7,44.4,316.1,48.4,313.5,52.6z"></path>
					<path d="M198.7,35.6L198.7,35.6l0.1-0.1L198.7,35.6z"></path>
					<path d="M282.1,191.2c-6.3-1.8-12.7-3.4-19-4.7c-1.6,7-3.1,14-4.4,20.9c5.9,1.3,11.8,2.8,17.8,4.4

C278.2,205,280.1,198.1,282.1,191.2z"></path>
					<path d="M304,126.9c-7.4-2.1-14.8-3.9-22.1-5.5c-2.2,6.3-4.4,12.8-6.5,19.4c7.1,1.5,14.2,3.3,21.3,5.3

C299.1,139.5,301.5,133.1,304,126.9z"></path>
					<path d="M157,229.1c4.6-1.1,9.4-2.1,14.2-2.9c-1.2-6.9-2.2-13.8-3-20.8c-5.2,0.8-10.3,1.9-15.3,3.1

C154,215.5,155.4,222.3,157,229.1z"></path>
					<path d="M418.4,264.8c5.1,4.5,10,9.1,14.8,13.9c5.1-5.1,10.1-10.3,15.2-15.5c-5-5-10.3-9.9-15.7-14.7

C428,254,423.2,259.4,418.4,264.8z"></path>
					<path d="M433.7,247.4c5.4,4.8,10.7,9.7,15.7,14.7c5-5.2,9.8-10.2,14.9-15.7

c-5.3-5.3-10.8-10.4-16.5-15.4C442.8,236.9,438.4,242,433.7,247.4z"></path>
					<path d="M138.1,106.7c-0.2,0-2.6,0.2-2.9,0.3C136.2,106.8,137.2,106.7,138.1,106.7z"></path>
					<path d="M201.1,34.2c-0.3,0.4-0.6,0.8-0.9,1.2c7.1-1.1,14.4-2,21.7-2.6c2.5-3.5,5.3-6.9,8.2-10.1

c-3.2,0.3-6.4,0.6-9.6,1C213.8,26.8,207.3,30.3,201.1,34.2z"></path>
					<path d="M373.9,313.3c4,3.5,7.9,7.2,11.7,10.9c4.8-4.4,9.7-8.9,14.7-13.5c-4.1-4.1-8.3-8-12.7-11.9

C382.9,303.8,378.4,308.6,373.9,313.3z"></path>
					<path d="M150,120c6.3-1.5,12.7-2.8,19.2-3.9c0.9-6.3,2.1-12.4,3.4-18.2c-6.7,1.1-13.3,2.4-19.8,4

C151.6,107.7,150.7,113.8,150,120z"></path>
					<path d="M179.2,50.4c3.8-0.8,7.7-1.5,11.6-2.2c2.3-4.1,4.8-8.1,7.5-11.9c-0.3,0-0.6,0.1-0.9,0.2

C191,40.7,184.9,45.3,179.2,50.4z"></path>
					<path d="M157.4,83c6.6-1.6,13.4-3,20.3-4.1c1.7-5.3,3.6-10.4,5.6-15.3c-7,1.1-13.9,2.5-20.7,4.2

C160.7,72.7,158.9,77.7,157.4,83z"></path>
					<path d="M153,100.8c6.5-1.6,13.1-2.9,19.8-4c1.3-5.8,2.9-11.4,4.5-16.8c-6.9,1.1-13.7,2.5-20.3,4.1

C155.5,89.4,154.2,95,153,100.8z"></path>
					<path d="M388.4,297.9c4.4,3.9,8.7,7.9,12.8,11.9c4.9-4.6,9.9-9.4,15-14.3c-4.4-4.4-9-8.7-13.8-12.9

C397.7,287.8,393,292.9,388.4,297.9z"></path>
					<path d="M148.3,164c0.2,7,0.7,14,1.4,21c5.4-1.3,10.9-2.4,16.5-3.3c-0.4-7.1-0.5-14.1-0.4-21.2

C159.9,161.4,154,162.6,148.3,164z"></path>
					<path d="M149.9,186.3c0.7,7,1.7,14,2.8,20.9c5-1.2,10.2-2.3,15.4-3.1c-0.8-7-1.4-14-1.7-21.1

C160.7,183.9,155.3,185,149.9,186.3z"></path>
					<path d="M157.3,230.5c1.6,6.8,3.3,13.5,5.3,20.1c4.2-1,8.6-1.9,13-2.6c-1.5-6.7-2.9-13.5-4.1-20.3

C166.6,228.4,161.9,229.3,157.3,230.5z"></path>
					<path d="M386.1,13.4c7.4,2.1,14.8,4.5,22.2,7.1c1.1-0.3,2.3-0.4,3.4-0.4c-7.9-2.6-15.8-5-23.7-6.9

C387.4,13.3,386.7,13.3,386.1,13.4z"></path>
					<path d="M148.4,140.7c6-1.5,12.2-2.7,18.4-3.7c0.5-6.7,1.3-13.3,2.2-19.6c-6.5,1.1-12.9,2.4-19.2,3.9

C149.1,127.5,148.6,134,148.4,140.7z"></path>
					<path d="M148.3,162.6c5.7-1.4,11.6-2.6,17.5-3.6c0.1-7,0.4-13.9,0.9-20.8c-6.2,1-12.4,2.3-18.4,3.7

C148.1,148.9,148.1,155.7,148.3,162.6z"></path>
					<path d="M185,62.5c7-1.1,14.2-2,21.5-2.6c2.1-4.8,4.4-9.4,6.8-13.7c-7.3,0.6-14.5,1.5-21.6,2.6

C189.3,53.1,187.1,57.7,185,62.5z"></path>
					<path d="M170.4,115.9c6.5-1,13.2-1.8,20-2.4c1.2-6.3,2.5-12.4,4.1-18.3c-7,0.6-13.8,1.4-20.6,2.5

C172.6,103.5,171.4,109.6,170.4,115.9z"></path>
					<path d="M355.8,35.7c8,2.3,16.1,4.9,24.2,7.8c2.5-3.1,4.9-5.9,7.3-8.4c-8-2.9-16-5.4-24-7.7

C360.9,29.9,358.3,32.7,355.8,35.7z"></path>
					<path d="M363.8,27c8,2.3,15.9,4.9,23.9,7.7c2.2-2.3,4.5-4.5,6.9-6.5c-7.9-2.8-15.7-5.3-23.5-7.6

C368.6,22.6,366.1,24.7,363.8,27z"></path>
					<path d="M179,78.7c6.9-1.1,14-1.9,21.1-2.5c1.8-5.4,3.8-10.5,6-15.3c-7.3,0.6-14.4,1.4-21.4,2.6

C182.6,68.3,180.8,73.4,179,78.7z"></path>
					<path d="M174.1,96.5c6.8-1.1,13.6-1.9,20.6-2.5c1.5-5.9,3.2-11.5,5-16.9c-7.1,0.6-14.2,1.4-21.1,2.5

C177,85.1,175.5,90.7,174.1,96.5z"></path>
					<path d="M168,136.7c6.3-1,12.7-1.7,19.2-2.3c0.8-6.8,1.8-13.3,3-19.7c-6.7,0.5-13.4,1.3-19.9,2.4

C169.3,123.5,168.6,130,168,136.7z"></path>
					<path d="M167.4,182.8c0.4,7.1,0.9,14.1,1.7,21.1c5.2-0.8,10.6-1.5,16-1.9c-0.4-7-0.6-14.1-0.6-21.2

C178.7,181.3,173,182,167.4,182.8z"></path>
					<path d="M169.2,205.3c0.8,7,1.7,13.9,2.9,20.8c4.8-0.8,9.8-1.4,14.8-1.8c-0.8-6.9-1.3-13.9-1.7-21

C179.7,203.8,174.4,204.5,169.2,205.3z"></path>
					<path d="M167.3,181.5c5.6-0.9,11.3-1.6,17.1-2c0-7.1,0.2-14.2,0.6-21.4c-6.1,0.5-12.2,1.2-18.1,2.2

C166.9,167.3,167,174.4,167.3,181.5z"></path>
					<path d="M167,158.9c6-0.9,12-1.7,18.2-2.2c0.4-7,1-14.1,1.9-20.9c-6.5,0.5-12.8,1.3-19.1,2.3

C167.4,145,167.1,152,167,158.9z"></path>
					<path d="M188,290.3c-3.5,0.6-6.9,1.3-10.3,2.1c2.7,6.1,5.5,12,8.4,17.8c3-0.7,6-1.3,9.1-1.8

C192.7,302.5,190.3,296.5,188,290.3z"></path>
					<path d="M502.2,65.4c0.1-0.1,0.1-0.1,0.2-0.2c-6.4-4.3-12.9-8.4-19.6-12.4c-0.1,0-0.2-0.1-0.3,0

C489.1,56.8,495.7,61,502.2,65.4z"></path>
					<path d="M371.6,20.3c7.8,2.3,15.7,4.8,23.5,7.6c2-1.7,4.2-3.2,6.5-4.5c-7.7-2.7-15.3-5.2-23-7.4

C376.2,17.2,373.8,18.6,371.6,20.3z"></path>
					<path d="M162.9,251.8c2,6.6,4.1,13.1,6.4,19.5c3.8-0.9,7.7-1.7,11.7-2.4c-1.9-6.5-3.6-13-5.2-19.7

C171.4,249.9,167.1,250.8,162.9,251.8z"></path>
					<path d="M448.7,229.9c5.7,5,11.2,10.2,16.6,15.5c4.8-5.2,9.5-10.3,14-15.3

c-5.5-5.5-11.3-10.9-17.2-16.1C457.7,219.1,453.3,224.5,448.7,229.9z"></path>
					<path d="M213.8,45.4c2.3-4.1,4.8-8.1,7.6-11.9c-7.4,0.6-14.6,1.5-21.7,2.6c-2.7,3.8-5.2,7.8-7.5,11.9

C199.2,46.9,206.4,46,213.8,45.4z"></path>
					<path d="M187.7,330.4c-3.7-5.2-7.2-10.7-10.7-16.3c-2.5,0.9-4.9,1.8-7.3,2.9c4,6.1,8.3,12.2,11.2,16.1

C183.1,332.1,185.4,331.2,187.7,330.4z"></path>
					<path d="M462.9,212.9c5.9,5.2,11.7,10.6,17.3,16.1c4.5-5,8.8-9.9,13-14.8c-5.7-5.7-11.7-11.2-17.8-16.6

C471.3,202.7,467.2,207.7,462.9,212.9z"></path>
					<path d="M169.8,272.6c2.3,6.4,4.8,12.6,7.5,18.7c3.4-0.8,6.9-1.5,10.4-2.1c-2.2-6.2-4.3-12.5-6.2-19

C177.5,270.8,173.6,271.6,169.8,272.6z"></path>
					<path d="M312.1,107.7c-7.7-2.2-15.3-4.1-22.9-5.7c-2.4,5.8-4.6,11.9-6.9,18.1

c7.4,1.6,14.8,3.4,22.2,5.5C307,119.5,309.5,113.5,312.1,107.7z"></path>
					<path d="M129.6,147.4c0,6.7,0.3,13.5,0.9,20.4c5.5-1.9,11.1-3.5,16.8-4.9c-0.2-6.9-0.2-13.8,0-20.6

C141.2,143.7,135.3,145.5,129.6,147.4z"></path>
					<path d="M185.6,310.4c-3-5.8-5.8-11.7-8.5-17.8c-3.4,0.8-6.7,1.8-9.9,2.9c3.1,6,6.3,11.8,9.7,17.4

C179.7,311.9,182.6,311.1,185.6,310.4z"></path>
					<path d="M541.8,96c0,0.1,0.1,0.1,0.1,0.2c2.7,2.4,5.4,4.9,8.1,7.3C547.3,101,544.6,98.5,541.8,96z"></path>
					<path d="M139.1,104.7c4.2-1.3,8.4-2.5,12.7-3.6c1.2-5.8,2.5-11.4,4.1-16.7c-2.4,0.6-4.7,1.2-7,1.9

C145.2,92.2,141.9,98.3,139.1,104.7z"></path>
					<path d="M263.2,276c-4.5-1.3-9-2.4-13.4-3.4c-0.5,6.4-0.8,12.6-1,18.7c4,0.9,8,1.9,12,3

C261.5,288.4,262.3,282.2,263.2,276z"></path>
					<path d="M0.9,266.2c0.1-1.6,0.2-3.5,0.3-3.8C1,263.6,0.9,264.9,0.9,266.2z"></path>
					<path d="M131.4,125.4c5.7-1.9,11.4-3.6,17.3-5.1c0.7-6.2,1.6-12.3,2.8-18.1c-4.4,1.1-8.7,2.4-13,3.7

C135.7,112.2,133.3,118.7,131.4,125.4z"></path>
					<path d="M129.6,146.1c5.8-2,11.6-3.7,17.7-5.2c0.3-6.7,0.7-13.1,1.4-19.4c-6,1.5-11.8,3.2-17.6,5.1

C129.6,131.7,129.5,138.9,129.6,146.1z"></path>
					<path d="M328.7,73.9c-8-2.3-15.9-4.2-23.8-6c-2.5,4.8-5,9.9-7.5,15.2c7.8,1.7,15.6,3.6,23.5,5.9

C323.4,83.8,326.1,78.7,328.7,73.9z"></path>
					<path d="M11.1,369.8c-0.1-0.3-0.1-0.4,0-0.1c0.4,1.4,0.3,1.2,0.2,0.7C11.3,370.3,11.1,369.9,11.1,369.8

z"></path>
					<path d="M126.4,147.3c0.7-0.2,1.3-0.5,2-0.7c0-3.6,0.1-7.1,0.2-10.6C127.8,139.7,127,143.5,126.4,147.3

z"></path>
					<path d="M140,259.4c2.4,6.4,5.1,12.7,8,19c2.8-1.2,5.7-2.3,8.6-3.4c-2.7-6.3-5.3-12.6-7.7-19.1

C145.9,257,143,258.2,140,259.4z"></path>
					<path d="M133,238.4c1.9,6.6,4.1,13.2,6.5,19.8c2.9-1.2,5.9-2.4,9-3.5c-2.4-6.5-4.5-13.1-6.5-19.7

C138.9,236,136,237.2,133,238.4z"></path>
					<path d="M148.6,279.6c2.9,6.1,6,12.1,9.3,18.1c2.6-1.1,5.3-2.2,8-3.1c-3.1-6-6-12.1-8.8-18.3

C154.3,277.3,151.4,278.4,148.6,279.6z"></path>
					<path d="M124.8,194.3c0.6,7,1.5,14,2.8,21c2.8-1.1,5.6-2.2,8.4-3.2c-1.5-6.8-2.8-13.7-3.9-20.6

C129.6,192.4,127.2,193.3,124.8,194.3z"></path>
					<path d="M254.6,230.5c-1,6.8-1.9,13.5-2.7,20.1c5,1.1,10,2.3,15,3.7c1.2-6.5,2.6-13.1,4.1-19.8

C265.5,233.1,260.1,231.7,254.6,230.5z"></path>
					<path d="M124.7,192.9c2.4-0.9,4.8-1.8,7.2-2.7c-1.1-6.9-1.9-13.8-2.5-20.7c-1.8,0.6-3.5,1.3-5.3,2

C124,178.6,124.1,185.8,124.7,192.9z"></path>
					<path d="M158.6,298.8c3.3,5.8,6.8,11.5,10.5,17.2c2.4-1,4.8-2,7.3-2.9c-3.4-5.6-6.7-11.4-9.8-17.4

C163.9,296.6,161.2,297.7,158.6,298.8z"></path>
					<path d="M124.2,170.1c1.7-0.6,3.4-1.3,5.1-1.9c-0.5-6.9-0.9-13.7-0.9-20.4c-0.8,0.3-1.5,0.6-2.2,0.8

C125.1,155.7,124.5,162.9,124.2,170.1z"></path>
					<path d="M320.3,90.1c-7.8-2.2-15.7-4.2-23.4-5.9c-2.5,5.3-4.9,10.9-7.2,16.7c7.6,1.6,15.2,3.6,22.9,5.7

C315.1,100.9,317.7,95.4,320.3,90.1z"></path>
					<path d="M251.8,251.9c-0.8,6.6-1.4,13.1-1.9,19.5c4.5,1,9,2.1,13.5,3.4c1-6.3,2-12.6,3.3-19.1

C261.7,254.2,256.7,253,251.8,251.9z"></path>
					<path d="M303.6,4.2c-1.9,0.8-3.7,1.7-5.5,2.7c7.5,0.5,15.1,1.4,22.7,2.4c2.6-1.6,5.3-3,8.2-4.1

C321,4.2,317.5,3.6,303.6,4.2z"></path>
					<path d="M305.4,67c7.9,1.7,15.9,3.7,23.9,6c2.6-4.8,5.3-9.3,7.9-13.5c-8.1-2.3-16.1-4.3-24.1-6

C310.5,57.7,307.9,62.2,305.4,67z"></path>
					<path d="M163.1,66.6c0,0.1-0.1,0.2-0.1,0.3c6.8-1.6,13.6-3,20.7-4.2c2-4.8,4.2-9.4,6.6-13.6

c-4.1,0.7-8.2,1.5-12.2,2.3C172.8,56.1,167.8,61.2,163.1,66.6z"></path>
					<path d="M137.3,213.1c1.5,6.8,3.3,13.6,5.2,20.3c4.4-1.5,9-2.8,13.6-4c-1.6-6.8-3-13.6-4.1-20.6

C146.9,210.1,142,211.5,137.3,213.1z"></path>
					<path d="M359,328.8c-4.3,4.4-8.5,8.7-12.6,12.7c3.2,2.8,6.3,5.7,9.3,8.7c4.5-3.7,9.1-7.6,13.7-11.7

C366.1,335.2,362.6,331.9,359,328.8z"></path>
					<path d="M149.8,255.7c2.4,6.5,4.9,12.9,7.6,19.1c3.6-1.2,7.4-2.3,11.2-3.3c-2.3-6.4-4.5-12.9-6.5-19.5

C157.9,253.1,153.8,254.3,149.8,255.7z"></path>
					<path d="M157.9,276c2.7,6.2,5.6,12.4,8.7,18.3c3.2-1.1,6.6-2.1,9.9-2.9c-2.7-6.1-5.2-12.3-7.5-18.7

C165.3,273.7,161.6,274.8,157.9,276z"></path>
					<path d="M142.8,234.7c2,6.7,4.1,13.3,6.5,19.8c4-1.4,8.2-2.6,12.4-3.6c-2-6.6-3.8-13.3-5.4-20.1

C151.8,231.8,147.2,233.2,142.8,234.7z"></path>
					<path d="M373,314.2c-4.5,4.7-8.9,9.3-13.2,13.7c3.6,3.2,7.1,6.5,10.5,9.8c4.7-4.1,9.4-8.3,14.2-12.7

C380.9,321.4,377,317.8,373,314.2z"></path>
					<path d="M276.1,213.1c-5.9-1.7-11.8-3.2-17.7-4.4c-1.3,6.9-2.5,13.8-3.6,20.6

c5.5,1.2,10.9,2.5,16.4,4.1C272.8,226.7,274.4,219.9,276.1,213.1z"></path>
					<path d="M476.1,196.7c6.1,5.4,12.1,11,17.8,16.7c4.2-4.8,8.1-9.5,11.9-14.1c-5.9-5.8-11.9-11.5-18.2-17

C483.9,186.9,480.1,191.8,476.1,196.7z"></path>
					<path d="M156.1,83.4c1.5-4.9,3.1-9.6,4.9-14.2c-4.1,5.1-8,10.4-11.4,15.9

C151.7,84.5,153.9,83.9,156.1,83.4z"></path>
					<path d="M260.7,295.4c-4-1.1-7.9-2.1-11.9-3c-0.2,6.1-0.3,12-0.2,17.8c3.5,0.7,7,1.6,10.5,2.6

C259.4,307.2,260,301.4,260.7,295.4z"></path>
					<path d="M333,354.7c-3.7,3.6-7.4,7.1-11.2,10.6c2.4,2.1,4.7,4.3,6.9,6.5c4.1-3.1,8.2-6.3,12.3-9.5

C338.4,359.7,335.7,357.2,333,354.7z"></path>
					<path d="M137,211.8c4.8-1.6,9.7-3.1,14.7-4.3c-1.1-6.9-2.1-13.9-2.8-20.9c-5.4,1.3-10.6,2.9-15.7,4.6

C134.2,198.1,135.5,205,137,211.8z"></path>
					<path d="M130.5,169.1c0.6,6.9,1.4,13.8,2.4,20.8c5.1-1.7,10.4-3.3,15.8-4.6c-0.7-7-1.2-14-1.5-21

C141.5,165.7,136,167.3,130.5,169.1z"></path>
					<path d="M354.9,350.9c-3-3-6.1-5.8-9.3-8.7c-4.1,4.1-8.1,8-12,11.7c2.8,2.5,5.5,5,8.1,7.6

C346.1,358.2,350.4,354.7,354.9,350.9z"></path>
					<path d="M384.7,13.1c0.6-0.1,1.1-0.1,1.7-0.1c-4.8-1.2-9.7-2.2-14.5-3.2

C376.2,10.8,380.5,11.8,384.7,13.1z"></path>
					<path d="M321.3,4.1c2.8,0.3,5.5,0.6,8.3,1c0.7-0.2,1.4-0.4,2.1-0.6C328.2,4.3,324.7,4.1,321.3,4.1z"></path>
					<path d="M223.2,32.7c7.4-0.6,14.8-0.9,22.4-0.9c2.5-3.5,5.2-6.9,8.2-10.1c-7.5,0-15,0.3-22.3,0.9

C228.5,25.8,225.8,29.2,223.2,32.7z"></path>
					<path d="M354.2,82.1c-8-2.9-16-5.4-24-7.7c-2.6,4.8-5.3,9.8-7.9,15.1c7.9,2.3,15.7,4.8,23.6,7.6

C348.7,91.8,351.5,86.8,354.2,82.1z"></path>
					<path d="M488.3,181.3c6.3,5.6,12.4,11.2,18.3,17.1c3.8-4.6,7.4-9,10.8-13.4c-6-5.9-12.2-11.7-18.5-17.3

C495.5,172.1,492,176.6,488.3,181.3z"></path>
					<path d="M201.5,76.1c7.2-0.6,14.4-0.8,21.8-0.9c2-5.4,4.1-10.5,6.3-15.3c-7.5,0-14.8,0.3-22.1,0.9

C205.4,65.6,203.4,70.7,201.5,76.1z"></path>
					<path d="M336.5,115.6c-7.7-2.7-15.4-5.2-23-7.4c-2.6,5.8-5.1,11.7-7.6,17.9c7.4,2.1,14.9,4.5,22.3,7.2

C331,127.2,333.8,121.3,336.5,115.6z"></path>
					<path d="M196.1,94c7-0.5,14.1-0.8,21.2-0.9c1.7-5.9,3.6-11.5,5.6-16.9c-7.3,0-14.6,0.3-21.7,0.9

C199.3,82.5,197.6,88.1,196.1,94z"></path>
					<path d="M330.7,73.4c8,2.3,16,4.9,24,7.7c2.7-4.7,5.4-9.2,8.1-13.5c-8.1-2.9-16.2-5.5-24.2-7.8

C336,64.1,333.3,68.7,330.7,73.4z"></path>
					<path d="M195,268.6c1.4,6.5,3,12.9,4.8,19.1c3.7-0.3,7.4-0.4,11.1-0.5c-1.3-6.3-2.5-12.7-3.5-19.2

C203.3,268.1,199.1,268.3,195,268.6z"></path>
					<path d="M334.5,333c-3.4-2.6-6.9-5.2-10.5-7.7c-3.2,4.8-6.3,9.4-9.3,13.8c3.2,2.2,6.3,4.4,9.3,6.7

C327.3,341.8,330.8,337.5,334.5,333z"></path>
					<path d="M314,340c-2.2,3.3-5.6,8.4-8.5,12.7c2.7,1.8,5.3,3.8,7.9,5.7c3.3-3.9,6.6-7.8,9.8-11.7

C320.2,344.4,317.2,342.1,314,340z"></path>
					<path d="M347.5,46.5c8.1,2.3,16.2,4.9,24.3,7.8c2.6-3.7,5.2-7.1,7.7-10.2c-8.1-2.9-16.2-5.5-24.2-7.8

C352.7,39.4,350.1,42.8,347.5,46.5z"></path>
					<path d="M288.8,169.1c-6.8-1.9-13.5-3.6-20.2-5c-1.9,7-3.6,14-5.3,21c6.3,1.4,12.7,3,19,4.8

C284.4,183,286.6,176,288.8,169.1z"></path>
					<path d="M371.3,55c-8.1-2.9-16.2-5.5-24.3-7.8c-2.6,3.7-5.2,7.7-7.9,11.9c8.1,2.3,16.1,4.9,24.2,7.8

C366.1,62.7,368.7,58.7,371.3,55z"></path>
					<path d="M215.2,45.3c7.3-0.6,14.8-0.9,22.3-0.9c2.4-4.3,5-8.2,7.6-11.9c-7.6,0-15,0.3-22.4,0.9

C220,37.3,217.5,41.2,215.2,45.3z"></path>
					<path d="M323.8,347.1c-3.4,4-6.5,7.7-9.9,11.7c2.6,2,5,4,7.4,6.1c3.8-3.6,7.5-7.1,11.1-10.7

C329.6,351.8,326.8,349.4,323.8,347.1z"></path>
					<path d="M207.9,59.8c7.3-0.6,14.7-0.9,22.1-0.9c2.2-4.8,4.6-9.4,7-13.7c-7.5,0-15,0.3-22.3,0.9

C212.3,50.4,210,55,207.9,59.8z"></path>
					<path d="M185.6,179.3c5.8-0.4,11.7-0.7,17.7-0.7c0.4-7.1,1-14.3,1.7-21.4c-6.3,0-12.5,0.3-18.7,0.8

C185.8,165.1,185.6,172.2,185.6,179.3z"></path>
					<path d="M186.2,203.3c0.4,7,0.9,14,1.7,21c5-0.4,10.1-0.6,15.2-0.6c-0.3-6.9-0.5-13.9-0.5-21

C197,202.7,191.6,202.9,186.2,203.3z"></path>
					<path d="M230.6,22.1c1.6-1.7,3.2-3.2,4.8-4.7c-4.6,1.7-9.1,3.6-13.5,5.6

C224.8,22.7,227.7,22.4,230.6,22.1z"></path>
					<path d="M248.6,311.3c0.1,5.6,0.3,11.2,0.7,16.7c3,0.6,5.9,1.4,8.9,2.2c0.1-5.3,0.4-10.8,0.8-16.3

C255.5,312.9,252,312.1,248.6,311.3z"></path>
					<path d="M237.7,309.4c0.6,5.7,1.3,11.4,2.2,16.9c2.9,0.4,5.8,0.9,8.8,1.6c-0.4-5.1-0.6-10.7-0.7-16.7

C244.5,310.5,241.1,309.9,237.7,309.4z"></path>
					<path d="M205.2,307.2c-2.1-5.9-4-12-5.8-18.2c-3.6,0.3-7.2,0.7-10.7,1.3c2.2,6.2,4.6,12.2,7.1,18.1

C198.9,307.8,202,307.4,205.2,307.2z"></path>
					<path d="M186.1,202c5.4-0.4,10.9-0.6,16.5-0.7c0-7.1,0.2-14.2,0.6-21.3c-6,0-11.8,0.3-17.6,0.7

C185.6,187.8,185.7,194.9,186.1,202z"></path>
					<path d="M345.4,98.1c-7.9-2.8-15.7-5.3-23.6-7.6c-2.6,5.3-5.2,10.8-7.8,16.6c7.7,2.2,15.4,4.7,23.1,7.4

C339.8,108.8,342.6,103.3,345.4,98.1z"></path>
					<path d="M499.5,166.8c6.4,5.6,12.6,11.4,18.6,17.3c3.4-4.3,6.6-8.5,9.6-12.5c-6-6-12.3-11.8-18.7-17.5

C506,158.2,502.8,162.4,499.5,166.8z"></path>
					<path d="M188.4,134.3c6.5-0.5,13.1-0.8,19.8-0.8c1.1-6.8,2.4-13.4,3.8-19.7c-6.9,0-13.8,0.3-20.5,0.8

C190.3,121,189.3,127.6,188.4,134.3z"></path>
					<path d="M509.5,153.4c6.5,5.7,12.7,11.5,18.7,17.5c3-4,5.8-7.9,8.4-11.6c-6-6-12.3-11.8-18.8-17.6

C515.3,145.4,512.5,149.3,509.5,153.4z"></path>
					<path d="M188,225.6c0.8,6.9,1.7,13.8,2.8,20.5c4.6-0.4,9.2-0.5,13.9-0.6c-0.7-6.8-1.2-13.6-1.5-20.6

C198,225,192.9,225.2,188,225.6z"></path>
					<path d="M191.7,113.4c6.8-0.5,13.6-0.8,20.6-0.8c1.4-6.3,3-12.4,4.7-18.3c-7.2,0-14.2,0.3-21.2,0.9

C194.3,101,192.9,107.1,191.7,113.4z"></path>
					<path d="M194.7,267.3c4.1-0.3,8.3-0.5,12.5-0.5c-1-6.5-1.8-13.2-2.5-20c-4.7,0-9.3,0.2-13.8,0.6

C192.1,254.2,193.3,260.8,194.7,267.3z"></path>
					<path d="M186.3,156.6c6.2-0.5,12.4-0.7,18.8-0.8c0.8-7,1.7-14,2.8-21c-6.6,0-13.2,0.3-19.7,0.8

C187.4,142.7,186.8,149.7,186.3,156.6z"></path>
					<path d="M176.7,249.1c1.5,6.7,3.3,13.3,5.1,19.7c4-0.6,8-1.1,12.1-1.4c-1.4-6.5-2.7-13.1-3.9-19.9

C185.5,247.9,181.1,248.4,176.7,249.1z"></path>
					<path d="M215.5,306.7c-1.6-5.9-3.1-12-4.4-18.3c-3.7,0-7.4,0.2-11,0.5c1.8,6.2,3.7,12.3,5.7,18.2

C209,306.9,212.3,306.7,215.5,306.7z"></path>
					<path d="M345.8,293.1c-3.6,5.3-7.2,10.6-10.6,15.6c4.1,2.8,8.1,5.7,12,8.7c3.9-4.8,7.8-9.7,11.9-14.8

C354.8,299.4,350.4,296.2,345.8,293.1z"></path>
					<path d="M182.2,270.1c1.9,6.5,3.9,12.8,6.1,19c3.5-0.6,7.1-1,10.8-1.3c-1.8-6.2-3.4-12.6-4.9-19.1

C190.1,269,186.1,269.5,182.2,270.1z"></path>
					<path d="M334.5,309.7c-3.4,5.1-6.8,10-10,14.8c3.6,2.5,7.2,5.1,10.6,7.7c3.6-4.5,7.4-9.1,11.3-13.9

C342.6,315.4,338.6,312.5,334.5,309.7z"></path>
					<path d="M176.4,247.8c4.4-0.7,8.9-1.2,13.5-1.6c-1.1-6.7-2.1-13.6-2.8-20.5c-5,0.4-9.9,1-14.7,1.8

C173.5,234.3,174.9,241.1,176.4,247.8z"></path>
					<path d="M299.1,618.9c-7.8-2.1-16.7-4.8-23.5-7.1c-2.1,1.8-4.4,3.4-6.7,4.8c8.3,2.8,18.5,5.7,23,6.9

C294.4,622.2,296.8,620.7,299.1,618.9z"></path>
					<path d="M289.4,546.7c-8-3.3-16-6.9-23.9-10.8c-2.8,4.6-6.5,10.4-8.6,13.5c3.2,1.6,15.3,7.2,24.2,10.9

C283.8,556,286.6,551.5,289.4,546.7z"></path>
					<path d="M307.1,611.8c-7.9-2.1-15.9-4.5-23.9-7.2c-2.3,2.4-4.7,4.7-7.2,6.8c6.7,2.2,15.4,4.9,23.5,7.1

C302.2,616.5,304.7,614.2,307.1,611.8z"></path>
					<path d="M297.8,583.8c-8.1-2.7-16.2-5.8-24.3-9.1c-2.2,3.1-5,7-7.7,10.5c6.4,2.6,15.9,6.3,24.2,9.1

C292.6,591,295.2,587.5,297.8,583.8z"></path>
					<path d="M280.5,561.2c-8.7-3.6-21-9.3-24.2-10.9c-3,4.5-6.7,10-8.1,12.1c5.7,2.8,17.3,8,24.3,10.9

C275.1,569.5,277.8,565.4,280.5,561.2z"></path>
					<path d="M289.6,594.9c-8-2.7-17.3-6.2-24.2-9.1c-2.4,3.1-5.5,6.8-7.3,8.8c8.6,3.5,17.9,6.9,24,9

C284.6,601,287.1,598.1,289.6,594.9z"></path>
					<path d="M303.8,465c-7.1-3.5-14.1-7.2-21-11.1c-3.5,6-7.1,11.9-10.5,17.7c7,4,14.3,7.9,21.9,11.6

C297.3,477.2,300.5,471.2,303.8,465z"></path>
					<path d="M283.4,502.4c-7.7-3.8-15.2-7.8-22.7-12c-1.6,2.6-7.4,11.9-9.8,15.7

c3.2,1.8,15.7,8.6,23.2,12.3C277.1,513.3,280.2,507.9,283.4,502.4z"></path>
					<path d="M273.5,519.4c-7.1-3.5-19.4-10.1-23.2-12.3c-1.7,2.7-6.8,10.6-9.4,14.6

c3.3,1.9,15.6,8.6,23.6,12.5C267.4,529.5,270.4,524.6,273.5,519.4z"></path>
					<path d="M293.5,484.2c-7.5-3.7-14.8-7.5-21.9-11.6c-3.5,5.7-6.9,11.3-10.2,16.8c7.4,4.2,15,8.2,22.6,12

C287.1,495.8,290.3,490.1,293.5,484.2z"></path>
					<path d="M371.7,490.6c-7.1-1.9-14.1-4-21.2-6.4c-2.6,6.5-5.2,12.8-7.8,18.9c7.4,2.5,14.7,4.7,22.1,6.7

C367.1,503.6,369.4,497.2,371.7,490.6z"></path>
					<path d="M247.9,458.2c-6.8-4.5-13.4-9.1-19.9-13.9c-4,5.4-7.8,10.7-11.6,15.9

c6.7,4.9,13.5,9.7,20.5,14.4C240.5,469.2,244.2,463.7,247.9,458.2z"></path>
					<path d="M260.1,440c-6.5-4.3-12.9-8.7-19.1-13.3c-4.2,5.6-8.2,11.2-12.2,16.6c6.4,4.8,13,9.4,19.8,13.9

C252.4,451.5,256.2,445.8,260.1,440z"></path>
					<path d="M326.5,475.2c-7.1-2.9-14.2-6.1-21.3-9.6c-3.2,6.2-6.4,12.2-9.6,18.1c7.4,3.6,14.8,6.9,22.1,10

C320.6,487.7,323.5,481.6,326.5,475.2z"></path>
					<path d="M281.4,453.1c-6.9-3.9-13.6-8-20.1-12.3c-3.9,5.8-7.7,11.5-11.4,17.1

c6.8,4.4,13.8,8.7,20.9,12.8C274.3,465,277.9,459.1,281.4,453.1z"></path>
					<path d="M226.8,443.3c-6.4-4.8-12.7-9.8-18.7-14.9c-4.2,5.2-8.3,10.3-12.2,15.4

c6.2,5.3,12.7,10.4,19.3,15.4C218.9,454,222.8,448.7,226.8,443.3z"></path>
					<path d="M239.8,425.7c-6.2-4.6-12.1-9.4-17.9-14.3c-4.4,5.4-8.8,10.7-13,16c6,5.1,12.3,10.1,18.7,14.9

C231.6,436.9,235.6,431.4,239.8,425.7z"></path>
					<path d="M317.1,494.9c-7.4-3.1-14.8-6.4-22.2-10c-3.2,5.9-6.3,11.6-9.5,17.1

c7.6,3.7,15.2,7.1,22.9,10.3C311.2,506.7,314.2,500.9,317.1,494.9z"></path>
					<path d="M26.6,414c-0.7-1.6-1-2.1,0.6,1.6l-0.1-0.4C26.7,414.2,26.8,414.2,26.6,414z"></path>
					<path d="M220.8,410.5c-5.8-4.9-11.4-10-16.8-15.2c-4.7,5.2-9.3,10.3-13.7,15.3

c5.6,5.4,11.5,10.6,17.5,15.8C212,421.2,216.3,415.9,220.8,410.5z"></path>
					<path d="M285.8,626.7c3.2,0.8,14.1,3.5,21.6,5c2.8-0.4,5.5-1.2,8.1-2.2c-7.5-1.5-15.1-3.2-22.8-5.2

C290.6,625.2,288.2,626.1,285.8,626.7z"></path>
					<path d="M270.2,471.8c-7.2-4.1-14.1-8.4-21-12.8c-3.7,5.6-7.4,11-10.9,16.3c7,4.6,14.2,9,21.6,13.2

C263.3,483.1,266.7,477.6,270.2,471.8z"></path>
					<path d="M356.5,530c-7.6-2-15.2-4.3-22.9-6.9c-2.7,5.7-5.4,11.2-8.1,16.5c7.8,2.6,15.6,5,23.4,7.1

C351.6,541.4,354.1,535.8,356.5,530z"></path>
					<path d="M364.3,511c-7.4-2-14.7-4.2-22.1-6.7c-2.6,6.1-5.3,12-8,17.8c7.6,2.6,15.2,4.9,22.8,6.9

C359.5,523.1,361.9,517.1,364.3,511z"></path>
					<path d="M348.5,547.8c-7.8-2.1-15.6-4.4-23.5-7.1c-2.7,5.3-5.4,10.3-8.1,15.2c8,2.7,15.9,5.1,23.9,7.2

C343.4,558.2,346,553.1,348.5,547.8z"></path>
					<path d="M323.6,591.6c-8-2.2-16.1-4.6-24.2-7.3c-2.6,3.8-5.2,7.3-7.8,10.5c8.1,2.7,16.1,5.1,24.2,7.3

C318.4,598.8,321,595.4,323.6,591.6z"></path>
					<path d="M306.3,570.9c-8.1-2.7-16.1-5.8-24.2-9.1c-2.7,4.3-5.4,8.3-8.1,12.1c8.1,3.3,16.2,6.3,24.3,9.1

C301,579.2,303.6,575.2,306.3,570.9z"></path>
					<path d="M340.3,564c-7.9-2.1-15.9-4.5-23.9-7.2c-2.7,4.8-5.4,9.4-8.1,13.7c8.1,2.7,16.1,5.1,24.1,7.3

C335.1,573.5,337.7,568.9,340.3,564z"></path>
					<path d="M332,578.7c-8-2.1-16.1-4.6-24.1-7.3c-2.7,4.3-5.3,8.4-8,12.2c8.1,2.7,16.2,5.1,24.2,7.3

C326.7,587.1,329.3,583,332,578.7z"></path>
					<path d="M332.2,522.7c-7.6-2.6-15.3-5.5-22.9-8.6c-2.9,5.6-5.8,11.1-8.7,16.3

c7.8,3.2,15.7,6.1,23.5,8.8C326.8,533.9,329.5,528.4,332.2,522.7z"></path>
					<path d="M314.9,556.3c-8-2.7-16-5.7-24-9c-2.8,4.8-5.6,9.3-8.3,13.6c8.1,3.3,16.1,6.3,24.2,9.1

C309.5,565.7,312.2,561.1,314.9,556.3z"></path>
					<path d="M298.5,530.8c-7.8-3.3-15.7-6.8-23.5-10.6c-3,5.1-6,10.1-9,14.8c8,3.9,15.9,7.5,23.9,10.8

C292.8,541,295.6,536,298.5,530.8z"></path>
					<path d="M340.7,503.8c-7.4-2.5-14.8-5.3-22.2-8.3c-2.9,6-5.8,11.8-8.7,17.5c7.6,3.1,15.3,6,22.9,8.6

C335.4,515.8,338.1,509.9,340.7,503.8z"></path>
					<path d="M349.1,483.8c-7.1-2.4-14.2-5.1-21.3-8c-2.9,6.3-5.8,12.5-8.7,18.5c7.4,3,14.8,5.8,22.2,8.3

C343.9,496.5,346.5,490.2,349.1,483.8z"></path>
					<path d="M323.6,540.2c-7.8-2.7-15.7-5.6-23.5-8.8c-2.9,5.2-5.7,10.2-8.5,15c8,3.3,16,6.3,23.9,9

C318.1,550.5,320.8,545.5,323.6,540.2z"></path>
					<path d="M307.8,513.4c-7.7-3.2-15.3-6.6-22.9-10.3c-3.1,5.5-6.2,10.9-9.3,16

c7.8,3.8,15.6,7.3,23.5,10.6C301.9,524.5,304.8,519.1,307.8,513.4z"></path>
					<path d="M301.1,618.9c7.8,2.1,15.6,3.8,23.3,5.4c2.8-2,5.4-4.2,7.9-6.6c-7.9-1.5-15.8-3.4-23.7-5.4

C306.2,614.6,303.7,616.9,301.1,618.9z"></path>
					<path d="M218.4,377.7c-5.2-5-11.9-11.9-14.8-15.1c-3.2,3.1-10.4,10-15.3,14.9

c4,4.3,10.2,10.7,15.6,15.9C208.6,388.2,213.4,382.9,218.4,377.7z"></path>
					<path d="M235.3,393c-5.5-4.7-10.8-9.5-16-14.4c-4.9,5.3-9.8,10.5-14.5,15.7c5.4,5.2,11,10.2,16.8,15.1

C226.1,404,230.7,398.6,235.3,393z"></path>
					<path d="M314.2,444.8c-6.7-3.3-13.3-6.8-20-10.6c-3.6,6.3-7.2,12.4-10.8,18.5c6.9,3.9,13.9,7.6,21,11.1

C307.7,457.6,310.9,451.2,314.2,444.8z"></path>
					<path d="M272.7,421c-6.2-4.1-12.2-8.3-18.1-12.7c-4.3,5.8-8.6,11.5-12.7,17.2c6.2,4.6,12.5,9,19,13.3

C264.7,433,268.7,427.1,272.7,421z"></path>
					<path d="M293,433.5c-6.5-3.7-12.9-7.6-19.1-11.7c-4,6-7.9,12-11.8,17.8c6.5,4.3,13.2,8.3,20.1,12.3

C285.7,445.9,289.3,439.8,293,433.5z"></path>
					<path d="M253.4,407.5c-5.8-4.4-11.5-8.9-17-13.6c-4.6,5.6-9.2,11-13.6,16.5c5.8,4.9,11.8,9.6,17.9,14.3

C244.8,419,249.1,413.3,253.4,407.5z"></path>
					<path d="M5.5,231.7c0.3-1.6,0.7-3.2,1.1-4.8C7.9,220.7,6.3,227.6,5.5,231.7z"></path>
					<path d="M293.3,624c7.6,2,15.2,3.8,22.8,5.2c2.7-1.3,5.4-2.8,7.8-4.5c-7.7-1.5-15.5-3.3-23.3-5.4

C298.3,621.1,295.8,622.6,293.3,624z"></path>
					<path d="M357.2,462.6c-6.7-2.3-13.5-4.8-20.2-7.6c-2.9,6.6-5.7,13.1-8.6,19.5c7.1,2.9,14.2,5.6,21.2,8

C352.2,476,354.7,469.4,357.2,462.6z"></path>
					<path d="M325.7,591.3c8.1,2.1,16.1,4,24,5.5c2.6-3.8,5.2-7.9,7.8-12.2c-7.9-1.6-15.9-3.4-23.9-5.5

C330.9,583.4,328.3,587.5,325.7,591.3z"></path>
					<path d="M334,578.2c8,2.1,16,3.9,23.9,5.5c2.6-4.4,5.1-9,7.6-13.9c-7.9-1.5-15.7-3.3-23.7-5.4

C339.2,569.3,336.6,573.9,334,578.2z"></path>
					<path d="M342.3,563.5c7.9,2.1,15.8,3.9,23.7,5.4c2.5-4.9,4.9-10,7.3-15.4c-7.7-1.5-15.5-3.3-23.3-5.3

C347.5,553.5,344.9,558.6,342.3,563.5z"></path>
					<path d="M317.3,602.5c8,2.1,16,3.9,24,5.5c2.6-3.2,5.3-6.7,7.9-10.5c-8-1.6-16-3.4-24-5.5

C322.6,595.8,319.9,599.3,317.3,602.5z"></path>
					<path d="M315.3,602.7c-8-2.1-16.1-4.6-24.1-7.3c-2.5,3.2-5,6.1-7.5,8.7c8,2.7,16,5.1,23.9,7.2

C310.1,608.7,312.7,605.9,315.3,602.7z"></path>
					<path d="M309.1,611.7c7.9,2.1,15.9,3.9,23.7,5.5c2.8-2.7,5.4-5.6,7.9-8.6c-8-1.6-15.9-3.4-24-5.5

C314.2,606.3,311.7,609.1,309.1,611.7z"></path>
					<path d="M350.5,547.1c7.8,2,15.5,3.8,23.2,5.3c2.4-5.4,4.7-11,7-16.8c-7.5-1.5-15.1-3.2-22.7-5.2

C355.6,536.2,353.1,541.8,350.5,547.1z"></path>
					<path d="M358.5,529.3c7.6,2,15.1,3.7,22.6,5.2c2.3-5.8,4.4-11.9,6.5-18.1c-7.3-1.4-14.6-3.1-21.9-5

C363.4,517.5,360.9,523.5,358.5,529.3z"></path>
					<path d="M0,289.3c0-0.7,0-1.3,0.1-2C0.1,282.1,0,284.3,0,289.3z"></path>
					<path d="M335.7,454.5c-6.7-2.8-13.5-5.8-20.2-9.1c-3.2,6.5-6.5,12.8-9.7,19c7.1,3.4,14.1,6.6,21.2,9.5

C329.9,467.6,332.8,461.2,335.7,454.5z"></path>
					<path d="M378.7,469.1c-6.7-1.8-13.4-3.8-20.2-6.1c-2.5,6.8-5,13.4-7.6,19.9c7.1,2.4,14.1,4.5,21.2,6.4

C374.4,482.8,376.6,476,378.7,469.1z"></path>
					<path d="M373.5,489.7c7,1.8,14,3.5,21,4.8c1.9-6.7,3.8-13.5,5.5-20.4c-6.6-1.3-13.3-2.8-20-4.6

C377.9,476.4,375.7,483.1,373.5,489.7z"></path>
					<path d="M366.2,510.1c7.3,1.9,14.6,3.6,21.9,5c2.1-6.3,4.1-12.7,6.1-19.4c-7-1.4-14-3-21.1-4.8

C370.8,497.5,368.5,503.9,366.2,510.1z"></path>
					<path d="M202.9,394.3c-5.3-5.1-11.6-11.6-15.6-15.9c-3.8,3.8-9.8,9.8-14.4,14.6

c3.6,3.9,10.6,11.1,16.2,16.6C193.6,404.6,198.2,399.5,202.9,394.3z"></path>
					<path d="M206.9,427.4c-6-5.1-11.9-10.4-17.5-15.8c-4.2,4.8-10.1,11.6-12.8,14.8

c4.6,4.4,12,11.1,18.1,16.3C198.6,437.7,202.7,432.6,206.9,427.4z"></path>
					<path d="M202.6,361.5c-4.4-4.8-11.3-12.9-13.6-15.8c-3,2.6-10.7,9.3-16,14.1c4.9,6,9.7,11.6,14.3,16.6

C192.2,371.5,199.4,364.6,202.6,361.5z"></path>
					<path d="M155.2,374.4c-5-6.1-10.8-14-13.6-17.9c-4.4,3.9-11.5,10.4-14.3,13.1

c2.9,4.1,11.5,15.3,14,18.5C144.9,384.3,151.4,378,155.2,374.4z"></path>
					<path d="M175.5,425.4c2.8-3.2,8.3-9.7,12.9-14.8c-5.3-5.1-11.5-11.5-16.3-16.6

c-4.9,5.1-11,11.6-13.4,14.3C161.3,411.1,171,421.1,175.5,425.4z"></path>
					<path d="M225.2,491.8c-4.6-3-15.1-10.3-21-14.7c-3.6,5-5.9,8.2-10.2,14.1c5.8,4.3,17,12.1,21.4,15

C218.1,502.2,221.6,497.1,225.2,491.8z"></path>
					<path d="M236.2,475.5c-7-4.6-13.9-9.4-20.5-14.4c-3.4,4.6-7.6,10.4-10.9,15c5.7,4.3,16,11.4,21,14.7

C229.7,485.1,233.4,479.7,236.2,475.5z"></path>
					<path d="M259.3,489.6c-7.4-4.2-14.6-8.6-21.7-13.3c-3.2,4.8-7.1,10.5-10.4,15.4

c8.7,5.7,19,11.7,22.2,13.6C252.3,500.7,257.5,492.5,259.3,489.6z"></path>
					<path d="M214.4,460.1c-6.6-5-13.1-10.1-19.3-15.4c-2.6,3.3-9.5,12.1-11.4,14.6

c3.2,2.7,12.5,10.3,19.8,15.8C206.7,470.7,211.3,464.4,214.4,460.1z"></path>
					<path d="M151.1,454.9c0.5-0.6,5.8-7.3,10.9-13.5c-6-5.8-13.2-13.2-17.2-17.6

c-2.5,2.9-8.1,9.4-11.2,13.2C136.9,440.7,147.6,451.5,151.1,454.9z"></path>
					<path d="M202.8,476.1c-6.7-5.1-15.7-12.4-19.8-15.8c-3.1,4.1-8.7,11.4-10.5,13.8

c0.2,0.2,11.3,9.4,20.2,16.1C196.3,485.1,200.1,479.8,202.8,476.1z"></path>
					<path d="M181.7,459.2c-6.4-5.5-15.4-13.7-18.6-16.8c-3.6,4.4-8.6,10.7-10.9,13.5

c3.2,3.1,12.8,11.9,18.9,17C174.3,468.9,179.6,462,181.7,459.2z"></path>
					<path d="M248.8,506.3c-8.6-4.9-19-11.5-22.2-13.6c-2.2,3.2-7.8,11.5-9.8,14.4

c10.3,6.6,19.4,12,22.6,13.8C242,516.9,246.7,509.5,248.8,506.3z"></path>
					<path d="M214.7,507.1c-4.5-2.9-15.7-10.8-21.4-15c-3.1,4.3-6.8,9.5-9.3,13.1

c5.7,4.2,17.5,12.5,21.6,15.2C208.8,515.7,212.3,510.7,214.7,507.1z"></path>
					<path d="M171.1,359.6c-4.2-5.2-10.8-14-13.1-17.2c-4.3,3.5-11.6,9.7-15.5,13.2c4,5.6,10,13.5,13.6,17.9

C159.3,370.5,166,364.2,171.1,359.6z"></path>
					<path d="M188.2,344.7c-4.4-5.4-10.1-13.1-12.4-16.3c-4.7,3.6-13.5,10.6-16.7,13.2

c2.3,3.2,8.3,11.3,13,17.1C177.4,354,185,347.5,188.2,344.7z"></path>
					<path d="M186.4,377.3c-4.7-5.1-12.2-14-14.4-16.6c-5,4.5-11.9,11-15,13.9c4.2,5.1,12,14.1,14.9,17.3

C176.9,386.8,181.3,382.4,186.4,377.3z"></path>
					<path d="M193.9,443.7c-6.7-5.7-14.1-12.5-18.1-16.3c-3.5,4.2-9.9,11.8-11.9,14.2

c3.9,3.7,12.6,11.6,18.5,16.7C185.9,453.8,192.1,446,193.9,443.7z"></path>
					<path d="M182.6,504.1c2.7-3.8,6.5-9.1,9.3-13.1c-5.9-4.4-16.4-12.9-20.2-16.1

c-2.6,3.4-7.2,9.7-9.5,12.9C169,493.6,176.6,499.6,182.6,504.1z"></path>
					<path d="M162.8,440.5c3.5-4.2,8.3-10,11.9-14.2c-5.3-5.1-13.8-14-16.8-17.2

c-3.2,3.6-10.2,11.4-12.3,13.8C148.8,426.5,157.9,435.9,162.8,440.5z"></path>
					<path d="M156.8,408c-3.8-4.1-11.2-12.7-15.5-17.9c-3.5,3.6-10,10.4-12.7,13.3

c5.2,6.4,12.9,15.1,15.8,18.3C146.8,419.1,153.3,411.8,156.8,408z"></path>
					<path d="M171.1,392.9c-2.9-3.2-10.8-12.2-15-17.3c-4.4,4.2-9.3,9-13.9,13.7

c4.2,5.1,10.3,12.2,15.4,17.9C161.3,403.1,166.6,397.5,171.1,392.9z"></path>
					<path d="M48.3,456.1c-0.1-0.2-0.2-0.4-0.3-0.6C48.8,457,49.2,457.5,48.3,456.1z"></path>
					<path d="M26.1,167.9c1.5-3,3.2-5.8,5.1-8.6c4-6,3.9-8.6,11-21.1c-4.4,6.2-9.5,15.7-13,23.1l0.8-1.8

c-1.7,3.4-5.6,12.1-6.3,13.5l3.2-6.9C26.7,166.7,26.3,167.3,26.1,167.9z"></path>
					<path d="M48.1,128.4c0.5-0.8,1-1.5,1.6-2.3c-2.2,3.3-4.3,6.7-6.3,10.2c5.4-7.4,7.2-7.6,11.4-14.5

c2.5-4.2,5.4-8.6,8.2-12.6c-3.7,3.8-7,7.8-10.1,12.1c0.1-0.2,0.3-0.4,0.4-0.6c-0.2,0.3-0.4,0.5-0.6,0.8c-0.3,0.5-0.7,1-1,1.4

C49,126.8,49.6,126,48.1,128.4z"></path>
					<path d="M272,574c-7.9-3.3-21.1-9.4-24.3-10.9c-2.1,3-5.2,7.3-7.6,10.6c7.4,3.6,17.1,7.9,24.2,10.9

C265.3,583.2,269.5,577.6,272,574z"></path>
					<path d="M285.5,1.3c5.1-0.4,11.8-0.6,17.4-0.7c-5.2-0.9-8-0.6-20.1-0.3c-0.2,0-1.3,0-1.6,0

c-3.2,0.2-7.3,0.4-8.4,0.5C279.7,0.5,281.4,1.6,285.5,1.3z"></path>
					<path d="M118.2,57.9c0.4-0.3,0.7-0.6,1-0.9c3.4-2.8,6.9-5.6,10.5-8.2c-3.9,1.9-7.7,4.2-11.2,6.7

c-0.1,0-0.1,0.1-0.2,0.1l0,0c-0.5,0.4-1,0.8-1.5,1.1c-0.4,0.3-0.7,0.6-1.1,0.8c-4.2,3.1-8.6,6.6-12.5,10

C110.5,62.6,112.8,62.5,118.2,57.9z"></path>
					<path d="M7.8,222.6c1.4-4.2,3.1-8.2,5-12.2c1-5.3,3-13.7,4.9-19.9c-3,6.4-5.4,13-7,19.9

c0.1-0.6,0.3-1.1,0.3-1.5c0-0.1,0.1-0.3,0.2-0.6c0-0.1,1.1-4.1,1.2-4.3c-0.4,1.5-1.1,3.8-1.3,4.7C12.1,203.9,8.6,218.6,7.8,222.6z"></path>
					<path d="M246.1,562.3c-6.8-3.3-16.5-8.4-24-12.7c-1.9,2.7-5.3,7.4-7.6,10.6c3.2,1.8,15.6,8.5,23.9,12.7

C240.9,569.7,244.1,565.2,246.1,562.3z"></path>
					<path d="M77.5,107.1c4.5-2.4,9.2-4.5,13.9-6.4c1.8-3.2,6.6-10.8,10.2-15.8c-4.7,1.8-9.2,3.8-13.7,6

C84.1,96.2,80.6,101.6,77.5,107.1z"></path>
					<path d="M17.6,219.2c2.8-3.8,5.8-7.4,9-10.8c0.7-7.4,1.7-14.1,2.9-20.4c-3.2,3.4-6.2,7-9,10.7

C19.9,201.9,18.3,212.3,17.6,219.2z"></path>
					<path d="M30.4,186.1c3.4-3.5,7.1-6.8,10.9-9.8c1.4-7.2,3.1-13.9,4.9-19.7c-3.8,3-7.4,6.2-10.8,9.6

C33.1,173.5,31,182.9,30.4,186.1z"></path>
					<path d="M108.8,95.3c5.6-1.4,12.2-2.5,16.8-3.1c2.9-5,6-9.9,9.4-14.6c-5.2,0.5-13.7,2-16.5,2.6

C116.3,83.3,111.6,90.4,108.8,95.3z"></path>
					<path d="M93,131.7c4.1-1.3,11.2-3.2,17.4-4.5c2.2-7.1,4.9-13.8,4.9-13.8c0.4-1.1,0.9-2.1,1.3-3.2

c-3.2,0.6-11.8,2.3-17.2,3.9C97.3,119.3,94.8,125.9,93,131.7z"></path>
					<path d="M55.6,166.3c3.5-2.1,9.1-5.2,14.4-7.8c1.2-6,2.8-12.7,4.7-18.9c-4.7,2.1-11.4,5.6-14.3,7.3

C59.4,150.1,57,159.3,55.6,166.3z"></path>
					<path d="M93.8,493.5c-2.9-3.2-11-12.4-15.9-18.4c-0.9,2.6-1.7,5.2-2.3,7.9c2.6,3.2,10.8,12.8,15.5,18

C91.9,498.4,92.7,495.9,93.8,493.5z"></path>
					<path d="M191.2,28.5c5.1,0.3,10.1,0.8,15.1,1.6c4.7-2.7,11.7-6.1,14.9-7.6c-4.8-0.9-9.6-1.6-14.4-2

C201.6,23,195.6,26.1,191.2,28.5z"></path>
					<path d="M103.1,83.7c3.2-1.1,10.2-3.2,15.1-4.3c3.3-4.6,9-11.3,11.3-13.9c-5,1-9.9,2.3-14.7,3.8

C111.6,72.9,106.3,79.3,103.1,83.7z"></path>
					<path d="M175,29.7c-5.1,2.9-12,7.4-15.2,9.7c4.9-0.6,9.9-0.9,14.9-1c5.4-3.8,8.9-5.9,14.6-9.3

C184.6,29,179.8,29.2,175,29.7z"></path>
					<path d="M16.6,241.4c1.7-2.3,5.9-7.5,9-10.9c0-8.1,0.6-17.6,0.8-20.8c-3.2,3.4-6.2,7-9,10.8

C17.2,223.7,16.6,233.4,16.6,241.4z"></path>
					<path d="M148.4,62.4c5.5-0.3,12.3-0.2,17.2,0c3.6-4,8-8.3,11.6-11.4c-5.5-0.5-11.1-0.7-16.6-0.6

C156.4,54.1,151.2,59.3,148.4,62.4z"></path>
					<path d="M88.2,151.1c3.2-1.1,11.8-3.8,17.6-5.3c1.3-6.5,3.4-14.3,4.3-17.5c-6.6,1.4-14,3.5-17.5,4.6

C90.9,138.9,88.8,147.9,88.2,151.1z"></path>
					<path d="M76.9,473.9c-5.4-6.6-12.2-15.8-14.5-19c-0.8,2.7-1.5,5.5-1.9,8.4c3.7,5.2,9.4,12.7,14.1,18.6

C75.2,479.1,76,476.4,76.9,473.9z"></path>
					<path d="M46.9,443.3c-0.2,2.6,0,5.3,0.4,7.9c4,6.5,10,15.4,12.3,18.6c-0.2-2.4-0.2-4.9,0-7.3

C55.3,456.4,50,448.3,46.9,443.3z"></path>
					<path d="M92.1,502.5c-0.5,2-0.9,4.1-1,6.1c6.1,6.6,13.2,13.6,16.4,16.7c0.4-1.9,0.9-3.8,1.5-5.6

C105.7,516.6,97.1,507.9,92.1,502.5z"></path>
					<path d="M3.8,267.4c0,4.6,0.4,13.5,1.1,20.9c1.4-4,3.1-7.8,5-11.6c-0.7-7.1-1-14.1-1.1-21.1

C6.8,259.4,5.2,263.4,3.8,267.4z"></path>
					<path d="M254.8,549.5c-6.1-3-19.5-10.1-23.9-12.6c-2.1,3.2-5.4,8-8.2,12c8.6,4.9,18.2,9.8,24,12.7

C249.5,557.5,252.3,553.3,254.8,549.5z"></path>
					<path d="M104.3,164.5c4.7-1.4,12.9-3.5,19.3-4.8c0.5-5.8,1.8-13.9,2.4-17.1c-6.4,1.1-15.9,3.2-19.1,4

C105.5,153.5,104.6,161.3,104.3,164.5z"></path>
					<path d="M229.4,536c-6.7-3.9-15.8-9.4-22.9-14c-2.9,4.2-5.8,8.4-8.3,12c3.2,2.1,15.9,10,22.9,14

C223.6,544.5,227.2,539.2,229.4,536z"></path>
					<path d="M238.8,521.8c-3.2-1.8-15.6-9.2-22.6-13.8c-3.5,5.2-6.6,9.6-9.1,13.2c7.7,5,16.1,10.1,22.8,14

C232.6,531.1,236.8,524.8,238.8,521.8z"></path>
					<path d="M238,573.5c-8.2-4-20.7-10.8-23.9-12.7c-1.9,2.6-4.7,6.4-6.8,9c8.2,4.7,17.1,9.3,23.7,12.5

C233.3,579.7,236.4,575.7,238,573.5z"></path>
					<path d="M68.1,178.6c4.4-2.2,12.4-5.7,16.2-7.2c0.3-3.2,1.4-12.2,2.6-18.7c-6.2,2.2-12.9,5.1-16.1,6.6

C69.6,165.6,68.7,172.1,68.1,178.6z"></path>
					<path d="M112.4,511.8c1.3-2.6,3.5-6.6,4.6-8.4c-5.3-5.1-14.9-15.1-17.6-17.9c-1,1.8-3.2,6.1-4.3,8.7

C100.1,499.5,106.6,506.2,112.4,511.8z"></path>
					<path d="M39.2,400.8c-3.6-6.9-8.6-17.6-10-20.8c-1.3,3.4-2.4,6.8-3.2,10.3c3,6.7,7.7,16.3,9.9,20.4

C36.8,407.4,37.9,404.1,39.2,400.8z"></path>
					<path d="M84.4,191.2c4.7-2,14.6-5.7,17.8-6.8c0-3.2,0.2-12.4,0.7-18.3c-5.3,1.6-12.1,3.9-17.8,6.1

C84.7,178.4,84.4,185.2,84.4,191.2z"></path>
					<path d="M83.2,118.6c5-2,12.5-4.5,15.7-5.4c2.8-6.5,6.4-13.4,8.2-16.6c-5.2,1.3-10.4,2.9-15.4,4.8

C88.7,106.9,84.6,115.4,83.2,118.6z"></path>
					<path d="M108.2,295.7c2.3,5.1,6.1,13.1,9.3,19.2c4.9-3.6,11-7.9,16.5-11.6c-3.1-6-7.5-15.2-8.9-18.4

C121.5,287,111.6,293.4,108.2,295.7z"></path>
					<path d="M84.4,192.4c0.1,7.4,0.7,16.1,1,19.3c5-2.3,13.8-6.1,17.8-7.6c-0.5-5.7-0.9-12.5-1-18.5

C99,186.6,89.9,190.1,84.4,192.4z"></path>
					<path d="M13.4,298.6c2.1-3.9,4.5-7.6,7-11.3c-0.8-4.3-2.5-16.1-3-21.3c-1.7,2.3-5.3,8-7,11.3

C10.7,280.5,12,291,13.4,298.6z"></path>
					<path d="M25.2,344.7c-1.3-3.6-4.7-14.2-6.7-21.4c-1.9,3.6-3.6,7.3-5,11.2c1.7,6.2,4.1,14.1,6.6,21.2

C21.6,352,23.3,348.3,25.2,344.7z"></path>
					<path d="M26.3,229.9c2-2.1,7-7,10.9-10.3c0-4.6,0.4-16.1,0.8-20.6c-3.8,3.2-7.5,6.5-10.9,10.1

C26.9,212.3,26.3,222.8,26.3,229.9z"></path>
					<path d="M76,138c3.2-1.4,10.2-4.1,15.9-6c1.9-6.1,4.4-12.9,6.5-17.7c-3.2,0.9-10.6,3.3-15.7,5.4

C80.3,125.4,77.6,133,76,138z"></path>
					<path d="M39,197.1c3.8-3,9.6-7.1,12.8-9.2c0.6-6.6,2.2-16.6,2.8-19.8c-4.4,2.7-8.6,5.7-12.7,8.9

C40.4,184.4,39.3,193.8,39,197.1z"></path>
					<path d="M59.7,470.3c-2.3-3.2-8.5-12.5-12.3-18.5c0,0.2,0.1,0.4,0.2,0.6c0,0.2,0.1,0.4,0.2,0.7

c0.4,1.8,1.1,3.6,1.9,5.2c0.5,0.8,1.1,1.7,1.6,2.6c0.3,0.4,0.5,0.8,0.8,1.2c1.5,2.4,3.3,5.2,5.5,8.4c1.4,2,2.7,4,4.1,6.1

C60.7,474.5,60,472.4,59.7,470.3z"></path>
					<path d="M145.7,562.9c13.5,9.9,10.9,8.1,18.8,13.1c0.2-0.1,0.3-0.2,0.5-0.3

c-6.3-4.1-13.4-9.2-19.1-13.4C145.8,562.5,145.7,562.7,145.7,562.9z"></path>
					<path d="M49,457.1c-1-2.1-1.8-4.3-2.3-6.6c-1.8-2.9-7.7-13.1-10.8-19c0.5,2.2,1.3,4.4,2.3,6.4

c0.3,0.5,0.5,1,0.8,1.4c0.3,0.5,0.6,1,0.9,1.5c3.8,6.7,6.4,11.4,8.5,15.1C48.5,456.4,48.8,456.8,49,457.1z"></path>
					<path d="M108.6,530.9c0.1,0.3,0.3,0.7,0.4,1c13.7,13,11.9,11.2,16.7,15.2c-0.2-0.3-0.3-0.6-0.3-0.9

C119.7,541.2,111.9,534,108.6,530.9z"></path>
					<path d="M8.8,356.1c-1.5-5.5-3.2-12.9-4.4-19.2c0,0.6,0.1,1.1,0.1,1.7c0.1,1.8,0.4,3.6,0.7,5.4

c0.2,1.3,0.7,3.5,1,4.8c1.7,7.7,3,13.1,4,17.6C9.4,362.4,8.8,356.3,8.8,356.1z"></path>
					<path d="M17,388.6c-0.8-3.2-1.2-6.5-1.3-9.8c-2.8-8-5.5-17.1-6.4-20.3c0.1,3.3,0.6,6.6,1.4,9.8

c0,0.1,0,0.2,0.1,0.3c0.1,0.4,0.2,0.8,0.4,1.2C12.4,373.8,15.7,384.9,17,388.6z"></path>
					<path d="M396.9,15.5c-4-2.6-23.4-6.9-26.8-7.6c2.3,0.5,4.6,1.4,6.7,2.6

C384.3,12.1,392.6,14.3,396.9,15.5z"></path>
					<path d="M304.8,0.7c3.3,0.7,6.6,1.6,9.8,2.9c3.2,0,13,0.2,19.1,0.7C327,1.2,323.6,1.3,316.1,1

C312.9,0.8,309.9,0.7,304.8,0.7z"></path>
					<path d="M260.3,619.2c-7.3-2.5-16.9-6.2-22.3-8.3c-1.5,0.6-3.2,0.8-4.8,0.8c2.9,1.2,5.5,2.2,7.7,3.1

c-2.9-1.1-5.7-2.2-8-3.1c0,0-0.8-0.1-0.9-0.1c1.7,0.7,20.6,7.9,22.3,8.1c-0.9-0.3-2.1-0.7-3.5-1.2

C256.7,620.3,257.9,619.8,260.3,619.2z"></path>
					<path d="M281.6,604.1c-6.9-2.4-16.2-5.8-24-9c-2.9,3.2-4.1,4.3-6.8,7c3.2,1.3,15.5,6.1,23.6,8.8

C276.1,609.6,279.2,606.7,281.6,604.1z"></path>
					<path d="M284.7,1.5c-0.2,0-0.5,0-0.8,0c3.6,0.7,7.2,1.7,10.7,2.9c4.3-0.3,11.6-0.7,18.6-0.8

C305.3,0.3,302.1,0.2,284.7,1.5z"></path>
					<path d="M26.1,176.2c2.8-3.7,5.8-7.3,9-10.6c1.6-5.1,4.4-13,7.1-19.3c-3.2,3.4-6.2,6.9-9,10.6

C30.6,162.9,27.8,170.6,26.1,176.2z"></path>
					<path d="M43.3,144.4c3.4-3.3,7-6.5,10.8-9.4c3-6.9,7.1-14.7,8.9-17.9c-3.7,2.8-7.3,5.9-10.7,9.2

C49.1,131.9,44.7,141.2,43.3,144.4z"></path>
					<path d="M15.4,195.3c7.1-17.5,5.2-14.5,9.8-25.4C21.8,176.6,18.3,186.7,15.4,195.3z"></path>
					<path d="M97.4,77c-0.6,0.3-1.2,0.6-1.7,1s-1.1,0.6-1.7,0.9c-2.1,1.3-4.1,2.6-6.1,4

c-0.8,0.9-1.6,1.9-2.5,2.9c-0.3,0.3-0.6,0.7-0.9,1c-1.6,1.9-3.2,3.7-4.7,5.7c-0.8,1-1.6,2.1-2.4,3.1c-0.6,0.8-1.2,1.6-1.8,2.4

c3.9-2.7,8-5.3,12.1-7.5c3.8-5.1,7.8-10,12-14.7c-0.5,0.3-1,0.6-1.5,0.9C97.9,76.7,97.6,76.8,97.4,77z"></path>
					<path d="M257.5,2.5c-18.3,2.1-9.7,1.1-6.5,0.9C252.4,3.2,256,2.7,257.5,2.5z"></path>
					<path d="M45.2,132.9l2.1-3.4C46.4,131.1,45.7,132.1,45.2,132.9z"></path>
					<path d="M45.2,132.9l-4.1,6.6C44,135.2,43.5,135.8,45.2,132.9z"></path>
					<path d="M264.2,1.7c-4.5,0.5-8.9,1.1-12.5,1.7c3.8-0.1,7.6,0,11.3,0.4c6.7-1.1,15.8-2,19-2.3

c-5.2-0.6-10.5-0.6-15.7,0c0.9-0.1,1.7-0.2,2.6-0.3C273.5,0.8,269,1.2,264.2,1.7z"></path>
					<path d="M482.5,52.6c-0.4-0.6-20.1-11.3-20.7-11.7c0.3,0.2,0.7,0.4,0.9,0.7

C469.5,45.1,476.6,49,482.5,52.6z"></path>
					<path d="M492.4,58.6l-9.1-5.8c0.2,0.1,0.3,0.2,0.4,0.4C486.7,55,489.6,56.8,492.4,58.6z"></path>
					<path d="M322.4,1c-0.3,0-0.6-0.1-0.7-0.1L322.4,1z"></path>
					<path d="M49.1,457.5c0.3,0.5,0.5,0.9,0.6,1L49.1,457.5z"></path>
					<path d="M49.1,457.5c-0.6-1-1.4-2.3-1.4-2.4L49.1,457.5z"></path>
					<path d="M37,435.9l0.9,1.7l0,0C37.6,437.1,37.3,436.5,37,435.9z"></path>
					<path d="M60.9,475.6c0.5,0.6,0.9,1.2,1.4,1.8c-0.1-0.2-0.3-0.4-0.4-0.6

C59.3,473.2,56.1,468.3,60.9,475.6z"></path>
					<path d="M0.2,297.3c0.2,5.4,0.3,7.2,0.1,1.6C0.2,298.4,0.2,297.8,0.2,297.3z"></path>
					<path d="M1.5,258.9c0-0.4,0.1-0.9,0.2-1.4L1.5,258.9z"></path>
					<path d="M1.9,254.8l0.4-3.7C2.3,251.1,2.1,252.8,1.9,254.8z"></path>
					<path d="M1.8,256.2c0.1-0.5,0.1-0.9,0.2-1.4L1.8,256.2z"></path>
					<path d="M1.6,257.5l0.1-1.2C1.7,256.7,1.7,257.1,1.6,257.5z"></path>
					<path d="M18.3,392.7l-0.1-0.4C18.1,392.3,18.3,392.7,18.3,392.7z"></path>
					<path d="M18,391.9C15.2,383.1,16.9,388.6,18,391.9z"></path>
					<path d="M18,391.9c0,0.1,0.1,0.2,0.1,0.4l0.1,0.2C18.1,392.3,18.1,392.1,18,391.9z"></path>
					<path d="M18.9,394.5c0-0.1-0.1-0.2-0.1-0.3C18.6,393.7,18.7,394,18.9,394.5z"></path>
					<path d="M4.9,342.6C3.7,335.6,5.5,345.8,4.9,342.6z"></path>
					<path d="M130.8,48.1c3.4-2.4,10.4-7,16.1-10.3c-6.4,3.4-12.6,7.1-18.6,11.2l-1.3,0.9l-2.2,1.4l-5.3,3.5

l-0.2,0.1c0.5-0.4,1-0.7,1.6-1C124.2,51.8,127.4,49.8,130.8,48.1z"></path>
					<path d="M59.1,113.1C72.7,97.2,69,100.8,76,93c-5.4,5.4-18.9,22.4-21.2,25.6c0.5-0.7,1-1.3,1.5-1.9

C57.1,115.6,58.3,114.1,59.1,113.1z"></path>
					<path d="M263.8,3.9c4,0.7,7.9,1.6,11.7,2.8c3.2-0.5,11.9-1.6,18.1-2.2c-2.8-1-5.7-1.8-8.6-2.4

c-0.1,0-1.3-0.3-1.4-0.3C281.4,1.4,265.9,3.5,263.8,3.9z"></path>
					<path d="M187.1,19.8c-0.1,0-0.1,0.1-0.2,0.1l0,0c-0.8,0.3-1.5,0.6-2.3,0.9c-1.6,0.7-3.2,1.3-4.7,2

c4.2-1.1,8.4-1.9,12.7-2.4c6.3-2.8,14.2-5.7,17.4-6.8c-0.2,0-0.3,0-0.5,0.1c-0.7,0.1-1.3,0.2-2,0.3c-0.3,0-0.5,0.1-0.8,0.1

c-3,0.4-6,1-8.9,1.7c-3,1-5.9,2.1-8.8,3.2L187.1,19.8z"></path>
					<path d="M69.7,487.6c5.9,7.8,4.9,6.5,6.7,8.6c-0.3-0.3-0.5-0.7-0.7-1.1

C71.3,489.7,67.4,484.4,69.7,487.6z"></path>
					<path d="M521.9,79.1c-1.6-1.2-16.5-12.1-18.2-13.2c0,0,0,0.1,0,0.2c6.1,4.2,12,8.5,17.8,12.9

L521.9,79.1z"></path>
					<path d="M262.9,622.5c12.2,4,16,4.9,21,3.9c-7.5-2-19-5.6-22.2-6.7c-1.9,0.5-3.8,0.6-5.7,0.5

c2.1,0.7,4,1.3,5.8,1.9c-3.1-1-6.5-2.1-6.5-2.1C259.4,621.4,258.7,621.2,262.9,622.5z"></path>
					<path d="M11,369.1c0-0.2-0.1-0.4-0.1-0.6c0-0.1,0-0.1,0-0.2c0,0.1,0.1,0.5,0.1,0.5

C11.2,370,11.1,369.9,11,369.1z"></path>
					<path d="M523,80l-0.4-0.1c4.6,3.6,17.5,14.2,17.5,14.2L523,80z"></path>
					<path d="M13.4,200.8c0.2-0.5,0.6-1.6,1.4-4C14.3,198,13.7,199.8,13.4,200.8z"></path>
					<path d="M13.3,201.1c-0.3,0.8-0.1,0.5,0.1-0.3C13.3,201,13.3,201.1,13.3,201.1z"></path>
					<path d="M10.4,367c-0.5-1.8-0.3-1.2,0.2,0.7c0,0,0-0.1,0-0.1C10.5,367.4,10.4,367.2,10.4,367z"></path>
					<path d="M8.7,217.9c-0.5,2.2-1.1,4.7-1.7,7.3C8.1,221.5,10.2,211.7,8.7,217.9z"></path>
					<path d="M129.7,54.7c4.7-1.4,9.4-2.6,14.3-3.4c5-4.4,10.1-8.3,14-11.1c-4.7,0.7-9.3,1.8-13.8,3

C140.9,45.5,134,50.9,129.7,54.7z"></path>
					<path d="M176.1,29.1c4.7-0.5,9.5-0.6,14.2-0.6c4.6-2.6,10.2-5.5,15.7-8c-4.5-0.2-9.1-0.1-13.6,0.3

C189.2,22.2,181.1,26.2,176.1,29.1z"></path>
					<path d="M64.3,115.4c3.9-2.9,8.1-5.5,12.3-7.8c3.2-5.6,6.7-11,10.4-16.2c-2.4,1.3-9.3,5.5-12.1,7.6

C72.6,102.1,67.3,110.1,64.3,115.4z"></path>
					<path d="M2.9,310.3c0.6,4.2,1.2,8.4,2,12.6c0.7-3.9,1.6-7.7,2.9-11.4c-1.1-6.1-2.7-17.9-3-21.1

c-0.1,0.2-0.4,1.6-0.5,1.8c-1,3.3-1.8,6.6-2.3,10c0,0.4,0.1,0.8,0.2,1.2C2.2,304.6,2.9,310,2.9,310.3z"></path>
					<path d="M24.9,404.9c0,0.2,0,0.4,0,0.6c0.2,1.7,0.4,3.4,0.8,5c1.8,4.2,3.8,8.3,5.9,12.5

c0.2,0.5,0.5,0.9,0.7,1.4c0.9,1.8,1.8,3.7,2.8,5.5c-0.6-2.8-0.8-5.7-0.7-8.6c-3.5-6.7-8-16.2-9.6-19.9

C24.7,402.6,24.8,403.8,24.9,404.9z"></path>
					<path d="M47.3,154.7c2.7-2,8.3-5.9,12.6-8.5c1.7-5.6,4.6-13.5,6.9-18.8c-4.3,2.5-8.5,5.2-12.5,8.2

C51.8,141.4,48.9,149.6,47.3,154.7z"></path>
					<path d="M10.1,231.4c2.1-4,4.4-7.8,7-11.4c0.6-6.9,2.3-17.3,2.9-20.5c-2.6,3.7-4.9,7.5-7,11.5

C11.6,217.8,10.4,228.2,10.1,231.4z"></path>
					<path d="M146.1,558.3c0.8-1.4,1.8-3.2,2.7-4.6c-7.5-5.6-16.2-12.8-19.4-15.5c-0.8,1.6-1.5,3.3-2.1,5.1

C130.5,546,140,553.7,146.1,558.3z"></path>
					<path d="M146,321.7c5.9-4.4,11.8-8.5,17.3-12.3c-3.6-5.9-7.9-13.5-9.8-17.2

c-5.5,3.4-11.1,6.9-17.8,11.4C137.3,306.9,141.8,315,146,321.7z"></path>
					<path d="M104.5,204.8c0.7,6.9,2.1,15.4,2.6,18.6c5.8-2.5,15.9-6.4,19.1-7.6c-1.1-5.9-1.9-11.9-2.5-17.8

C120.3,199.2,110.7,202.4,104.5,204.8z"></path>
					<path d="M263.8,585.2c-6.5-2.7-15.3-6.6-24.2-10.9c-1.7,2.3-4.9,6.4-7,8.9c3.2,1.6,16.6,7.7,24,10.8

C258.4,591.9,261.3,588.3,263.8,585.2z"></path>
					<path d="M3.8,266.1c1.4-4,3.1-8,4.9-11.8c0-6.1,0.6-17.4,0.8-20.8c-1.5,3-2.8,6.1-3.9,9.2

C3.9,247.5,3.7,261.1,3.8,266.1z"></path>
					<path d="M148.8,40c-0.7,0.5-1.5,1-2.2,1.5c-0.5,0.3-1,0.7-1.5,1c4.5-1.3,9.1-2.3,13.8-3

c3.2-2.3,9.8-6.6,15.2-9.7c-1.1,0.1-2.1,0.3-3.1,0.5c-0.3,0-0.6,0.1-0.9,0.1c-3.1,0.5-6.3,1.2-9.3,2.1

C156.7,34.8,152.7,37.4,148.8,40z"></path>
					<path d="M101.4,74.3c4.3-2.1,8.8-4,13.4-5.6c4.3-4.7,10-10.1,13.2-13c-4.5,1.5-8.8,3.2-13,5.3

C110.4,65.1,104.3,71.1,101.4,74.3z"></path>
					<path d="M1.8,300.8c0.7-4,1.7-7.9,2.8-11.7c-0.8-8.2-1.1-17.5-1.1-20.9C0.8,276.8,0,281.7,1.8,300.8z"></path>
					<path d="M203,16.3c-0.8,0.3-1.7,0.7-2.5,1c-2.4,1-4.8,1.9-7.1,3c4.5-0.4,9.1-0.5,13.6-0.2

c5.1-2.3,13-5.3,16.7-6.6c-4.3-0.3-8.6-0.3-12.9,0C208.2,14.4,205.6,15.3,203,16.3z"></path>
					<path d="M127.2,543.6c-0.4,1.1-0.6,2.2-0.6,3.4c3.2,2.7,11.2,9.3,18.1,14.4c0.4-1,0.8-1.9,1.3-2.8

C139.8,553.9,130.4,546.3,127.2,543.6z"></path>
					<path d="M91.1,509c0,1.6,0.2,3.2,0.8,4.7c2.9,3.2,10.7,11.2,15.7,16c-0.3-1.3-0.3-2.7-0.2-4.1

C102.5,520.9,96.4,514.8,91.1,509z"></path>
					<path d="M175.7,38.4c5.3,0.1,10.6,0.5,15.9,1.2c4.1-2.9,10.7-7,13.9-8.9c-5-0.8-10.1-1.3-15.2-1.6

C184.8,32.2,178.9,36.1,175.7,38.4z"></path>
					<path d="M224.8,13.1c5.6-1.9,11.8-3.7,17.6-5.1c-11.4-1.2-15.6,0.2-30.4,5.1

C216.2,12.8,220.5,12.8,224.8,13.1z"></path>
					<path d="M244.3,7.8c4.3,0.6,8.5,1.5,12.6,2.6c5.9-1.4,14.2-3,17.4-3.5c-3.9-1.2-7.8-2.1-11.8-2.8

C257.4,4.9,250.1,6.3,244.3,7.8z"></path>
					<path d="M259.3,542.5L259.3,542.5c1.3-2,3.4-5.3,4.6-7.3c-6-2.9-18.1-9.3-23.7-12.5

c-2.3,3.6-7,10.6-8.8,13.4c3.7,2.1,17.1,9.3,23.9,12.6C255.5,548.4,259.1,542.8,259.3,542.5z"></path>
					<path d="M147.6,63.2c-3.1,3.4-8.1,9.5-10.8,13.3c3.2-0.3,11.9-0.7,17.7-0.6c3.1-4.3,8.1-10.2,10.3-12.7

C159.1,62.9,153.4,62.9,147.6,63.2z"></path>
					<path d="M147.2,559.5c-0.5,0.9-1,1.8-1.4,2.8c6.1,4.5,13,9.3,19.2,13.4c0.7-0.7,1.5-1.5,2.1-2.2

C162.2,570.2,153.2,563.9,147.2,559.5z"></path>
					<path d="M104,429.8c-2.1-2.7-10.7-13.8-14.8-19.5c-2.9,3.7-6.7,8.7-8.8,11.6

c2.4,3.2,7.4,10.4,14.9,19.6C97.3,438.5,101,433.5,104,429.8z"></path>
					<path d="M85.2,375c-2-3.2-7.7-13-11.6-20.4c-3.6,3.8-8.8,9.6-10.6,11.7c3.7,7,9.7,17.3,11.8,20.6

C77.5,383.5,81.9,378.5,85.2,375z"></path>
					<path d="M68.1,297.8c2.5,6.9,7,18,8.2,20.6c3.4-2.9,9.2-7.7,13.9-11.3c-1.4-3.2-5.5-13.1-8-20.1

C79.4,289,72.1,294.5,68.1,297.8z"></path>
					<path d="M56,253.9c0.8,4.1,3.3,15.4,4.7,20.7c2.7-2.1,9.6-7.1,14.3-10.3c-1.9-7.1-3.7-15.2-4.6-20.2

C69.2,244.8,57.1,252.8,56,253.9z"></path>
					<path d="M111.6,382.9c-4.5-6.4-9.7-14.3-13-19.6c-2.7,2.6-9.1,9.1-12,12.2c4,6.5,11.8,18,13.2,19.9

C103.1,391.8,110.1,384.5,111.6,382.9z"></path>
					<path d="M112.1,348.9c-3.3-5.4-9.4-16.3-11.2-19.5c-4,3.3-11.6,9.9-13.7,11.9c3.4,6.4,9.3,16.6,11.4,20

C101.5,358.6,109.2,351.5,112.1,348.9z"></path>
					<path d="M27.6,253.6c0.3,3.2,1.5,12.9,3,21.2c1.8-2,7.3-7.5,10.9-10.7c-0.8-4.6-2.2-13.1-3-21

C35.9,245.4,31,250,27.6,253.6z"></path>
					<path d="M131.3,64.4c4.2-0.8,11.8-1.7,16-1.9c2.8-3.1,8.1-8.4,12.2-12c-5.2,0.2-10.4,0.7-15.5,1.5

C139.6,55.9,135.3,60,131.3,64.4z"></path>
					<path d="M144.9,51.2c5.1-0.8,10.3-1.2,15.4-1.4c4.1-3.6,10.5-8.6,13.5-10.7c-5,0.1-10,0.4-14.9,1

C154,43.5,148.1,48.3,144.9,51.2z"></path>
					<path d="M61.6,453.6c-4.5-6.4-11-16.4-13-19.6c-0.8,2.9-1.3,5.8-1.6,8.8c4,6.5,8.8,13.7,12.7,19.2

C60.1,459.2,60.8,456.4,61.6,453.6z"></path>
					<path d="M99.9,275.1c1.7,4.7,5.1,13.3,7.7,19.4c3.7-2.5,14.1-9.1,16.9-10.8c-2.6-6-6.3-15.5-7.4-18.7

C113.8,266.8,105.4,271.7,99.9,275.1z"></path>
					<path d="M111.7,126.9c6.7-1.4,16.2-2.8,18.9-3c1.7-5.5,3.7-10.9,5.9-16.1c-3.2,0.2-12.1,1.1-18.6,2.2

C115.6,115.4,113.1,122.2,111.7,126.9z"></path>
					<path d="M207.9,20.1c4.8,0.4,9.6,1.1,14.3,2c5-2.2,11.4-4.7,15.8-6.2c-4.5-1.1-9-1.9-13.6-2.3

C219.9,15.2,213.2,17.8,207.9,20.1z"></path>
					<path d="M47.6,287.2c2,7.4,5.2,17.1,6.6,21.1c2-1.9,9.3-8.4,12.5-11.1c-1.1-3.2-4.3-12.9-6.5-20.7

C56.4,279.5,50.6,284.5,47.6,287.2z"></path>
					<path d="M61.1,275.8c2.1,7.8,5.4,17.5,6.5,20.7c4.3-3.5,11.4-8.9,14.1-10.8c-2.2-6.3-4.5-13.6-6.3-20.2

C70.7,268.7,64,273.6,61.1,275.8z"></path>
					<path d="M85.8,340.8c-4.1-7.9-8.3-16.9-9.8-20.4c-1.8,1.5-8.9,8-12.3,11.4c2.9,6.6,6.6,14.2,10,20.7

C77.3,348.8,82.7,343.6,85.8,340.8z"></path>
					<path d="M132.7,435.6L132.7,435.6c3.4-4.1,8.7-10.3,11-13c-2.9-3.2-11.4-12.9-15.9-18.4

c-3.2,3.5-9.3,10.3-11.5,12.9c4,4.9,11,13,16.1,18.7C132.6,435.7,132.6,435.7,132.7,435.6z"></path>
					<path d="M26.3,231.2c0.1,5.1,0.4,13.7,1.1,21.1c3.1-3.3,8.9-8.7,10.9-10.5c-0.5-5-1-13.8-1.1-20.9

C33.5,224.1,28.1,229.4,26.3,231.2z"></path>
					<path d="M57.2,411.5c-4-6.5-9.5-16.3-11.8-20.6c-1.4,2.4-4.2,7.9-5.3,10.6c1.7,3.2,7.6,13.8,11.7,20.4

C53.3,418.6,56.1,413.4,57.2,411.5z"></path>
					<path d="M87.1,453.7c-4.7-5.8-12.4-16.1-14.9-19.6c-2.1,3.5-4.1,7.3-5.5,10.2

c4.8,6.7,12.1,16.2,14.7,19.4C82.9,460.9,85.5,456.4,87.1,453.7z"></path>
					<path d="M52.3,377.8c-3.8-7.2-8.2-16.5-10.2-21c-1.8,2.6-5.2,7.7-7.1,11.1c2.4,5.4,6.7,14.5,10.1,21

C47.3,385.3,50.2,380.8,52.3,377.8z"></path>
					<path d="M51.6,208.8c3.1-2.1,9.7-6.1,14.5-8.8c0-6.3,0.4-15.6,0.8-19.7c-3,1.5-10.2,5.5-14.5,8.3

C51.9,194.8,51.6,204.1,51.6,208.8z"></path>
					<path d="M42.1,320.9c-2.4-6.7-5.7-17.7-6.7-21.3c-2.6,2.9-6.4,7.5-9,11.1c1.9,6.8,4.3,14.6,6.7,21.4

C35.4,329,39.4,324,42.1,320.9z"></path>
					<path d="M175,327.4c-3.5-5-7.9-11.6-11.2-16.8c-4.7,3.2-11.9,8.4-17.2,12.3c2.9,4.7,9.3,14.3,11.7,17.7

C161.5,337.9,170.2,331,175,327.4z"></path>
					<path d="M157.3,341.4c-3.3-4.7-9.6-14.2-11.7-17.7c-4,3-13,10-16,12.4c2.9,4.7,8.5,13.2,12.2,18.4

C146,350.8,152.7,345.2,157.3,341.4z"></path>
					<path d="M135,302.5c5.2-3.4,12.9-8.4,17.8-11.3c-3.1-5.9-7.1-14.3-8.5-17.5

c-5.7,3.1-14.8,8.4-18.2,10.5C127.6,287.3,132,296.7,135,302.5z"></path>
					<path d="M125.7,283c4-2.4,12.8-7.5,18.2-10.4c-2.5-5.6-5.9-14.5-7-17.7c-5.5,2.6-15.7,8-18.6,9.5

C119.4,267.6,123.1,277.1,125.7,283z"></path>
					<path d="M93.4,254.4c0.9,3.3,3.9,13.1,6.1,19.5c5.5-3.4,13.9-8.3,17.2-10c-2.3-6.4-5-15.6-5.9-18.8

C104.6,248.2,98.1,251.6,93.4,254.4z"></path>
					<path d="M88.6,233.7c0.6,3.2,2.7,13.1,4.5,19.5c6.1-3.5,12.1-6.6,17.5-9.2c-1.6-5.9-3.2-13-4.3-18.8

C100.6,227.7,91.8,232,88.6,233.7z"></path>
					<path d="M30.8,276.2c1.1,6,2.8,13.7,4.9,21.3c3.1-3.4,8.6-8.9,10.8-11c-1.8-6.7-3.5-14.2-4.8-21.1

C38.2,268.6,32.6,274.3,30.8,276.2z"></path>
					<path d="M103.4,185.1c0.1,5.2,0.4,11.8,1,18.5c6.6-2.5,16-5.7,19.3-6.7c-0.3-3.2-0.9-11.7-0.9-17.6

C117,180.8,109.8,183,103.4,185.1z"></path>
					<path d="M106,224c-0.7-4-2.1-13.2-2.7-18.7c-3.5,1.4-13.2,5.5-17.7,7.7c0.5,5.1,1.6,13.1,2.8,19.5

C91.5,230.7,100.4,226.4,106,224z"></path>
					<path d="M52.9,231.9c0.8,7.5,2.3,17.4,2.9,20.6c4.3-3.2,11.5-7.9,14.4-9.8c-1.4-7.5-2.5-16.9-2.9-20.1

C62.5,225.4,57.2,228.8,52.9,231.9z"></path>
					<path d="M51.7,210.1c0.1,7.1,0.6,15.1,1.1,20.5c4.4-3.1,9.4-6.3,14.5-9.3c-0.6-6.6-1-16.8-1.1-20

C61.3,204,54.6,208.1,51.7,210.1z"></path>
					<path d="M38,220.3c0.1,7.9,0.8,17.6,1.1,20.8c3.8-3.2,9.7-7.7,12.7-9.9c-0.7-7.3-1-14.3-1.1-20.5

C46.5,213.6,40.2,218.5,38,220.3z"></path>
					<path d="M16.7,242.8c0.1,7.9,0.7,16.7,1.1,21.2c2.6-3.6,6.9-8.7,9-11c-0.8-8.1-1.1-17.6-1.1-21.1

C22.5,235.4,19.5,239,16.7,242.8z"></path>
					<path d="M52.6,187.3c4.3-2.7,11.6-6.8,14.5-8.3c0.6-6.5,1.5-13,2.7-19.3c-5,2.4-10.4,5.4-14.4,7.8

C54.8,170.7,53.2,180.8,52.6,187.3z"></path>
					<path d="M118.3,108.9c5.6-0.9,15.4-1.9,18.6-2.2c1.9-4.3,4.8-10.3,7.5-15.1c-5.2,0.2-12.2,0.7-18.2,1.4

C123.6,97.6,120.7,103.5,118.3,108.9z"></path>
					<path d="M20.7,197.4c2.8-3.8,5.8-7.3,9-10.7c0.7-3.5,2.7-12.5,4.9-19.9c-3.2,3.3-6.2,6.9-9,10.6

C24.7,180.6,22.2,190.1,20.7,197.4z"></path>
					<path d="M68.1,125.8c2.5-1.4,9.3-4.8,14.1-6.8c1.4-3.2,5.5-11.8,8.5-17.2c-4.8,1.9-9.4,4-13.9,6.4

C73.6,114.1,70.5,120.2,68.1,125.8z"></path>
					<path d="M115.6,68.4c4.8-1.5,9.7-2.8,14.7-3.8c4-4.4,8.3-8.6,12.7-12.5c-4.8,0.8-9.6,2-14.3,3.4

C125.2,58.7,119.6,64.1,115.6,68.4z"></path>
					<path d="M161.4,49.7c5.5-0.1,11,0.1,16.6,0.6c4.1-3.6,8.4-7,12.8-10.2c-5.3-0.7-12.9-1.1-15.9-1.1

C172.1,40.9,165.3,46.2,161.4,49.7z"></path>
					<path d="M74.9,98.5c3.1-4.3,9.4-11.9,12.2-15c-7.3,5.3-13.3,11.1-22.8,24

C67.6,104.3,71.2,101.3,74.9,98.5z"></path>
					<path d="M131.6,47.7L131.6,47.7c4.1-2,8.3-3.6,12.7-5c3.3-2.3,10.9-7.3,15.8-10.1

c-7.5,2.2-14.4,5.1-28.2,14.9C131.7,47.7,131.7,47.7,131.6,47.7z"></path>
					<path d="M169,26.7c18.2-6.8,19.4-7.8,28-10.8C185.6,19.6,178.8,22.6,169,26.7z"></path>
					<path d="M220.4,9.1c19.1-3.3,18.6-3.8,29.3-5.5C242.1,4.2,224.4,8.3,220.4,9.1z"></path>
					<path d="M324.4,1.3C319.5,0.4,300,0,296.8,0c1.3,0,2.6,0.1,4,0.2C309.5,1,312,0.5,324.4,1.3z"></path>
					<path d="M375.6,10.2c-5.3-3.1-19.8-5.2-27.5-6.3c2.7,0.7,5.2,1.6,7.7,2.8C361.1,7.5,369.6,9,375.6,10.2

z"></path>
					<path d="M418.4,22.4c-2.2-1.6-3.2-1.7-24.8-8.6c1.6,0.5,3.1,1.2,4.6,2.1

C404.9,17.8,415.2,21.3,418.4,22.4z"></path>
					<path d="M440,30.9c-1.9-1.6-20.8-8.7-23.2-9.6c1,0.4,2,0.9,2.9,1.5C427.2,25.5,436.6,29.4,440,30.9z"></path>
					<path d="M461.5,41c-0.8-0.8-21-10.2-22-10.7c0.6,0.3,1.2,0.7,1.8,1.1C445.7,33.4,455.5,37.9,461.5,41z"></path>
					<path d="M127.8,335.7c-4.5-7.3-9.1-15.7-10.8-18.9c-5.4,4-11.7,9-15.1,11.8c3.2,6.1,8.9,15.8,11.1,19.5

C116.5,345.1,124.8,338.2,127.8,335.7z"></path>
					<path d="M47.3,285.9c3.5-3.2,8.9-7.8,12.6-10.7c-0.9-3.2-3.4-13.8-4.7-20.7c-4.3,3.2-10.1,8-12.7,10.3

C43.8,271.8,45.4,279,47.3,285.9z"></path>
					<path d="M25.6,176.9c1.9-6.3,4.6-13.5,7.1-19.4c-4.8,6.6-8.7,13.2-14.1,30.9

C20.6,184.4,22.9,180.6,25.6,176.9z"></path>
					<path d="M42.2,263.5c2.2-1.9,8.2-7,12.7-10.3c-0.6-3.2-2.2-13.4-2.9-20.7c-2.8,2-9,6.7-12.7,10

C40,250.1,41.6,260.3,42.2,263.5z"></path>
					<path d="M42.6,145c1.4-3.2,5.8-12.4,9-18.1c-7.6,7.7-11,12.7-17.9,28.7C36.5,151.9,39.5,148.4,42.6,145

z"></path>
					<path d="M27.3,207.8c3.1-3.2,8.3-7.9,10.9-10c0.4-4.8,1.6-13.7,2.8-20.2c-3.8,3.1-7.5,6.4-10.9,9.8

C28.8,194.2,27.8,201.8,27.3,207.8z"></path>
					<path d="M38,219c2.2-1.8,8.6-6.7,12.8-9.6c0-3.9,0.1-7.8,0.3-11.6c0,0,0.5-7.7,0.5-8.6

c-3.2,2.1-8.7,6-12.7,9.2C38.3,204.9,38,215.7,38,219z"></path>
					<path d="M42,175.6c4.1-3.2,8.3-6.1,12.7-8.8c1.3-6.7,3.8-16.2,4.8-19.4c-4.3,2.5-10,6.5-12.6,8.5

C44.9,162.5,43.1,170.1,42,175.6z"></path>
					<path d="M91.1,306.4c2.9-2.3,12.3-9,15.5-11.1c-3.2-7.2-6.6-16.2-7.7-19.4c-5.4,3.4-12.5,8.2-15.7,10.5

C85.6,293.2,89.7,303.2,91.1,306.4z"></path>
					<path d="M35.7,164.9c3.4-3.4,7-6.6,10.8-9.6c1.8-6,4.7-13.6,7-19.1c-3.8,2.9-7.4,6.1-10.8,9.4

C40.1,151.9,37.7,158.4,35.7,164.9z"></path>
					<path d="M101.3,327.4c4.7-3.9,10.3-8.1,15.1-11.8c-3.9-7.5-7.9-16-9.3-19.2

c-3.1,2.1-11.2,7.9-15.4,11.2C94.9,314.8,99.6,324.2,101.3,327.4z"></path>
					<path d="M55.6,120.8c-0.9,1.5-1.8,2.9-2.7,4.4c3.3-3.3,6.9-6.4,10.7-9.2c3.2-5.7,8.3-13.3,10.6-16.5

C63.7,107.2,62.3,109.7,55.6,120.8z"></path>
					<path d="M144.9,322.5c-3.6-5.8-8.7-14.9-10.3-18.1c-6,4-12.1,8.4-16.5,11.7c1.7,3.3,6.7,12.3,10.8,18.9

C131.7,332.6,140.7,325.7,144.9,322.5z"></path>
					<path d="M71.2,242.1c5.7-3.5,12.5-7.3,16.1-9.2c-1.3-7.3-2.4-16.3-2.8-19.5c-5.2,2.5-13.2,6.8-16.2,8.5

C68.8,226.2,70,235.5,71.2,242.1z"></path>
					<path d="M76,263.6c4.7-3.1,13-8.1,15.9-9.8c-1.7-6.4-3.9-16.4-4.5-19.6c-4.7,2.5-11,6.1-16.1,9.2

C72.4,248.4,74.2,256.9,76,263.6z"></path>
					<path d="M82.7,285c3.2-2.3,10.5-7.2,15.7-10.5c-1.2-3.5-4.6-14-6.1-19.6c-3,1.7-11.4,6.9-15.9,9.9

C78.5,272.4,81.2,280.6,82.7,285z"></path>
					<path d="M54.8,134.4c4-2.9,8.2-5.6,12.5-8.2c2.6-6,5.9-12.6,8.7-17.6c-4.3,2.4-8.4,5-12.3,7.9

C61.2,120.9,57.4,128.5,54.8,134.4z"></path>
					<path d="M85.8,84.3c0.7-0.6,1.4-1.2,2.2-1.7c2.3-2.5,4.6-4.8,7-7.2c-2,2-4,4.2-6.3,6.6

c3.8-2.6,7.8-5.1,11.9-7.3c2.9-3.2,8.8-9.1,13.5-13.3c-5.7,2.8-11,6.4-15.7,10.7c0.4-0.4,0.8-0.7,1.1-1.1c0.3-0.3,0.5-0.5,0.8-0.8

c0.5-0.4,0.9-0.9,1.4-1.3c-0.7,0.5-1.6,1.2-2.4,1.8c1.1-0.9,2.2-1.8,3.3-2.6c1.3-1.1,3-2.5,4.3-3.6c-3.2,2.6-6.3,4.9-10.9,8.9

c0.7-0.6,1.5-1.2,2.2-1.8C90.9,77.8,84,84.4,77.5,91.4c2.4-2.3,4.9-4.5,7.5-6.6C85.2,84.7,85.5,84.5,85.8,84.3z"></path>
					<path d="M67.2,200.8c0,3.2,0.4,13.3,1.1,19.9c2.9-1.7,11-6,16.2-8.5c-0.6-6.5-1-15-1-19.3

C79.5,194.6,71.2,198.6,67.2,200.8z"></path>
					<path d="M67.2,199.5c4.3-2.3,11-5.6,16.2-7.8c0-6.5,0.2-12.5,0.8-19.1c-4,1.6-12.3,5.3-16.2,7.2

C67.4,187,67.1,196.3,67.2,199.5z"></path>
					<path d="M60.8,145.7c2.8-1.7,9.7-5.2,14.3-7.3c1.6-5.1,4.3-12.6,6.7-18.3c-3.9,1.6-11,5.1-14.1,6.8

C64.9,133.3,62.3,140.9,60.8,145.7z"></path>
					<path d="M71,158c4.2-2,10.7-4.7,16.1-6.6c0.6-3.2,2.8-12.5,4.5-18.3c-5.6,1.8-12.7,4.6-15.9,6

C73.8,145.4,72.1,152.4,71,158z"></path>
					<path d="M92.3,100.4c5.1-1.9,10.2-3.5,15.4-4.8c2.8-4.9,7.5-12.1,9.8-15.3c-4.9,1.1-12,3.2-15.1,4.3

C98.8,89.7,94.1,97.2,92.3,100.4z"></path>
					<path d="M85.3,170.9c6.3-2.4,13.6-4.8,17.8-6.1c0.5-5.2,1.4-12.3,2.5-18c-6.1,1.6-14.5,4.2-17.7,5.3

C86.7,158.6,85.6,167.7,85.3,170.9z"></path>
					<path d="M88.6,90c4.4-2.2,9-4.2,13.7-6c3.1-4.3,8.4-10.7,11.7-14.4c-4.6,1.6-9,3.5-13.4,5.6

C95.9,80.3,90.9,86.8,88.6,90z"></path>
					<path d="M130.9,48.3c-0.1,0-0.2,0.1-0.3,0.1c-3.5,2.5-6.9,5.2-10.2,7.9c-0.4,0.3-0.7,0.6-1,0.9

c-1.2,1-2.5,2-3.7,3c1.8-0.9,3.6-1.7,5.4-2.4c0.3-0.1,0.6-0.3,0.9-0.4c2.2-0.9,4.4-1.7,6.7-2.4c4.3-3.8,11.6-9.4,14.5-11.5

c-4,1.2-7.9,2.8-11.7,4.6C131.3,48.1,131.1,48.2,130.9,48.3z"></path>
					<path d="M107,145.5c3.2-0.8,12.7-2.9,19.1-3.9c1.1-5.4,3.2-13.4,4.1-16.6c-6.2,0.7-13.9,2-18.9,3.1

C110,132.3,108.1,139.8,107,145.5z"></path>
					<path d="M103.4,183.9c6.9-2.3,14.8-4.6,19.3-5.7c0-4.7,0.2-11.9,0.7-17.4c-7.5,1.6-16.1,3.9-19.3,4.9

C103.6,171.6,103.4,180.7,103.4,183.9z"></path>
					<path d="M111.7,243.4c3.2-1.5,13.4-6.2,18.9-8.5c-1.7-6.2-3.1-12.7-4.1-17.8

c-3.3,1.2-14.4,5.6-19.1,7.7C108,227.9,109.8,236.4,111.7,243.4z"></path>
					<path d="M117.8,263.2c2.7-1.5,13.1-6.9,18.6-9.5c-2.3-6.4-4.7-14.6-5.6-17.8c-2.9,1.2-13.5,6-18.9,8.6

C112.9,247.7,115.6,256.9,117.8,263.2z"></path>
					<path d="M100,112.9c5.4-1.5,14-3.3,17.2-3.8c2.3-5.3,5.5-11.6,7.9-15.9c-5.7,0.8-11.3,1.8-16.9,3.2

C105.5,101.1,102.4,107.4,100,112.9z"></path>
					<path d="M157.2,33.4c1.3-0.5,2.6-0.9,3.9-1.3c5.5-3.1,11.1-6,16.9-8.6c-3.2,1.2-11.8,3.5-29.1,13.3

c2.5-1.2,5-2.2,7.6-3.1C156.7,33.6,156.9,33.5,157.2,33.4z"></path>
					<path d="M175.2,29.2c5.2-2.9,13.2-6.9,16.4-8.3c-10,1.1-14.5,2.4-29.6,11

C166.3,30.7,170.7,29.8,175.2,29.2z"></path>
					<path d="M119.3,79.2c3.1-0.7,12-2.1,16.4-2.5c2.6-3.5,8.4-10.7,10.9-13.3c-4.8,0.3-13.1,1.4-16,2

C126.6,69.7,122.8,74.3,119.3,79.2z"></path>
					<path d="M126.7,92.1c5.3-0.7,12.2-1.2,18.2-1.4c1.7-3,5.9-9.7,9-13.9c-5.8-0.1-14.5,0.4-17.8,0.7

C132.8,82.2,129.6,87,126.7,92.1z"></path>
					<path d="M208.3,13.5c0.6-0.1,1.2-0.2,1.8-0.2c0.4,0,0.7-0.1,1-0.1c0.3-0.1,0.7-0.2,1-0.3

c5.6-1.9,11.4-3.6,17.3-5.1c-3.3,0.5-11.1,1-30.4,7.6c2.8-0.7,5.6-1.3,8.5-1.7C207.7,13.6,208,13.6,208.3,13.5z"></path>
					<path d="M225.6,13.2c4.5,0.5,9.1,1.3,13.5,2.4c5.1-1.7,11.2-3.5,16.7-4.9c-4.2-1.2-8.4-2-12.7-2.6

C237.2,9.6,231.3,11.3,225.6,13.2z"></path>
					<path d="M255.3,3.5c-0.3,0-4,0-4.9,0.1c-1.9,0.3-8.2,1.5-8.9,1.6c-1.4,0.3-4.1,0.9-5.5,1.2

c-1.6,0.4-3.2,0.7-4.7,1.1c4-0.2,8.1-0.1,12.1,0.3c6.9-1.7,15.1-3.2,18.3-3.7c-1-0.1-1.9-0.2-2.8-0.2C258,3.6,256.4,3.5,255.3,3.5z

"></path>
					<path d="M326.2,1.5c3,0.7,5.9,1.7,8.7,2.9c6.5,0.5,13,1.2,19.5,2.1c-2.4-1.2-4.4-2.3-10.7-3.2

c1,0.1,1.8,0.2,2.4,0.3C344.8,3.3,334.1,2,322.4,1c1.7,0.2,5.9,0.6,10.4,1.1C330.9,1.9,328.7,1.7,326.2,1.5z"></path>
					<path d="M494.1,59.7c2.9,1.9,5.6,3.7,8.5,5.6C502.6,65,494.3,59.8,494.1,59.7z"></path>
					<path d="M557.4,110.2c-0.6-0.5-16.2-15.3-17-15.6c2.5,2.2,10.2,9.2,15.6,14.6

C556.6,109.5,557,109.8,557.4,110.2z"></path>
					<path d="M100.3,328.2c-2-3.7-6.7-13.3-9.6-19.9c-5,3.9-10.7,8.6-13.9,11.4c3.6,8.1,8,16.8,9.8,20.3

C88.4,338.4,96.2,331.6,100.3,328.2z"></path>
					<path d="M14.9,201.8c-0.6,2.6-1.2,5.2-1.7,7.9c2.1-4,4.4-7.8,7-11.5c1.4-7.1,4-16.8,5-20

c-2.5,3.5-4.7,7.1-6.7,10.9C17.5,191,15.4,199.7,14.9,201.8z"></path>
					<path d="M9.1,253.5c2.1-3.9,4.5-7.7,7-11.4c0-7.7,0.6-17.7,0.9-20.9c-2.6,3.7-4.9,7.5-7,11.4

C9.3,239.8,9.1,250.3,9.1,253.5z"></path>
					<path d="M140.8,355.4c-4.2-6-9.3-13.7-12.2-18.5c-2.9,2.4-10.9,9.1-14.8,12.5

c3.6,5.9,9.2,14.2,12.6,19.1C129.9,365.1,137.7,358,140.8,355.4z"></path>
					<path d="M20.9,286.6c1.7-2.3,5.9-7.6,9-11c-1.3-7-2.7-18.1-3-21.3c-2.2,2.4-6.4,7.4-9,11

C18.5,271.7,20.2,282.8,20.9,286.6z"></path>
					<path d="M278.9,627c1.5,0.4,2.8,0.8,4.1,1.1l-0.6-0.2c3.8,1,3.8,1,5.1,1.3c12.9,3.2,15.4,3,19.3,2.5

c-7.3-1.4-18.3-4.1-21.5-4.9C283.3,627.2,281.1,627.2,278.9,627z"></path>
					<path d="M125.5,369.2c-2.3-3.2-9.2-13.5-12.7-19.1c-4,3.5-11.6,10.6-13.4,12.4c3.7,6,9.4,14.5,13,19.6

C115.2,379.3,121.5,373,125.5,369.2z"></path>
					<path d="M165.7,528.2c2.3-3.3,5.1-7.3,7.4-10.7c-3.2-2.4-14.2-11-20.5-16.3c-1.9,2.7-5.2,7.5-7.4,10.7

C148.5,514.7,160.4,524.2,165.7,528.2z"></path>
					<path d="M158.9,538.2c1.7-2.5,4.1-6,6.4-9.3c-4.7-3.5-16.1-12.6-20.4-16.3c-1.5,2.3-4.4,6.7-6.2,9.5

C144.9,527.4,154.5,534.9,158.9,538.2z"></path>
					<path d="M99.2,396.2c-2-2.8-9.4-13.7-13.2-19.9c-3.5,3.8-8.5,9.5-10.4,11.9c3.6,5.9,8.8,13.8,13.3,20.1

C91.7,404.7,95.5,400.3,99.2,396.2z"></path>
					<path d="M123.7,492.5c2.2-3.4,5-7.4,7.3-10.8c-3.2-3.1-12.4-12.3-17.8-18.2c-2.5,3.5-5.9,8.7-7.3,10.8

C111.6,480.5,118.9,487.8,123.7,492.5z"></path>
					<path d="M117.3,502.8c1.9-3.2,4.7-7.7,6-9.6c-6.3-6.1-12.4-12.4-17.7-18.1c-2.1,3.3-4.4,7.2-5.8,9.8

C103.7,489.2,114.2,499.8,117.3,502.8z"></path>
					<path d="M94.7,442.1c-2.6-3.2-9.6-12.2-14.9-19.6c-2.5,3.6-5.6,8.4-7.2,10.9

c2.3,3.2,10.3,13.9,14.9,19.6C89.4,450,92,446,94.7,442.1z"></path>
					<path d="M153.3,546.6c1.6-2.4,3.3-5.1,5.2-7.9c-9-6.7-17-13.5-20.2-16.1c-1.2,1.9-3.5,5.7-4.9,8.1

C140,536.3,147.3,542.1,153.3,546.6z"></path>
					<path d="M75.4,319.1c-2.8-6.4-5.8-13.8-8.2-20.6c-3.7,3.1-10,8.7-12.5,11.1c1.1,3.2,5.6,14.9,8.3,21

C66.2,327.4,73.3,321,75.4,319.1z"></path>
					<path d="M149,553.3c1.2-2,2.7-4.3,4-6.3c-6-4.5-13.2-10.2-19.9-15.8c-1.1,2-2.5,4.6-3.5,6.6

C132.8,540.5,141.4,547.6,149,553.3z"></path>
					<path d="M274.1,611.3c-8.1-2.8-20.4-7.5-23.6-8.8c-1.9,1.8-4,3.5-6.2,5c5,2,18,6.9,23.1,8.6

C269.7,614.7,271.9,613.1,274.1,611.3z"></path>
					<path d="M110.1,520.8c-0.7,1.8-1.2,3.7-1.5,5.6c4.7,4.5,11.7,10.9,17.6,15.9c0.6-1.7,1.3-3.4,2.1-5.1

C124.2,533.7,115.8,526.3,110.1,520.8z"></path>
					<path d="M51,343.5c-2.6-5.9-5.9-14.2-8.4-21.2c-2.2,2.6-6.6,7.9-9,11.2c1.1,3.2,5.2,13.8,8.5,21.3

C44.5,351.3,48.4,346.5,51,343.5z"></path>
					<path d="M90.1,507.5c0.1-2.1,0.5-4.1,1-6.2c-4.9-5.4-13.4-15.4-15.5-18c-0.4,2.2-0.6,4.5-0.5,6.7

C79.8,496.1,87.1,504.4,90.1,507.5z"></path>
					<path d="M32.6,332.9c-2.3-6.6-4.6-13.8-6.7-21.4c-2.4,3.4-5.6,8.5-7,11.2c2.2,8.1,5.6,18.2,6.7,21.4

C27.5,340.6,31.1,335,32.6,332.9z"></path>
					<path d="M214.3,600.3c-1.2,0.6-2.6,1-3.9,1.2c7.3,3.5,18.2,8.3,21.4,9.6c1.6,0,3.2-0.3,4.8-0.8

C228.4,606.9,219.4,602.7,214.3,600.3z"></path>
					<path d="M190.9,587.9c-0.9,0.7-1.9,1.3-3,1.7c6.6,3.8,13.7,7.5,21.2,11.2c1.3-0.2,2.6-0.6,3.9-1.3

C204.9,595.6,197.5,591.7,190.9,587.9z"></path>
					<path d="M168.5,574.3c-0.7,0.8-1.4,1.5-2.2,2.2c7.6,5,16.9,10.5,20.2,12.4c1.1-0.4,2.1-1,3-1.7

C186.3,585.3,175.4,578.8,168.5,574.3z"></path>
					<path d="M18.5,321.3c2.1-3.9,4.5-7.6,7-11.2c-1.6-5.9-3.6-14.4-4.9-21.4c-2.5,3.6-4.9,7.4-7,11.3

C14.5,304.4,16.4,313.4,18.5,321.3z"></path>
					<path d="M19.8,356.4c-2.4-6.7-5.1-15.4-6.6-21.1c-1.2,3.6-2.2,7.3-2.9,11c1.9,6.9,4.6,15.3,6.5,20.8

C17.5,363.5,18.6,359.9,19.8,356.4z"></path>
					<path d="M25.9,391c-0.6,3.2-1,6.5-1.1,9.8c1.4,3.2,5.7,12.3,9.6,20c0.2-3.1,0.6-6.3,1.3-9.3

C32.3,404.9,27.3,394.2,25.9,391z"></path>
					<path d="M80.2,500.4c2.7,3.2,4.6,5.6,10.7,12.2c-0.5-1.5-0.8-3.1-0.8-4.7c-2.9-3.2-10.2-11.4-15-17.4

c0.1,1.7,0.5,3.3,1.2,4.9c0,0,0.2,0.5,0.2,0.5c0.5,0.6,1.1,1.3,1.6,1.9C78.3,498.1,80,500.2,80.2,500.4z"></path>
					<path d="M92,513.9c0.8,1.9,14.7,15.6,16.1,17c-0.2-0.3-0.4-0.7-0.5-1C102.4,524.9,94.9,517.1,92,513.9z

"></path>
					<path d="M76.6,496.2c0.8,1.6,14.1,16.8,15.4,18.2c-0.1-0.1-1-1.5-1-1.5

C86.3,507.8,79.2,499.4,76.6,496.2z"></path>
					<path d="M11.8,366c1.2,4,2.5,8.1,4,12.1c0-3.5,0.3-6.9,0.9-10.3c-2.6-7.4-4.8-14.5-6.5-20.8

c-0.3,2.2-0.6,4.4-0.7,6.6c0,0.4,0,0.8,0,1.2c0,0.7-0.1,1.4,0,2.1c0,0.2,0,0.4,0,0.7c0,0.1,0,0.2,0,0.3c0.5,2,1.1,3.9,1.7,5.9

C11.3,364.5,11.5,365.2,11.8,366z"></path>
					<path d="M9.1,254.9c0.1,7,0.4,14,1.1,21.1c0.8-1.6,4.8-8.2,7-11.3c-0.6-6.3-1.1-14.3-1.1-21.2

C13.6,247.1,11.2,250.9,9.1,254.9z"></path>
					<path d="M9.9,345c0.7-3.7,1.7-7.4,2.9-11c-0.9-3.2-3.5-14-4.8-21.1c-1.2,3.7-2.2,7.5-2.9,11.4

C5.8,327.9,7.9,337.4,9.9,345z"></path>
					<path d="M37.6,437.1c-1.1-2.1-1.9-4.3-2.4-6.6c-1.1-2.2-2.2-4.3-3.3-6.5c-0.2-0.5-0.5-0.9-0.7-1.4

c-1.9-3.8-3.6-7.6-5.3-11.4c0.1,0.3,0.8,2.7,0.8,2.8c-1.7-4.9-3.1-8.2-5.1-12.9c1,2.4,2.2,5.2,3.6,8.4c-0.7-3-1-6.1-1-9.2

c-2.9-6.7-6.9-17-8-20.2c0.1,4.8,0.7,8.3,3.1,15c-0.5-1.4-1.1-2.9-1.7-4.6c0.8,3.1,8.3,21.9,9.4,24.5l-0.2-0.7

C27.5,416.1,36.6,435.2,37.6,437.1L37.6,437.1z"></path>
					<path d="M4.2,335.6c-0.1-0.5-0.4-2.5-0.5-2.8c-0.1-0.5-0.3-2.1-0.6-3.9c0.3,1.9,0.7,3.9,1.1,6

c-0.1-3.8,0.1-7.6,0.7-11.3c-1.2-6.7-2.6-17.5-2.9-20.7c-0.9,6.1-1,11.3,0.6,21.8c-0.2-1.5-0.5-2.9-0.6-4.1

c-0.1-0.8-0.1-1.4-0.2-1.8c0,0.2,0.1,0.4,0.1,0.7c-0.1-1.3-0.1-1.3-0.3-2.7c0.1,0.9,0.1,1.2,0.1,1.5l0-0.1c0.1,0.8,0.2,1.6,0.3,2.4

c-1-6.3-1.2-7.7,2.7,18.3c0-0.4-0.1-0.8-0.1-1.1C4.3,337.5,4.2,336.1,4.2,335.6z"></path>
					<path d="M2,255.6c0.6-3.6,1.5-7.2,2.5-10.7c0.3-3.6,0.8-7.1,1.3-10.7c-0.4,2.8-0.7,6-1,9.9

c1.4-4.1,3.1-8.1,5-12c0.3-3.2,1.6-13.7,2.9-20.4c-2.6,4.8-4.5,9.9-5.6,15.2c0.1-0.3,0.1-0.6,0.1-0.9c0-0.1,0.2-1.2,0.2-1.3

c-0.6,1.8-1.1,3.9-1.5,6c0.1-0.7,0.3-1.3,0.4-1.9c-0.8,3.1-1.2,5.8-1.7,8.3c0.2-0.9,0.4-2.2,0.7-3.5C3.8,240.8,2.8,248.9,2,255.6z"></path>
					<path d="M4.5,237c-2.2,14-3.1,20.5-1,6.6C3.8,241.4,4.1,239.3,4.5,237z"></path>
					<path d="M291.3,623.8c-5.1-1.4-15.6-4.4-22.9-6.9c-2,1.1-4.1,2-6.3,2.7c3.2,1.1,14.7,4.7,22.3,6.7

C286.8,625.7,289.1,624.9,291.3,623.8z"></path>
					<path d="M266.9,616.4c-3.2-1.1-15.6-5.6-23-8.6c-1.8,1.2-3.6,2.2-5.5,3c3.2,1.3,13.7,5.4,22.3,8.4

C262.9,618.4,264.9,617.5,266.9,616.4z"></path>
					<path d="M256.1,594.5c-6.5-2.7-19-8.4-24-10.8c-1.4,1.6-4.4,5.1-6.4,7.1c8.4,4.1,18.8,8.6,23.6,10.6

C251.3,599.6,254.9,595.9,256.1,594.5z"></path>
					<path d="M248.9,601.8c-4.4-1.8-15.3-6.6-23.6-10.6c-2.1,2.2-4.8,4.6-5.6,5.3c3.2,1.6,14.4,6.8,23,10.4

C245,605.4,247,603.7,248.9,601.8z"></path>
					<path d="M242.4,607.2c-8-3.3-19.8-8.8-23-10.3c-1.5,1.2-3.1,2.3-4.8,3.3c6.6,3.2,15.1,7,22.3,10

C238.9,609.3,240.7,608.3,242.4,607.2z"></path>
					<path d="M209.2,601.4c2.1,1,20,9.5,22.3,9.7c-3.2-1.3-13.9-6-21.4-9.6

C209.9,601.5,209.5,601.5,209.2,601.4z"></path>
					<path d="M217.9,596.1c-7-3.4-18.8-9.8-22.7-12c-1.3,1.3-2.6,2.5-4.1,3.7c6.9,3.9,14.4,7.9,22.1,11.7

C214.9,598.4,216.4,597.3,217.9,596.1z"></path>
					<path d="M223.9,590.5c-8.6-4.2-20.1-10.5-23.3-12.3c-1.5,1.7-3.5,4-5.1,5.6c3.2,1.8,14.7,8.1,22.8,12

C220.1,594.3,222.6,591.8,223.9,590.5z"></path>
					<path d="M186.9,589.7c11.7,6.6,9.5,5.5,20.9,11c0.3,0.1,0.6,0.1,1,0.1c-7.6-3.7-14.6-7.4-21.1-11.2

C187.5,589.7,187.2,589.8,186.9,589.7z"></path>
					<path d="M230.6,582.9c-9.6-4.7-17-8.7-23.7-12.5c-1.6,2.1-4,5.1-6,7.4c3.2,1.8,14.4,7.9,23.3,12.3

C226.2,588,229.3,584.5,230.6,582.9z"></path>
					<path d="M212.6,560c-6.4-3.7-18.6-11.2-22.9-14c-2.1,2.9-4.5,6.3-6.6,9.2c5.4,3.5,15.1,9.5,22.7,13.9

C207.4,567,210.6,562.7,212.6,560z"></path>
					<path d="M193.8,583.2c-4.8-2.7-14.8-8.8-21.7-13.3c-1.4,1.8-2.7,3.3-3.4,4.1

c6.9,4.5,17.9,11.1,21.1,12.9C191.2,585.8,192.5,584.6,193.8,583.2z"></path>
					<path d="M165.7,576.9c12.6,8.1,15.7,9.8,19.9,12.1c0.3,0,0.5,0,0.8-0.1c-4.1-2.3-12.8-7.5-20.2-12.3

C166.1,576.7,165.9,576.8,165.7,576.9z"></path>
					<path d="M199.1,577.3c-7.4-4.2-15-8.9-22.3-13.6c-1.8,2.3-3.4,4.5-4.5,5.9c8.1,5.3,18.6,11.5,21.8,13.3

C195.7,581.2,197.8,578.9,199.1,577.3z"></path>
					<path d="M205.4,569.6c-8.7-5-18.8-11.3-22.6-13.9c-2.2,3-4.2,5.8-5.6,7.6c7.5,4.9,15.7,9.9,22.3,13.6

C201.4,574.7,203.8,571.6,205.4,569.6z"></path>
					<path d="M220.6,548.8c-6.9-3.9-19.7-11.9-22.9-14c-2.4,3.4-5.2,7.5-7.5,10.6c5.4,3.5,17.8,11.1,22.9,14

C215.4,556.2,218.8,551.4,220.6,548.8z"></path>
					<path d="M170.7,569.1c-6.6-4.3-16.9-11.7-20.6-14.4c-1.2,1.9-2.2,3.6-2.7,4.6c4.6,3.5,13.9,10,20,14

C168.1,572.4,169.2,570.9,170.7,569.1z"></path>
					<path d="M188.3,545.1c-3.2-2.1-16.1-11-21.7-15.2c-2.4,3.4-4.8,7-6.4,9.3c5.4,4,18.6,13.2,21.5,15

C183.5,551.8,185.8,548.5,188.3,545.1z"></path>
					<path d="M196.2,533.8c-3.7-2.4-16.1-11-21.7-15.2c-2.5,3.6-5.2,7.5-7.4,10.7

c5.5,4.1,18.5,13.1,21.7,15.2C190.7,541.6,193.8,537.2,196.2,533.8z"></path>
					<path d="M175.4,562.8c-6-3.9-18-12.5-21.1-14.8c-1.7,2.6-3.2,4.9-4,6.3c3.2,2.4,13.8,10,20.6,14.4

C172.4,566.9,174,564.7,175.4,562.8z"></path>
					<path d="M181.3,554.8c-6.3-4.2-19-13.2-21.5-15c-2,2.9-3.8,5.7-5.2,7.8c5.6,4.2,17.9,12.7,21.1,14.8

C177.3,560.3,179.3,557.6,181.3,554.8z"></path>
					<path d="M205.1,521.1c-2.2-1.4-13.5-9.2-21.7-15.2c-2.9,4-6,8.4-8.4,11.9c6,4.5,18.5,13.1,21.7,15.2

C200.6,527.5,205.1,521.1,205.1,521.1z"></path>
					<path d="M126.9,548c6.3,5.2,11.2,9.3,17.7,14.1c0-0.2,0-0.4,0.1-0.6c-6-4.5-13.3-10.3-18-14.4

C126.7,547.4,126.7,547.7,126.9,548z"></path>
					<path d="M131.9,530.2c-3.2-2.7-13.3-11.7-18.6-16.8c-0.8,1.7-2.3,4.8-3.1,7c6.1,5.9,15,13.7,18.2,16.4

C129.4,534.7,130.9,531.9,131.9,530.2z"></path>
					<path d="M108.6,530.7c3.2,3.1,11.1,10.3,16.9,15.3c0-1.2,0.2-2.3,0.5-3.4c-6.7-5.7-14.4-12.8-17.6-15.9

C108.3,528,108.3,529.4,108.6,530.7z"></path>
					<path d="M143.6,511.5c-4.4-3.7-13.4-11.8-19.1-17.3c-1.2,1.8-4.2,6.6-6,9.6

c4.9,4.7,12.7,11.8,18.9,17.1C139.2,518.1,141.9,514,143.6,511.5z"></path>
					<path d="M137,521.6c-7.1-6.1-15.7-14-18.9-17.1c-1,1.7-3.3,5.8-4.6,8.4c5.3,5.1,15.5,14.2,18.6,16.8

C133.6,527.2,135.8,523.5,137,521.6z"></path>
					<path d="M173.6,516.8c2.4-3.4,5.5-7.8,8.4-11.9c-6.3-4.7-13.9-10.8-20.4-16.3c-2.2,3.1-6,8.3-8.5,11.9

C159.6,506,170.4,514.4,173.6,516.8z"></path>
					<path d="M112.1,512.3c-6.3-6-13.7-13.8-17.3-17.6c-1.1,2.4-2,4.9-2.7,7.5c5.1,5.5,13.5,14,16.9,17.2

C109.8,517.3,111.5,513.6,112.1,512.3z"></path>
					<path d="M151.4,500.2c-4.5-3.8-13.7-12.1-19.2-17.3c-1.8,2.6-5.1,7.4-7.3,10.8c5.2,5,15,13.7,19.1,17.3

C146.2,507.6,149.6,502.7,151.4,500.2z"></path>
					<path d="M160.4,487.6c-5.3-4.5-15.9-14.2-19.1-17.2c-3,4-6.9,9.4-8.6,11.8c5.2,5,13.8,12.7,19.2,17.3

C154.1,496.2,158.4,490.3,160.4,487.6z"></path>
					<path d="M62.6,477.9c0.1,0.1,0.1,0.2,0.2,0.3c0.8,1.1,1.1,1.4,1.8,2.4c1,1.4,1.8,2.4,2.4,3.3

c-0.6-0.8-1.3-1.8-2.2-3C64.6,480.6,62.8,478.1,62.6,477.9z"></path>
					<path d="M67,483.9C69,486.7,69.2,486.9,67,483.9z"></path>
					<path d="M98.3,484.2c-6.2-6.8-12.8-14.5-16.2-18.7c-1.6,3.2-3.3,7-4,9c4.8,5.9,13,15.2,15.9,18.4

C95.2,490.1,97.1,486.4,98.3,484.2z"></path>
					<path d="M170.5,473.9c-7.1-6.1-14.8-13.2-18.9-17.1c-3.3,4.2-7.1,9.2-9.8,12.7

c3.6,3.5,14.2,13.1,19.1,17.2C163.8,483,167.8,477.5,170.5,473.9z"></path>
					<path d="M75.5,494.8c-0.8-1.7-1.2-3.6-1.4-5.4c-2.5-3.1-9.5-12.2-13.7-18c0.2,1.2,0.4,2.3,0.8,3.4

c0.1,0.3,0.8,2.1,1.1,2.7c0.6,0.9,1.3,1.7,1.9,2.6C67.6,484.6,71.8,490.2,75.5,494.8z"></path>
					<path d="M104.4,473.8c-5.4-6-13.6-15.6-16.3-18.9c-1.8,2.9-4.1,7-5.7,10c5.7,7,11.2,13.3,16.2,18.7

C99.9,481.3,102.3,477.1,104.4,473.8z"></path>
					<path d="M131.5,481c1.7-2.4,6.2-8.6,8.6-11.8c-3.2-3.1-12.8-12.8-17.7-18.1c-3,3.9-7.2,9.6-8.7,11.7

C118.5,468,126.4,476.1,131.5,481z"></path>
					<path d="M60.5,463.7c-0.3,2.4-0.3,4.9,0,7.3c4.2,5.9,11.1,14.8,13.7,18c-0.1-2.3,0.1-4.5,0.5-6.7

C69.1,475.5,62.7,466.9,60.5,463.7z"></path>
					<path d="M140.7,468.4c0.5-0.7,4.6-6.1,4.6-6.1c1.7-2.2,3.4-4.4,5.2-6.6c-4.9-4.7-12.7-12.7-17.5-17.9

c-3.4,4.1-7.7,9.7-9.9,12.5C128.2,456,137.5,465.3,140.7,468.4z"></path>
					<path d="M81.1,464.3c-2.6-3.2-10.2-13-14.7-19.4c-1.4,3-2.7,6.1-3.8,9.3c2.3,3.2,9.1,12.4,14.5,19.1

C78.2,470.4,79.7,467.2,81.1,464.3z"></path>
					<path d="M112.1,462.3c-4.8-5.2-13.9-15.9-16.4-19c-1.6,2.3-5.1,7.5-7.2,10.9c2.9,3.6,11,13.1,16.3,18.9

C106,471.3,109.7,465.8,112.1,462.3z"></path>
					<path d="M27.9,416.9L27.9,416.9L27.9,416.9C27.8,416.7,27.8,416.7,27.9,416.9z"></path>
					<path d="M121.3,449.9c-4.6-5.1-13-14.8-16.3-18.9c-3.1,3.9-6.1,8-8.7,11.6c3.6,4.5,12.5,14.8,16.4,19

C114.2,459.4,118.4,453.7,121.3,449.9z"></path>
					<path d="M46.2,442.1c-4.2-6.9-8.5-14.6-11.2-19.6c-0.1,2.9,0.2,5.7,0.7,8.5c3.2,6,8.9,15.8,10.9,19

C46.2,447.5,46.1,444.8,46.2,442.1z"></path>
					<path d="M65.6,443.7c-4.2-5.9-11.3-16.8-13.2-20c-0.8,1.9-2.8,7.2-3.6,9.6c2.5,4.1,8.4,13.1,13,19.7

C62.9,449.9,64.1,446.7,65.6,443.7z"></path>
					<path d="M36.4,412.7c-0.7,3.1-1.2,6.2-1.4,9.3c3.8,7.1,8.3,14.9,11.2,19.6c0.3-3,0.9-5.9,1.6-8.9

C43.7,426,38.1,415.9,36.4,412.7z"></path>
					<path d="M51.6,422.5c-4.1-6.7-10-17.2-11.7-20.4c-1.3,3.3-2.4,6.6-3.4,10c2.3,4.4,7.4,13.5,11.5,20.1

C48.8,429.3,50.8,424.2,51.6,422.5z"></path>
					<path d="M131.9,436.6c-5.5-5.8-13.5-15.5-16.1-18.7c-3.4,3.9-8.2,9.9-10.1,12.3

c4,4.9,12,14.1,16.3,18.9C125.1,445,128.9,440.2,131.9,436.6z"></path>
					<path d="M71.4,432.9c-4.4-6.2-11-16.4-13.4-20.2c-1.6,2.8-3.9,7-5.4,10.4c1.7,2.7,9.5,14.8,13.2,20

C67.4,439.8,69.3,436.4,71.4,432.9z"></path>
					<path d="M79,421.3c-4.8-6.8-12.2-18.2-13.4-20.2c-1.4,1.9-5.2,7.6-7.1,11c2,3.2,8.4,13.2,13.4,20.2

C73,430.2,76.3,425.1,79,421.3z"></path>
					<path d="M25.3,389.7c-3-6.9-5.5-13.4-8.2-20.7c-0.6,3.4-0.9,6.8-0.9,10.3c1.1,3.2,5.2,13.6,8,20.2

C24.3,396.3,24.7,393,25.3,389.7z"></path>
					<path d="M114.7,416.7c-5.5-6.8-11.8-15.3-14.7-19.3c-2.9,3.3-7.2,8.3-10.3,12.1

c4.2,5.8,13.1,17.4,14.8,19.5C106.3,426.8,111.2,420.9,114.7,416.7z"></path>
					<path d="M44.8,389.6c-3.7-7-8.7-17.8-10.1-21c-1.6,3.1-3.8,7.4-5.2,10.8c1.6,3.6,6.5,14.1,10,20.8

C40.7,397.3,43.2,392.3,44.8,389.6z"></path>
					<path d="M64.8,399.8c-4.4-7.2-9.5-16.2-11.8-20.7c-2.1,3-5.2,7.8-7.1,11c2.9,5.4,7.7,14,11.8,20.6

C59.6,407.4,63.1,402.2,64.8,399.8z"></path>
					<path d="M28.6,378.7c-2-4.5-5.7-13.5-8.4-21c-1.3,3.5-2.3,7-3.1,10.7c1.1,3.2,5.1,13.6,8.2,20.7

C26.3,385.6,27.3,382.1,28.6,378.7z"></path>
					<path d="M88.3,409c-4.4-6.3-9.4-13.8-13.3-20.1c-2.5,3.1-6.2,7.8-8.8,11.5c2,3.2,8.7,13.6,13.4,20.2

C81.2,418.1,84.2,414,88.3,409z"></path>
					<path d="M5.3,344.9l0-0.2C5.3,344.8,5.3,344.6,5.3,344.9z"></path>
					<path d="M34.1,367.3c-2.9-6.5-6.9-16.9-8.4-21.2c-1.9,3.5-3.6,7.2-5.1,11c1.8,5,5.3,13.9,8.4,21

C30.2,374.7,32.9,369.5,34.1,367.3z"></path>
					<path d="M74.1,387.6c-2.9-4.7-8.6-14.6-11.8-20.6c-3.3,3.9-6.6,8.3-8.9,11.4

c3.6,6.9,8.2,14.8,11.8,20.7C65.9,397.9,73.3,388.7,74.1,387.6z"></path>
					<path d="M126.5,403.5L126.5,403.5c0.2-0.2,0.3-0.3,0.4-0.5c-4.4-5.5-11.9-15.4-14.4-19

c-3.6,3.7-10.2,10.8-11.7,12.5c4.2,5.9,9.6,13,14.7,19.3C118.2,412.7,123,407.3,126.5,403.5z"></path>
					<path d="M5,324.9c-1.3,8.8-0.8,13.8,4,31.6c-0.1-3.6,0.2-7.2,0.8-10.8C8.1,339.4,6.1,330.6,5,324.9z"></path>
					<path d="M0,291l0,1.5c0-0.2,0-0.4,0-0.7C0,289,0,289.4,0,291z"></path>
					<path d="M61.7,365.7c-2-3.7-7.1-14.1-10.1-21c-3.2,3.7-6.5,8-8.9,11.3c3.1,6.9,7,15,10.2,21

C55,374,58.6,369.4,61.7,365.7z"></path>
					<path d="M1.7,301.6c-0.7-6.9-1-13.7-1.1-20.5c-1.3,9.6-0.1,21.6,0.5,31.4c-0.1-2.7,0-5.5,0.3-8.2

C1.5,303.8,1.7,301.9,1.7,301.6z"></path>
					<path d="M41.6,355.5c-3.1-7-7.3-18.1-8.5-21.3c-1.6,2.2-5.1,7.7-7.1,11.1c1.1,3.2,6.7,17.6,8.4,21.2

C36.4,363.2,39.8,358,41.6,355.5z"></path>
					<path d="M13,299.3c-1.3-7-2.7-18.1-3-21.3c-1.9,3.7-3.6,7.6-5,11.6c0.5,5.4,2,15.9,3,21.1

C9.4,306.8,11.1,303,13,299.3z"></path>
					<path d="M140.4,388.9c-2.6-3.2-11.1-14.3-14.1-18.5c-4.2,3.9-10.2,9.9-13.1,12.9

c3.8,5.3,11.8,15.8,14.4,19C131.3,398.4,137.5,391.9,140.4,388.9z"></path>
					<path d="M13.1,333.2c1.4-3.8,3.1-7.6,5-11.2c-0.9-3.2-3.6-14.5-4.9-21.3c-1.9,3.7-3.6,7.5-5,11.4

C9.7,319.7,12,329.2,13.1,333.2z"></path>
					<path d="M72.9,353.3c-4.1-7.9-8.3-16.9-10-20.7c-3.6,3.6-8.9,9.4-10.7,11.4c3.1,7.1,8.4,17.7,10.1,20.9

C64.1,362.9,69.6,356.8,72.9,353.3z"></path>
					<path d="M62.3,331.3c-2.9-6.6-7.2-17.7-8.3-21c-3.9,3.8-8,8.1-10.8,11.2c2.6,7.2,7,18,8.4,21.2

C53.6,340.5,58.8,334.8,62.3,331.3z"></path>
					<path d="M0.7,278.9c0.7-4.1,1.6-8.1,2.8-12.1c0-6.7,0.6-17.4,0.8-20.6c-1.8,5.5-2.8,11.2-3.1,17

c0-1,0-1.5,0-1.6c0.1-1,0.1-1.4,0.1-1.6l0.1-1.2c-0.1,0.5-0.1,0.9-0.1,1.2c0,0.2-0.1,0.3-0.1,0.5l0,0c-0.1,0.8-0.2,2.4-0.3,4

c0-0.1,0-0.3,0-0.6c-0.1,0.9-0.1,2.2-0.2,3.8c0.1-3.2-0.4,8.2-0.6,15.8c0.1-1.8,0.3-5.3,0.5-9C0.7,275.9,0.7,277.4,0.7,278.9z"></path>
					<path d="M97.9,362.1c-3.2-5.3-8.1-13.7-11.4-20c-3,2.7-8.6,8.1-12.2,11.8c3.5,6.7,9,16,11.6,20.4

C89.5,370.4,95,364.9,97.9,362.1z"></path>
					<path d="M53.5,309c-2.6-7.3-5.6-17.3-6.6-21.1c-2.2,2.1-7.7,7.5-10.8,11c0.9,3.2,4.3,14.7,6.7,21.3

C45.6,317,49.7,312.6,53.5,309z"></path>
					<path d="M26.1,309.4c2.5-3.4,6.8-8.7,9-11.1c-2.1-7.7-3.9-16.2-4.9-21.3c-3.1,3.5-7.1,8.4-9,11.1

C22.6,295.3,24.4,303.1,26.1,309.4z"></path>
					<path d="M12.4,203.9c-0.1,0.2-0.1,0.4-0.2,0.6C12.4,203.9,12.6,203.3,12.4,203.9z"></path>
					<path d="M12.7,202.8c-0.3,1.2-0.6,2.3-1,3.5C12.7,203.1,14.2,198.5,12.7,202.8z"></path>
				</g>
			</svg>
		</div>

	</div>
</div>
</template>

<script>
import Title from './common/Title.vue'
import Button from './common/Button.vue'
export default {
	components: {
		Title,
		Button

	},

}
</script>

<style scoped>
._container {
	width: 100%;
	height: 100%;
	min-height: 900px;
	display: flex;
	justify-content: center;
	padding-top: 7%;
	padding-left: 5%;
	padding-right: 5%;
	background-color: #131619;
}
._insideContainer {
	display: flex;
	flex-direction: row;
	max-width: 1900px;
	width: 100%;
	color: white;
	justify-content: space-between;
}
.leftContainer {
	display: flex;
	flex-direction: column;
}
.infoContainer {
	display: flex;
	width: 100%;
	max-width: 400px;
	font-family: 'DM Sans', sans-serif;
	font-size: 17px;
	color: #bebebe;
	margin-top: -40px;
	line-height: 1.589em;
	font-weight: 400;
}
.buttonContainer {
	display: flex;
	width: 100%;
	max-width: 900px;
	margin-top: 40px;
}
.rightContainer {
	display: flex;
	width: 50%;
	justify-content: end;

}
@media screen and (max-width: 1023px) {
	._insideContainer {
		flex-direction: column;
	}
	.leftContainer{
		position: relative;
		top: 400px;
	}
	.rightContainer{
		position: relative;
		top: -400px;
	}

}
</style>
